import { Component, OnInit } from '@angular/core';
import { Category, ProductCategory } from 'projects/lendi-business/src/public-api';
import { SpinnerService } from 'projects/shared/services/spinner.service';
import { CategoryService } from 'projects/core/services/category.service';

declare var $: any;

@Component({
  selector: 'app-categories-section',
  templateUrl: './categories-section.component.html',
  styleUrls: ['./categories-section.component.css']
})
export class CategoriesSectionComponent implements OnInit {

  categories: ProductCategory[] = [];
  pageCount = 0;
  pageCategories = [];
  spinneName = 'CategoriesSectionComponent';
  slideConfig = {'slidesToShow': 1, 'slidesToScroll': 1};

  constructor(private categoryService: CategoryService, private spinner: SpinnerService) { }

  ngOnInit() {
    this.categoryService.categoriesAsLinks().then((res: ProductCategory[]) => {
      this.spinner.show(this.spinneName);
      this.categories = res;
      const  remainder = this.categories.length % 6;
      this.pageCount = ~~(remainder ? (this.categories.length / 6) + 1 : (this.categories.length / 6));
      for (let index = 0; index < this.pageCount; index++) {
        this.pageCategories.push(this.getCategories(index));
      }
      this.spinner.hide(this.spinneName);
    }).catch(err => {
      console.error(err);
    });
  }

  getCategories(page) {
    if (this.categories.length === 0) {
      return [];
    } else {
      const itemCount = 6;
      const start = page ? 0 : page * itemCount;
      const end = itemCount;
      return this.categories.splice(start, end);
    }
  }

}

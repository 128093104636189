import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SpinnerService } from 'projects/shared/services/spinner.service';
import { debounceTime } from 'rxjs/operators';
import { setErrorMessage } from 'projects/shared/validators/validation-messages';
import Swal from 'sweetalert2';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from 'projects/core/services/auth.service';
import { LendiUserService } from 'projects/core/services/lendi-user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent implements OnInit, OnDestroy {

  @Input() redirectOnLogin: boolean;

  loginForm: FormGroup;
  formErrors: { [key: string]: string };
  userSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private router: Router,
    private lendiUserService: LendiUserService,
    private spinner: SpinnerService,
    private $afAuth: AngularFireAuth) {}

  get email() {
    return this.loginForm.get('email');
  }
  get password() {
    return this.loginForm.get('password');
  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password:  ['', [Validators.required, Validators.minLength(6)]]
    });

    this.formErrors = {
      email: '',
      password: ''
    };

    if (this.redirectOnLogin === undefined) {
      this.redirectOnLogin = true;
    }

    this.email.valueChanges.pipe(debounceTime(1000)).subscribe(value => {
      this.formErrors.email = setErrorMessage(this.email, 'email');
    });

    this.password.valueChanges.subscribe(value => {
      this.formErrors.password = setErrorMessage(this.password, 'password');
    });
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  onLoginHandler() {
    if (this.redirectOnLogin) {

      this.userSubscription = this.lendiUserService.currentUser.subscribe(userRecord => {

        if (userRecord === null) { return; }

        if (userRecord.verificationInfo.isVerified) {
          this.router.navigateByUrl('/home').finally(() => {
            this.spinner.hide();
          });
        } else {
          this.authService.afAuth.auth.currentUser.sendEmailVerification().then(() => {
            this.router.navigateByUrl('/profile/verification').finally(() => {
              this.spinner.hide();
            });
          });
        }
      });
    }
  }

  login() {
    if (this.loginForm.valid) {
      this.spinner.show();
      this.$afAuth.auth.signInWithEmailAndPassword(this.email.value, this.password.value).then((res) => {
        if (res) {
          this.lendiUserService.setUserProfile((res as any).user.uid);
          this.onLoginHandler();
        }
      }).catch((err) => {
        console.error(err);
        this.spinner.hide();
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: err.message,
          showConfirmButton: false,
          timer: 3000
        });
      });
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please fill up all the required fields.',
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { ProductCategory } from 'projects/lendi-business/src/public-api';
import { SpinnerService } from 'projects/shared/services/spinner.service';
import { CategoryService } from 'projects/core/services/category.service';

@Component({
  selector: 'app-category-links',
  templateUrl: './category-links.component.html',
  styleUrls: ['./category-links.component.css']
})
export class CategoryLinksComponent implements OnInit {

  categories: ProductCategory[] = [];
  pageCount = 0;
  pageCategories = [];
  spinneName = 'CategoryLinksComponent';

  constructor(private categoryService: CategoryService, private spinner: SpinnerService) { }

  ngOnInit() {
    this.spinner.show(this.spinneName);
    this.categoryService.categoriesAsLinks().then((res: ProductCategory[]) => {
      this.categories = res;
      if (this.categories.length) {
        this.pageCount = 2;
        this.pageCategories.push(this.categories.slice(0, Math.round((this.categories.length / 2))));
        this.pageCategories.push(this.categories.slice(Math.round((this.categories.length / 2))));
      }
    }).finally(() => {
      this.spinner.hide(this.spinneName);
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { SpinnerService } from 'projects/shared/services/spinner.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-manage-account',
  templateUrl: './manage-account.component.html',
  styleUrls: ['./manage-account.component.css']
})
export class ManageAccountComponent implements OnInit {

  spinnerName = 'ManageAccountComponent';
  mode: string;
  code: string;

  constructor(
    private spinner: SpinnerService,
    private router: Router,
    private afAuth: AngularFireAuth,
    private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.spinner.show(this.spinnerName);
    this.getUrlParameters();
    this.redirectByMode();
  }

  getUrlParameters() {
    this.mode = this.activatedRoute.snapshot.queryParams['mode'];
    this.code = this.activatedRoute.snapshot.queryParams['oobCode'];
  }

  redirectByMode() {
    switch (this.mode) {
      case 'resetPassword':
        this.router.navigate(['auth/change-password'], { state: { code: this.code } });
        break;
      case 'verifyEmail':
        this.verifyEmail();
        break;
      default:
        this.router.navigate(['home']);
        break;
    }
  }

  verifyEmail() {
    this.spinner.hide(this.spinnerName);
    this.spinner.show();
    this.afAuth.auth
    .applyActionCode(this.code)
    .then(() => {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Email address successfully verified.',
        showConfirmButton: false,
        timer: 2000
      }).then(() => {
        this.router.navigate(['auth/login']);
      });
    })
    .catch(err => {
      console.error(err);
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'There was a problem with your request. Please contact support.',
        showConfirmButton: false,
        timer: 1500
      }).then(() => {
        this.router.navigate(['contact-us']);
      });
    }).finally(() => {
      this.spinner.show();
    });
  }

}

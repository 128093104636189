import { Component, OnInit, Input } from '@angular/core';
import { RatingCount } from 'projects/lendi-business/src/public-api';

@Component({
  selector: 'app-profile-lenders-rating',
  templateUrl: './profile-lenders-rating.component.html',
  styleUrls: ['./profile-lenders-rating.component.css']
})
export class ProfileLendersRatingComponent implements OnInit {

  @Input() isPublic: boolean;
  @Input() userRatingCount: RatingCount;

  constructor() { }

  ngOnInit() {
  }

}

import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { User } from 'projects/lendi-business/src/public-api';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user$: Observable<User>;
  uid: string;

  constructor(
    public afAuth: AngularFireAuth,
    public afs: AngularFirestore,
    public router: Router
  ) {
    this.user$ = this.afAuth.authState;
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.uid = user.uid;
        return user;
      }
      return null;
    });
  }

  isLoggedIn() {
    return this.user$;
  }

  async signIn(email, password) {
      return this.afAuth.auth.signInWithEmailAndPassword(email, password).then(user => {
        if (user) {
          this.uid = user.user.uid;
        }
        return user;
      }).catch(error => {
        console.error(error);
        return error;
      });
  }

  async signOut() {
    return this.afAuth.auth.signOut().then(res => {
      return true;
    }).catch(error => {
      return false;
    })
  }

  setUserDoc(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      'users/${user.uid}'
    );

    const data = {
      uid: user.uid,
      email: user.email
    };

    return userRef.set(data as any);
  }

  emailSignUp(email: string, password: string) {
    return this.afAuth.auth
      .createUserWithEmailAndPassword(email, password)
      .then(userAuth => {
        this.uid = userAuth.user.uid;
        return userAuth.user;
      }).catch(error => {
        this.handleError(error);
        return false;
      });
  }

  updateUser(user: User, data: any) {
    return this.afs.doc('users/${user.id}').update(data);
  }

  private handleError(error) {
    console.error(error);
  }
}

import { Component, OnInit } from '@angular/core';
import { LendiUserRecord } from 'projects/lendi-business/src/public-api';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { confirmPassword } from 'projects/shared/validators/confirm-password.validator';
import { setErrorMessage } from 'projects/shared/validators/validation-messages';
import { debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SpinnerService } from 'projects/shared/services/spinner.service';
import * as firebase from 'firebase';
import Swal from 'sweetalert2';
import { AuthService } from 'projects/core/services/auth.service';
import { LendiUserService } from 'projects/core/services/lendi-user.service';

declare var $: any;

@Component({
  selector: "app-sign-up-form",
  templateUrl: './sign-up-form.component.html',
  styleUrls: ['./sign-up-form.component.css']
})
export class SignUpFormComponent implements OnInit {
  signUpForm: FormGroup;
  formErrors: { [key: string]: string };

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private lendiUserService: LendiUserService,
    private router: Router,
    private spinner: SpinnerService
  ) {}

  get email() {
    return this.signUpForm.get('email');
  }
  get passwordGroup() {
    return this.signUpForm.get('passwordGroup');
  }
  get password() {
    return this.signUpForm.get('passwordGroup.password');
  }
  get confirmPassword() {
    return this.signUpForm.get('passwordGroup.confirmPassword');
  }
  get firstname() {
    return this.signUpForm.get('firstname');
  }
  get lastname() {
    return this.signUpForm.get('lastname');
  }
  get middlename() {
    return this.signUpForm.get('middlename');
  }
  get birthdate() {
    return this.signUpForm.get('birthdate');
  }
  get mobile() {
    return this.signUpForm.get('mobile');
  }

  ngOnInit() {
    $('#birthdate').datepicker({
      uiLibrary: 'bootstrap',
      change: (e) => {
        this.birthdate.setValue($(e.target).val());
      }
    });

    this.signUpForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      passwordGroup: this.fb.group(
        {
          password: ['', [Validators.required, Validators.minLength(6)]],
          confirmPassword: ['', [Validators.required]]
        },
        { validators: [confirmPassword] }
      ),
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      middlename: [''],
      birthdate: [''],
      mobile: ['', [Validators.required]]
    });

    this.formErrors = {
      email: '',
      passwordGroup: '',
      password: '',
      confirmPassword: '',
      firstname: '',
      lastname: '',
      birthdate: '',
      mobile: ''
    };

    this.email.valueChanges.pipe(debounceTime(1000)).subscribe(value => {
      this.formErrors.email = setErrorMessage(this.email, 'email');
    });
    this.passwordGroup.statusChanges.subscribe(status => {
      this.formErrors.passwordGroup = setErrorMessage(
        this.passwordGroup,
        'passwordGroup'
      );
    });
    this.password.valueChanges.subscribe(value => {
      this.formErrors.password = setErrorMessage(this.password, 'password');
    });
    this.confirmPassword.valueChanges.subscribe(value => {
      this.formErrors.confirmPassword = setErrorMessage(
        this.confirmPassword,
        'confirmPassword'
      );
    });
    this.firstname.valueChanges.subscribe(value => {
      this.formErrors.firstname = setErrorMessage(this.firstname, 'firstname');
    });
    this.lastname.valueChanges.subscribe(value => {
      this.formErrors.lastname = setErrorMessage(this.lastname, 'lastname');
    });
    this.mobile.valueChanges.subscribe(value => {
      this.formErrors.mobile = setErrorMessage(this.mobile, 'mobile');
    });
    this.birthdate.valueChanges.subscribe(value => {
      this.formErrors.birthdate = setErrorMessage(this.birthdate, 'birthdate');
    });
  }

  signUp() {
    if (!this.signUpForm.valid) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please fill up all the required fields.',
        showConfirmButton: false,
        timer: 1500
      });
      return;
    }

    this.spinner.show();
    this.formErrors['email'] = '';
    this.formErrors['mobile'] = '';

    Promise.all([
      this.validateMobileNumber(),
      this.validateEmailAddress()
    ]).then(res => {
      const isMobileValid = res[0];
      const isEmailValid = res[1];

      if (!isMobileValid) {
        this.formErrors['mobile'] = 'Mobile Number already in use.';
      }

      if (!isEmailValid) {
        this.formErrors['email'] = 'Email Address already in use.';
      }

      if (!isMobileValid || !isEmailValid) {
        this.spinner.hide();
        return;
      }

      this.authService
      .emailSignUp(this.email.value, this.password.value)
      .then(signUpRes => {
        this.createProfile();
      })
      .catch(err => {
        console.error(err);
      }).finally(() => {
        this.spinner.hide();
      });

    }).catch(err => {
      console.error(err);
      this.spinner.hide();
    });
  }

  private validateMobileNumber() {
    return this.lendiUserService.isMobileNumberInUse(this.mobile.value).toPromise().then(res => {
      return res.docs.length ? false : true;
    }).catch(err => {
      return false;
    });
  }

  private validateEmailAddress() {
    return this.lendiUserService.isEmailAlreadyInUse(this.email.value).toPromise().then(res => {
      return res.docs.length ? false : true;
    }).catch(err => {
      return false;
    });
  }

  private createProfile() {
    const lendiUseProfileData: LendiUserRecord = {
      id: this.authService.uid,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      isActive: true,
      isDeleted: false,
      profile: {
        email: this.email.value,
        firstname: this.firstname.value,
        lastname: this.lastname.value,
        middlename: this.middlename.value,
        birthdate: this.birthdate.value,
        mobile: this.mobile.value,
        gender: 'Male'
      },
      profileAddress: {
        streetAddress: null,
        barangay: null,
        region: null,
        city: null,
        postalCode: null,
      },
      profilePhoto: {
        source: 'assets/images/verify-placeholder.png'
      },
      verificationInfo: {
        isVerified: false,
        verificationPhoto: null
      }
    };

    this.spinner.show();
    this.lendiUserService.ref.doc(lendiUseProfileData.id).set(lendiUseProfileData).then((res) => {
      this.authService.afAuth.auth.currentUser.sendEmailVerification().then(() => {
        this.router.navigate(['/profile/verification']);
      }).catch((err) => {
        console.error(err);
      });
    }).catch(error => {
      console.error(error);
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'There was a problem with your request. Please contact support.',
        showConfirmButton: false,
        timer: 1500
      }).then(() => {
        this.router.navigate(['contact-us']);
      });
    }).finally(() => {
      this.spinner.hide();
    });
  }
}

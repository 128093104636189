import { Pipe, PipeTransform } from '@angular/core';
import { environment, City, Province } from 'projects/lendi-business/src/public-api';

@Pipe({
  name: 'cityByProvinceFilter'
})
export class CityByProvinceFilterPipe implements PipeTransform {

  transform(value: City[], province: string): any {
    if (province !== null && province !== undefined && province) {
      const selectedProvince: Province = environment.provinces.find(p => p.description.toLowerCase() === province.toLowerCase());
      return value.filter((c: City) => c.prov_dcode === selectedProvince.id);
    }

    return value;
  }

}

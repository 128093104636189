import { Component, OnInit, Input } from '@angular/core';
import { LendiProductRecord, LendiUserRecord } from 'projects/lendi-business/src/public-api';
import { LendiUserService } from 'projects/core/services/lendi-user.service';

@Component({
  selector: "app-product-info-full",
  templateUrl: './product-info-full.component.html',
  styleUrls: ['./product-info-full.component.css']
})
export class ProductInfoFullComponent implements OnInit {
  @Input() product: LendiProductRecord;
  @Input() productOwner: LendiUserRecord;

  displayContactNumber = false;

  constructor(private $user: LendiUserService) {}

  ngOnInit() {
    this.$user.currentUser.subscribe(user => {
      if (user) {
        this.displayContactNumber = user.verificationInfo.isVerified;
      }
    });
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'main-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { LendiUserRecord } from 'projects/lendi-business/src/public-api';

@Component({
  selector: 'app-profile-pill',
  templateUrl: './profile-pill.component.html',
  styleUrls: ['./profile-pill.component.css']
})
export class ProfilePillComponent implements OnInit {

  @Input() userProfile: LendiUserRecord;

  constructor() { }

  ngOnInit() { }

}

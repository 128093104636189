import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { LendiUserService } from 'projects/core/services/lendi-user.service';
import { LendiUserRecord } from 'projects/lendi-business/src/public-api';

@Component({
  selector: 'app-profile-status-badge',
  templateUrl: './profile-status-badge.component.html',
  styleUrls: ['./profile-status-badge.component.css']
})
export class ProfileStatusBadgeComponent implements OnInit {

  @Input() isPublic: boolean;
  @Input() userRecord: LendiUserRecord;

  isVerified = false;
  dateJoined = new Date().toDateString();
  subscription: any;

  constructor(public lendiUserService: LendiUserService) { }

  ngOnInit() {
    this.isVerified = this.userRecord.verificationInfo.isVerified;
    this.dateJoined = new Date(this.userRecord.createdAt.seconds * 1000).toDateString();
  }

}

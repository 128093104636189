import { Component, OnInit, Input } from '@angular/core';
import { LendiProductRecord } from 'projects/lendi-business/src/public-api';

@Component({
  selector: 'app-product-pill',
  templateUrl: './product-pill.component.html',
  styleUrls: ['./product-pill.component.css']
})
export class ProductPillComponent implements OnInit {

  @Input() product: LendiProductRecord;
  staticDate: Date;

  constructor() { }

  ngOnInit() {
    this.staticDate = new Date(this.product.createdAt.seconds * 1000);
  }

}

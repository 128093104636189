import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-rate',
  templateUrl: './rate.component.html',
  styleUrls: ['./rate.component.css']
})
export class RateComponent implements OnInit {

  @Output() ratingChanged = new EventEmitter<number>();

  indexStar1Fill = true;
  indexStar2Fill = false;
  indexStar3Fill = false;
  indexStar4Fill = false;
  indexStar5Fill = false;

  constructor() { }

  ngOnInit() { }

  setFill(index: number) {
    this.indexStar2Fill = false;
    this.indexStar3Fill = false;
    this.indexStar4Fill = false;
    this.indexStar5Fill = false;

    if (index === 2) {
      this.indexStar2Fill = true;
    }
    if (index === 3) {
      this.indexStar2Fill = true;
      this.indexStar3Fill = true;
    }
    if (index === 4) {
      this.indexStar2Fill = true;
      this.indexStar3Fill = true;
      this.indexStar4Fill = true;
    }
    if (index === 5) {
      this.indexStar2Fill = true;
      this.indexStar3Fill = true;
      this.indexStar4Fill = true;
      this.indexStar5Fill = true;
    }

    this.ratingChanged.emit(index);
  }

}

import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-product-photo-gallery',
  templateUrl: './product-photo-gallery.component.html',
  styleUrls: ['./product-photo-gallery.component.css']
})
export class ProductPhotoGalleryComponent implements OnInit, OnChanges {

  @Input() productImages: string[];
  selectedImage: string;

  constructor() { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.productImages.currentValue === undefined) {
      this.productImages = [];
    }
  }

  setFullView(image) {
    this.selectedImage = image;
  }

  getSelectedImage() {
    if (this.selectedImage) {
      return this.selectedImage;
    } else {
      if (this.productImages.length) {
        return this.productImages[0];
      } else {
        return '';
      }
    }
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SpinnerService } from 'projects/shared/services/spinner.service';
import { AuthService } from 'projects/core/services/auth.service';
import { LendiUserService } from 'projects/core/services/lendi-user.service';
import { Subscription } from 'rxjs';


@Component({
  selector: "app-auth-links",
  templateUrl: "./auth-links.component.html",
  styleUrls: ["./auth-links.component.css"]
})
export class AuthLinksComponent implements OnInit, OnDestroy {

  isLoggedIn = false;
  userSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private lendiUserService: LendiUserService,
    private spinner: SpinnerService
  ) {}

  ngOnInit() {
    this.userSubscription = this.lendiUserService.currentUser.subscribe(user => {
      if (user) {
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
      }
    });
  }

  logout() {
    this.spinner.show();
    this.authService
      .signOut()
      .then(res => {
        if (res) {
          this.lendiUserService.resetUserProfileSubscription();
          window.location.href = '/home';
        }
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { LendiUserRecord, BorrowRequestRecord } from 'projects/lendi-business/src/public-api';

@Component({
  selector: 'app-borrow-request-simple-card',
  templateUrl: './borrow-request-simple-card.component.html',
  styleUrls: ['./borrow-request-simple-card.component.css']
})
export class BorrowRequestSimpleCardComponent implements OnInit {

  @Input() borrowRequestRecord: BorrowRequestRecord;
  @Input() lenderProfile: LendiUserRecord;
  @Input() borrowerProfile: LendiUserRecord;

  constructor() { }

  ngOnInit() { }

}

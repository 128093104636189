import { Pipe, PipeTransform } from '@angular/core';
import { LendiProductSlotRecord } from 'projects/lendi-business/src/public-api';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'productSlotOptionLabel'
})
export class ProductSlotOptionLabelPipe implements PipeTransform {

  transform(value: LendiProductSlotRecord, ...args: any[]): any {

    if(value.isPremium) {
      const expiryDate = new DatePipe('en-US').transform(new Date(value.expiryDate.seconds * 1000), 'longDate');
      return `Premium Slot (Valid Until: ${expiryDate})`;
    } else {
      return 'Free Slot';
    }

  }

}

import { Injectable } from '@angular/core';
import { environment, LendiProductRatingRecord } from 'projects/lendi-business/src/public-api';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';

@Injectable({ providedIn: 'root' })
export class ProductRatingService {

  constructor(private $db: AngularFirestore) {}

  get ref() {
    return this.$db.collection(environment.collections.productRatings);
  }

  getProductRatingCounts(productId: string) {
    return this.ref.doc(productId).get();
  }

  getProductReviews(productId: string) {
    return this.ref.doc(productId).collection(environment.collections.ratings).get();
  }

  getPostedProductReviews(productId: string, count: number, lastDoc?: any) {
    if (lastDoc) {
      return this.ref.doc(productId).collection(environment.collections.ratings, ref =>
        ref.orderBy('createdAt', 'desc')
        .where('isActive', '==', true)
        .where('isDeleted', '==', false)
        .limit(count)
        .startAfter(lastDoc)
      ).get();
    } else {
      return this.ref.doc(productId).collection(environment.collections.ratings, ref =>
        ref.orderBy('createdAt', 'desc')
        .where('isActive', '==', true)
        .where('isDeleted', '==', false)
        .limit(count)
      ).get();
    }
  }

  getProductReviewByBorrowRequest(productId: string, borrowRequestId: string) {
    return this.ref.doc(productId)
    .collection(environment.collections.ratings,
      ref => ref
      .where('borrowRequestId', '==', borrowRequestId).limit(1)).get();
  }

  saveProductRating(productRating: LendiProductRatingRecord) {
    const processSaveProductRatingAPI = firebase.functions().httpsCallable('processSaveProductRating');
    return processSaveProductRatingAPI({productRating});
  }

}

import { Pipe, PipeTransform } from '@angular/core';
import { LendiCreditRecord, LendiCreditType } from 'projects/lendi-business/src/public-api';

@Pipe({
  name: 'creditRecordFilter'
})
export class CreditRecordFilterPipe implements PipeTransform {

  transform(value: LendiCreditRecord[], type: LendiCreditType): any {
    return value.filter(f => f.type === type);
  }

}

import { AbstractControl } from '@angular/forms';

export function confirmPassword(
  c: AbstractControl
): { [key: string]: boolean } | null {
  const password = c.get('password');
  const cPassword = c.get('confirmPassword');

  if (password.pristine || cPassword.pristine) {
    return null;
  }

  if (password.value !== cPassword.value) {
    return { match: true };
  }

  return null;
}

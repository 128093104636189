import { Component, OnInit } from '@angular/core';
import { LendiProductRecord } from 'projects/lendi-business/src/public-api';
import { SpinnerService } from 'projects/shared/services/spinner.service';
import { ProductService } from 'projects/core/services/product.service';

@Component({
  selector: 'app-daily-discover',
  templateUrl: './daily-discover.component.html',
  styleUrls: ['./daily-discover.component.css']
})
export class DailyDiscoverComponent implements OnInit {

  lastDoc: any;
  products: LendiProductRecord[] = [];
  spinnerName = 'DailyDiscoverComponent';

  constructor(private productService: ProductService, private spinner: SpinnerService) { }

  ngOnInit() {
    setTimeout(() => {
      this.loadData(8);
    }, 200);
  }

  viewMore() {
    if (this.lastDoc) {
      this.loadData(8);
    }
  }

  loadData(count: number) {
    this.productService.getDailyDiscovery(count, this.lastDoc).toPromise().then(res => {
      if (res.docs.length) {
        this.products = this.products.concat(res.docs.map(d => d.data() as LendiProductRecord));
        this.lastDoc = res.docs.pop();
      }
    }).catch(err => {
      console.error(err);
    }).finally(() => {
      this.spinner.hide(this.spinnerName);
    });
  }

}

import { LendiUserRecord } from '../types';

export function userHasBankDetails(userRecord: LendiUserRecord) {
  if (!(userRecord)) {
    return false;
  }

  if (!(userRecord.bankDetails)) {
    return false;
  }

  if (!(userRecord.bankDetails.accountName) || !(userRecord.bankDetails.accountNumber) || !(userRecord.bankDetails.accountType)) {
    return false;
  }

  return true;
}

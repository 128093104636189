import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-product-info-location',
  templateUrl: './product-info-location.component.html',
  styleUrls: ['./product-info-location.component.css']
})
export class ProductInfoLocationComponent implements OnInit {

  @Input() lng: number;
  @Input() lat: number;

  constructor() { }

  ngOnInit() {
  }

}

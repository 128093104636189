import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { SignUpFormComponent } from './sign-up-form/sign-up-form.component';
import { LoginGuard } from 'projects/core/guards/login.guard';
import { ForgotPasswordFormComponent } from './forgot-password-form/forgot-password-form.component';
import { ChangePasswordFormComponent } from './change-password-form/change-password-form.component';
import { ManageAccountComponent } from './manage-account/manage-account.component';


const routes: Routes = [
  {
    path: 'auth',
    component: AuthComponent,
    children: [
      { path: '', component: LoginFormComponent },
      { path: 'login', component: LoginFormComponent, canActivate: [ LoginGuard ] },
      { path: 'forgot-password', component: ForgotPasswordFormComponent, canActivate: [ LoginGuard ] },
      { path: 'change-password', component: ChangePasswordFormComponent, canActivate: [ LoginGuard ] },
      { path: 'manage-account', component: ManageAccountComponent, canActivate: [ LoginGuard ] },
      { path: 'sign-up', component: SignUpFormComponent, canActivate: [ LoginGuard ] }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {}

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({providedIn: 'root'})
export class SocialMediaLinkService {

  constructor(private afStore: AngularFirestore) {}

  get socialMediaLinks() {
    return this.afStore.collection('app-config').doc('social-media-links').collection('social-media-links').valueChanges();
  }

}

import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { UploadTask } from '@angular/fire/storage/interfaces';
import { LendiUserService } from './lendi-user.service';
import { DatePipe } from '@angular/common';
import { LendiPhoto, LendiProductRecord, environment, ItemTrackerStatus, BorrowRequestStatus } from 'projects/lendi-business/src/public-api';

@Injectable({ providedIn: 'root' })
export class ProductService {
  productUploadTask: UploadTask;

  constructor(
    private afStore: AngularFirestore,
    private $storage: AngularFireStorage,
    private lendiUserService: LendiUserService
  ) {}

  ref() {
    return this.afStore.collection(environment.collections.products);
  }

  refByUid(uid: string) {
    return this.afStore.collection(environment.collections.products, ref => ref.where('uid', '==', uid));
  }

  saveProduct(product: LendiProductRecord) {
    return this.ref()
      .doc(product.id)
      .update(product)
      .then(res => {
        return res;
      })
      .catch(error => {
        console.error(error);
        return null;
      });
  }

  editProduct(product: LendiProductRecord) {
    return this.ref().doc(product.id).update(product)
      .then(res => {
        return res;
      })
      .catch(error => {
        console.error(error);
        return null;
      });
  }

  deleteImage(url: string) {
    return this.$storage.storage.refFromURL(url).delete().then(res => {
      return res;
    }).catch(error => {
      console.error(error);
    });
  }

  uploadProductPhotos(id: string, productFiles: LendiPhoto[], reconcile: string[] = []) {
    const newImageIndexes = reconcile.map((r: string, i: number) =>  {
      if (r.indexOf('data:image') !== -1) {
        return i;
      } else {
        return -1;
      }
    }).filter(i => i !== -1);

    const uploadProductImages = new Promise((resolve, reject) => {
      Promise.all(
        productFiles.map(productFile => {
          return this.$storage.storage.ref(`${environment.collections.products}/${this.lendiUserService.currentUser.value.id}/${id}/${productFile.file.name}`)
            .put(productFile.file)
            .then(res => {
              return res.metadata.fullPath;
            });
        })
      ).then(res => {
        return Promise.all(res.map(p => this.$storage.storage.ref(p).getDownloadURL().then(url => url)))
        .then(urls => {
          const fullPathImages = reconcile.length ? reconcile.map((r: string, i: number) =>  {
            return newImageIndexes.find(n => n === i) !== undefined ? urls.shift() : r;
          }) : urls.map(p => p);

          this.ref().doc(id).update({productImages: fullPathImages})
          .then(response => {
            resolve(fullPathImages);
            return fullPathImages;
          });
        });
      })
      .catch(error => {
        console.error(error);
        reject(error);
      });
    })
    .catch(error => {
      console.error(error);
    });

    return uploadProductImages;
  }

  /* My Items */

  geyMyItemsAllProductsByUserId(uid: string, limit = 20, lastDoc?: any) {
    if (lastDoc) {
      return this.afStore.collection(environment.collections.products, ref => ref
        .where('uid', '==', uid)
        .where('isDeleted', '==', false)
        .where('slot.isExpired', '==', false)
        .orderBy('createdAt', 'desc')
        .startAfter(lastDoc)
      );
    } else {
      return this.afStore.collection(environment.collections.products, ref => ref
        .where('uid', '==', uid)
        .where('isDeleted', '==', false)
        .where('slot.isExpired', '==', false)
        .orderBy('createdAt', 'desc')
      );
    }
  }

  getMyItemsForVerificationProductsByUserId(uid: string, limit = 20, lastDoc?: any) {
    if (lastDoc) {
      return this.afStore.collection(environment.collections.products, ref => ref
        .limit(limit)
        .where('uid', '==', uid)
        .where('isDeleted', '==', false)
        .where('isVerified', '==', false)
        .where('slot.isExpired', '==', false)
        .orderBy('createdAt', 'desc')
        .startAfter(lastDoc)
      );
    } else {
      return this.afStore.collection(environment.collections.products, ref => ref
        .limit(limit)
        .where('uid', '==', uid)
        .where('isDeleted', '==', false)
        .where('isVerified', '==', false)
        .where('slot.isExpired', '==', false)
        .orderBy('createdAt', 'desc')
      );
    }
  }

  getMyItemsAvailableProductsByUserId(uid: string, limit = 20, lastDoc?: any) {
    if (lastDoc) {
      return this.afStore
      .collection(environment.collections.products, ref =>
        ref
          .where('uid', '==', uid)
          .where('isActive', '==', true)
          .where('isDeleted', '==', false)
          .where('isVerified', '==', true)
          .where('slot.isExpired', '==', false)
          .limit(limit)
          .startAfter(lastDoc)
          .orderBy('createdAt', 'desc')
      );
    } else {
      return this.afStore
      .collection(environment.collections.products, ref =>
        ref
          .where('uid', '==', uid)
          .where('isActive', '==', true)
          .where('isDeleted', '==', false)
          .where('isVerified', '==', true)
          .where('slot.isExpired', '==', false)
          .limit(limit)
          .orderBy('createdAt', 'desc')
      );
    }
  }

  getMyItemsBorrowedProductsByUserId(uid: string, limit = 20, lastDoc?: any) {
    if (lastDoc) {
      return this.afStore
      .collection(environment.collections.borrowRequests, ref =>
        ref
          .limit(limit)
          .where('product.uid', '==', uid)
          .where('isDeleted', '==', false)
          .where('isActive', '==', true)
          .where('status', '==', BorrowRequestStatus.Current)
          .orderBy('createdAt', 'desc')
          .startAfter(lastDoc)
      );
    } else {
      return this.afStore
      .collection(environment.collections.borrowRequests, ref =>
        ref
          .limit(limit)
          .where('product.uid', '==', uid)
          .where('isDeleted', '==', false)
          .where('isActive', '==', true)
          .where('status', '==', BorrowRequestStatus.Current)
          .orderBy('createdAt', 'desc')
      );
    }
  }

  getMyItemsExpiringProductsByUserId(uid: string, limit = 20, lastDoc?: any) {
    if (lastDoc) {
      return this.afStore.collection(environment.collections.products, ref => ref
        .limit(limit)
        .where('uid', '==', uid)
        .where('isDeleted', '==', false)
        .where('slot.isExpired', '==', false)
        .where('slot.isPremium', '==', true)
        .where('slot.expiryDate', '<=', new DatePipe('en-US').transform(new Date().setDate(new Date().getDate() + 5), 'y-MM-dd'))
        .startAfter(lastDoc)
      );
    } else {
      return this.afStore.collection(environment.collections.products, ref => ref
        .limit(limit)
        .where('uid', '==', uid)
        .where('isDeleted', '==', false)
        .where('slot.isExpired', '==', false)
        .where('slot.isPremium', '==', true)
        .where('slot.expiryDate', '<=', new DatePipe('en-US').transform(new Date().setDate(new Date().getDate() + 5), 'y-MM-dd'))
      );
    }
  }

  getMyItemsToShipProductsByUserId(uid: string, limit = 20, lastDoc?: any) {
    if (lastDoc) {
      return this.afStore
      .collection(environment.collections.borrowRequests, ref =>
        ref
          .where('status', '<', BorrowRequestStatus.Completed)
          .where('product.uid', '==', uid)
          .where('isDeleted', '==', false)
          .where('isActive', '==', true)
          .where('itemTrackerStatus', 'in', [
            ItemTrackerStatus.Pending,
            ItemTrackerStatus.AssignedRiderFromLendertoBorrower,
            ItemTrackerStatus.RiderOnTheWayToLender,
            ItemTrackerStatus.PickedUpFromLender
          ])
          .orderBy('status')
          .orderBy('createdAt', 'desc')
          .limit(limit)
          .startAfter(lastDoc)
      );
    } else {
      return this.afStore
      .collection(environment.collections.borrowRequests, ref =>
        ref
          .where('status', '<', BorrowRequestStatus.Completed)
          .where('product.uid', '==', uid)
          .where('isDeleted', '==', false)
          .where('isActive', '==', true)
          .where('itemTrackerStatus', 'in', [
            ItemTrackerStatus.Pending,
            ItemTrackerStatus.AssignedRiderFromLendertoBorrower,
            ItemTrackerStatus.RiderOnTheWayToLender,
            ItemTrackerStatus.PickedUpFromLender
          ])
          .orderBy('status')
          .orderBy('createdAt', 'desc')
          .limit(limit)
      );
    }
  }

  /* My Items */


  getProductById(id: string) {
    return this.ref().doc(id).ref.get().then((p) => {
        if (p.exists) {
          return p.data();
        } else {
          return null;
        }
      }).catch((error) => {
        console.error(error);
        return false;
      });
  }

  getProductByCategory(itemsPerPage = 16, category: string, lastDoc?: any) {
    if (lastDoc) {
      return this.afStore
        .collection(environment.collections.products, ref =>
          ref
            .limit(itemsPerPage)
            .orderBy('product.category', 'asc')
            .orderBy('createdAt', 'desc')
            .where('isVerified', '==', true)
            .where('isActive', '==', true)
            .where('isDeleted', '==', false)
            .where('slot.isExpired', '==', false)
            .where('product.category', '>=', category)
            .where('product.category', '<', category + '~')
            .startAfter(lastDoc)
        ).get();
    } else {
      return this.afStore
        .collection(environment.collections.products, ref =>
          ref
            .limit(itemsPerPage)
            .orderBy('product.category', 'asc')
            .orderBy('createdAt', 'desc')
            .where('isVerified', '==', true)
            .where('isActive', '==', true)
            .where('isDeleted', '==', false)
            .where('slot.isExpired', '==', false)
            .where('product.category', '>=', category)
            .where('product.category', '<', category + '~')
        ).get();
    }
  }

  getProductBySearchTerm(itemsPerPage = 16, searchTerm: string, lastDoc?: any) {
    const searchTermAsKeywords = searchTerm.toLocaleLowerCase().split(' ');

    if (lastDoc) {
      return this.afStore
        .collection(environment.collections.products, ref =>
          ref
            .limit(itemsPerPage)
            .where('isVerified', '==', true)
            .where('isActive', '==', true)
            .where('isDeleted', '==', false)
            .where('slot.isExpired', '==', false)
            .where('keywords', 'array-contains-any', searchTermAsKeywords)
            .orderBy('createdAt', 'desc')
            .startAfter(lastDoc)
        ).get();
    } else {
      return this.afStore
        .collection(environment.collections.products, ref =>
          ref
            .limit(itemsPerPage)
            .where('isVerified', '==', true)
            .where('isActive', '==', true)
            .where('isDeleted', '==', false)
            .where('slot.isExpired', '==', false)
            .where('keywords', 'array-contains-any', searchTermAsKeywords)
            .orderBy('createdAt', 'desc')
        ).get();
    }
  }

  getProducts(itemsPerPage: number) {
    return this.afStore
      .collection(environment.collections.products, ref => ref
        .limit(itemsPerPage)
        .where('isDeleted', '==', false)
        .where('isVerified', '==', true)
        .orderBy('createdAt', 'desc')
      ).get();
  }

  getMostPopular(itemsPerPage = 4, lastDoc?: any) {
    if (lastDoc) {
      return this.afStore
      .collection(environment.collections.products, ref => ref
        .limit(itemsPerPage)
        .orderBy('createdAt', 'desc')
        .where('isActive', '==', true)
        .where('isDeleted', '==', false)
        .where('isVerified', '==', true)
        .where('slot.isExpired', '==', false)
        .startAfter(lastDoc)
      ).get();
    } else {
      return this.afStore
      .collection(environment.collections.products, ref => ref
        .limit(itemsPerPage)
        .orderBy('createdAt', 'desc')
        .where('isActive', '==', true)
        .where('isDeleted', '==', false)
        .where('isVerified', '==', true)
        .where('slot.isExpired', '==', false)
      ).get();
    }
  }

  getDailyDiscovery(itemsPerPage = 8, lastDoc?: any) {
    if (lastDoc) {
      return this.afStore
      .collection(environment.collections.products, ref => ref
        .limit(itemsPerPage)
        .orderBy('createdAt', 'desc')
        .where('isActive', '==', true)
        .where('isDeleted', '==', false)
        .where('isVerified', '==', true)
        .where('slot.isExpired', '==', false)
        .startAfter(lastDoc)
      ).get();
    } else {
      return this.afStore
      .collection(environment.collections.products, ref => ref
        .limit(itemsPerPage)
        .orderBy('createdAt', 'desc')
        .where('isActive', '==', true)
        .where('isDeleted', '==', false)
        .where('isVerified', '==', true)
        .where('slot.isExpired', '==', false)
      ).get();
    }
  }

  getPostedProductsByUserId(uid: string, itemsPerPage: number, lastDoc?: any) {
    if (lastDoc) {
      return this.afStore
      .collection(environment.collections.products, ref =>
        ref
        .limit(itemsPerPage)
        .orderBy('createdAt', 'desc')
        .where('uid', '==', uid)
        .where('isActive', '==', true)
        .where('isVerified', '==', true)
        .where('isDeleted', '==', false)
        .where('slot.isExpired', '==', false)
        .startAfter(lastDoc)
      ).get();
    } else {
      return this.afStore
      .collection(environment.collections.products, ref =>
        ref
        .limit(itemsPerPage)
        .where('uid', '==', uid)
        .where('isActive', '==', true)
        .where('isVerified', '==', true)
        .where('isDeleted', '==', false)
        .where('slot.isExpired', '==', false)
        .orderBy('createdAt', 'desc')
      ).get();
    }
  }

  getProductsByUserId(uid: string, itemsPerPage: number, lastDoc?: any) {
    if (lastDoc) {
      return this.afStore
      .collection(environment.collections.products, ref =>
        ref
        .limit(itemsPerPage)
        .where('uid', '==', uid)
        .where('isDeleted', '==', false)
        .orderBy('createdAt', 'desc')
        .startAfter(lastDoc)
      ).get();
    } else {
      return this.afStore
      .collection(environment.collections.products, ref =>
        ref
        .limit(itemsPerPage)
        .where('uid', '==', uid)
        .where('isDeleted', '==', false)
        .orderBy('createdAt', 'desc')
      ).get();
    }
  }

  getProductsWithStatusByUserId(
    uid: string,
    status: string,
    itemsPerPage: number
  ) {
    return this.afStore
      .collection(environment.collections.products, ref =>
        ref
          .limit(itemsPerPage)
          .where('uid', '==', uid)
          .where('status', '==', status)
          .orderBy('createdAt', 'desc')
      ).get();
  }

  getProductsAvialableByUserId(
    uid: string,
    itemsPerPage: number,
    subscribable = false
  ) {
    return this.afStore
      .collection(environment.collections.products, ref =>
        ref
          .limit(itemsPerPage)
          .where('uid', '==', uid)
          .where('isActive', '==', true)
          .where('isDeleted', '==', false)
          .where('isVerified', '==', true)
          .where('isCompleted', '==', false)
          .where('isCancelled', '==', false)
          .where('isBorrowed', '==', false)
          .orderBy('createdAt', 'desc')
      ).get();
  }

  getProductsCompletedByUserId(
    uid: string,
    itemsPerPage: number
  ) {
    return this.afStore
      .collection(environment.collections.products, ref =>
        ref
          .limit(itemsPerPage)
          .where('uid', '==', uid)
          .where('isDeleted', '==', false)
          .where('isCompleted', '==', true)
          .orderBy('createdAt', 'desc')
      ).get();
  }

  getProductsCancelledByUserId(
    uid: string,
    itemsPerPage: number
  ) {
    return this.afStore
      .collection(environment.collections.products, ref =>
        ref
          .limit(itemsPerPage)
          .where('uid', '==', uid)
          .where('isDeleted', '==', false)
          .where('isBorrowed', '==', true)
          .orderBy('createdAt', 'desc')
      ).get();
  }

  getProductImagesById(uid: string, productId: string) {
    return this.$storage.storage
      .ref(`${environment.collections.products}/${uid}/${productId}/`)
      .list({ maxResults: 5 })
      .then(res => {
        if (res.items.length) {
          return Promise.all(
            res.items.map(image => {
              return image
                .getDownloadURL()
                .then(url => {
                  if (url) {
                    return url;
                  } else {
                    return false;
                  }
                })
                .catch(error => {
                  console.error(error);
                  return false;
                });
            })
          ).then(allImages => {
            return allImages as any;
          });
        } else {
          return false;
        }
      })
      .catch(error => {
        console.error(error);
        return false;
      });
  }
}

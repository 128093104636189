import { Component, OnInit, Input } from '@angular/core';
import { UserRatingRecord, LendiUserRecord } from 'projects/lendi-business/src/public-api';
import { LendiUserService } from 'projects/core/services/lendi-user.service';
import { SpinnerService } from 'projects/shared/services/spinner.service';

@Component({
  selector: 'app-user-rating-card',
  templateUrl: './user-rating-card.component.html',
  styleUrls: ['./user-rating-card.component.css']
})
export class UserRatingCardComponent implements OnInit {

  spinnerName = 'UserRatingCardComponent';

  @Input() userRating: UserRatingRecord;
  userRecord: LendiUserRecord;
  staticDate: Date;

  constructor(private $user: LendiUserService, private spinner: SpinnerService) { }

  ngOnInit() {

    this.spinnerName = [
      this.spinnerName,
      this.userRating.uid,
      this.userRating.createdAt.seconds,
      this.userRating.borrowRequestId
    ].join('-');

    this.spinner.show(this.spinnerName, true);

    this.$user.ref.doc(this.userRating.uid).get().toPromise().then(res => {
      this.userRecord = (res.data() as LendiUserRecord);
    }).catch(err => {
      console.error(err);
    }).finally(() => {
      this.spinner.hide(this.spinnerName);
    });

    this.staticDate = new Date(this.userRating.createdAt.seconds * 1000);
  }

}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-product-photo-gallery-horizontal-preview',
  templateUrl: './product-photo-gallery-horizontal-preview.component.html',
  styleUrls: ['./product-photo-gallery-horizontal-preview.component.css']
})
export class ProductPhotoGalleryHorizontalPreviewComponent implements OnInit {

  @Input() imageUrls: string[];

  defaultImageUrl = 'assets/images/verify-placeholder.png';

  constructor() { }

  ngOnInit() {
    if (!this.imageUrls) {
      this.imageUrls = [];
    }
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { LendiProductRecord, LendiUserRecord } from 'projects/lendi-business/src/public-api';

@Component({
  selector: 'app-product-dumb-card',
  templateUrl: './product-dumb-card.component.html',
  styleUrls: ['./product-dumb-card.component.css']
})
export class ProductDumbCardComponent implements OnInit {

  @Input() product: LendiProductRecord;
  @Input() user: LendiUserRecord;

  constructor() { }

  ngOnInit() {
  }

}

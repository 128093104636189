import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { BorrowService } from 'projects/core/services/borrow.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit, OnDestroy {

  currentYear;
  myRequestCountSubscription: Subscription;
  myRequestCount = 0;

  constructor(private $borrowRequest: BorrowService) {}

  ngOnInit() {
    this.currentYear = new Date().getFullYear();

    this.myRequestCountSubscription = this.$borrowRequest.myCurrentRequestCount.subscribe(count => {
      this.myRequestCount = count;
    });
  }

  ngOnDestroy() {
    if (this.myRequestCountSubscription) {
      this.myRequestCountSubscription.unsubscribe();
    }
  }

}

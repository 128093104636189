import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { FaqComponent } from './faq/faq.component';
import { AuthGuard } from 'projects/core/guards/auth.guard';
import { AuthVerifiedGuard } from 'projects/core/guards/auth-verified.guard';


const routes: Routes = [
  {
    path: 'contact-us',
    loadChildren: () => import('projects/contact-us/src/app/contact-us-export.module').then(m => m.ContactUsExportModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('projects/profile/src/app/profile-export.module').then(m => m.ProfileExportModule),
  },
  {
    path: 'products',
    loadChildren: () => import('projects/product/src/app/product-export.module').then(m => m.ProductExportModule)
  },
  {
    path: 'messages',
    loadChildren: () => import('projects/chat/src/app/chat-export.module').then(m => m.ChatExportModule),
    canActivate: [AuthGuard, AuthVerifiedGuard]
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home'
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'about-us',
    component: AboutUsComponent
  },
  {
    path: 'faq',
    component: FaqComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

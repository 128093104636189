import { Component, OnInit, Input } from '@angular/core';
import { LendiProductRecord, RatingCount } from 'projects/lendi-business/src/public-api';
import { SpinnerService } from 'projects/shared/services/spinner.service';
import { ProductRatingService } from 'projects/core/services/product-rating.service';

@Component({
  selector: 'app-product-rating',
  templateUrl: './product-rating.component.html',
  styleUrls: ['./product-rating.component.css']
})
export class ProductRatingComponent implements OnInit {

  spinnerName = '';
  @Input() product: LendiProductRecord;

  ratingCount: RatingCount = {
    averageRating: 0,
    totalRating: 0,
    summaryRating: 0
  };

  constructor(private spinner: SpinnerService, private productRating: ProductRatingService) { }

  ngOnInit() {
    this.spinnerName = this.product.id;
    this.spinner.show(this.spinnerName);
    this.productRating.getProductRatingCounts(this.product.id).toPromise().then(res => {
      this.ratingCount = res.data() as RatingCount;
    }).finally(() => {
      this.spinner.hide(this.spinnerName);
    });
  }

}

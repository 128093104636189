import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-location-map',
  templateUrl: './location-map.component.html',
  styleUrls: ['./location-map.component.css']
})
export class LocationMapComponent implements OnInit {

  @Input() isEditMode: boolean;
  @Input() lng: number;
  @Input() lat: number;
  editMode = false;

  constructor() {}

  ngOnInit() {
    if (this.isEditMode) {
      this.editMode = this.editMode;
    }
  }

  onMapClick(event) {
    if (this.editMode) {
      this.lat = event.coords.lat;
      this.lng = event.coords.lng;
    }
  }


}

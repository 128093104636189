import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SocialMediaLinkService } from 'projects/core/services/social-media-link.service';

@Component({
  selector: 'app-social-links-list',
  templateUrl: './social-links-list.component.html',
  styleUrls: ['./social-links-list.component.css']
})
export class SocialLinksListComponent implements OnInit {

  links: Observable<any[]>;

  constructor(private socialMediaLinkService: SocialMediaLinkService) { }

  ngOnInit() {
    this.links = this.socialMediaLinkService.socialMediaLinks;
  }

  openTab(url) {
    window.open(url, '_blank');
    return false;
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { LendiUserService } from 'projects/core/services/lendi-user.service';
import { Subscription } from 'rxjs';
import { LendiUserRecord } from 'projects/lendi-business/src/public-api';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit, OnDestroy {

  isLoggedIn = false;
  displaySearchBar = true;
  userSubscription: Subscription;

  constructor(private route: Router, private $user: LendiUserService) { }

  ngOnInit() {
    this.route.events.subscribe(r => {
      if (r instanceof NavigationEnd) {
        this.checkRoute(r.url);
      }
    });

    this.$user.currentUser.subscribe((user: LendiUserRecord) => {
      if (user) {
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
      }
    });
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  checkRoute(route: string) {
    this.displaySearchBar = route.indexOf('messages') === -1;
  }

}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthLinksComponent } from "./auth-links/auth-links.component";
import { LoginFormComponent } from "./login-form/login-form.component";
import { SignUpFormComponent } from "./sign-up-form/sign-up-form.component";
import { AuthComponent } from "./auth.component";
import { AuthRoutingModule } from './auth-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ForgotPasswordFormComponent } from './forgot-password-form/forgot-password-form.component';
import { ChangePasswordFormComponent } from './change-password-form/change-password-form.component';
import { ManageAccountComponent } from './manage-account/manage-account.component';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  declarations: [
    AuthLinksComponent,
    LoginFormComponent,
    SignUpFormComponent,
    AuthComponent,
    ForgotPasswordFormComponent,
    ChangePasswordFormComponent,
    ManageAccountComponent
  ],
  imports: [CommonModule, AuthRoutingModule, ReactiveFormsModule, NgxSpinnerModule],
  exports: [AuthLinksComponent, LoginFormComponent, SignUpFormComponent,
    ForgotPasswordFormComponent,
    ChangePasswordFormComponent,
    ManageAccountComponent]
})
export class AuthModule {}

export const spinnerConfig  = {
  size: 'medium',
  color: '#5c297f',
  type: 'ball-spin',
  bdColor: 'rgba(255,255,255,0.5)',
  fullScreen: true
};

export const spinnerContainerConfig  = {
  size: 'medium',
  color: '#5c297f',
  type: 'ball-spin',
  bdColor: 'rgba(255,255,255,0.5)',
  fullScreen: false
};

export const spinnerSmallContainerConfig  = {
  size: 'small',
  color: '#5c297f',
  type: 'ball-spin',
  bdColor: 'rgba(255,255,255,0.5)',
  fullScreen: false
};

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-product-photo-gallery-horizontal-editable-preview',
  templateUrl: './product-photo-gallery-horizontal-editable-preview.component.html',
  styleUrls: ['./product-photo-gallery-horizontal-editable-preview.component.css']
})
export class ProductPhotoGalleryHorizontalEditablePreviewComponent implements OnInit {

  @Input() imageUrls: string[];
  @Output() removeImageEvent: EventEmitter<string[]> = new EventEmitter<string[]>();

  defaultImageUrl = 'assets/images/verify-placeholder.png';

  constructor() { }

  ngOnInit() {
    if (!this.imageUrls) {
      this.imageUrls = [];
    }
  }

  removeImage(index: number) {
    this.imageUrls.splice(index, 1);
    this.removeImageEvent.emit(this.imageUrls);
  }

}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SpinnerService } from 'projects/shared/services/spinner.service';
import { setErrorMessage } from 'projects/shared/validators/validation-messages';
import { AngularFireAuth } from '@angular/fire/auth';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.css']
})
export class ChangePasswordFormComponent implements OnInit {
  changePasswordForm: FormGroup;
  formErrors: { [key: string]: string };
  code: string;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private spinner: SpinnerService,
    private afAuth: AngularFireAuth) {}

    get passwordGroup() {
    return this.changePasswordForm.get('passwordGroup');
  }
  get password() {
    return this.changePasswordForm.get('passwordGroup.password');
  }
  get confirmPassword() {
    return this.changePasswordForm.get('passwordGroup.confirmPassword');
  }

  ngOnInit() {
    this.validateState();
    this.setupForm();
  }

  validateState() {
    this.code = window.history.state.code;

    if (this.code === undefined) {
      this.router.navigate(['/home']);
    }
  }

  setupForm() {
    this.changePasswordForm = this.fb.group({
      passwordGroup: this.fb.group({
          password: ['', [Validators.required, Validators.minLength(6)]],
          confirmPassword: ['', [Validators.required]]
        })
    });

    this.formErrors = {
      passwordGroup: '',
      password: '',
      confirmPassword: '',
    };

    this.passwordGroup.statusChanges.subscribe(status => {
      this.formErrors.passwordGroup = setErrorMessage(
        this.passwordGroup,
        'passwordGroup'
      );
    });
    this.password.valueChanges.subscribe(value => {
      this.formErrors.password = setErrorMessage(this.password, 'password');
    });
    this.confirmPassword.valueChanges.subscribe(value => {
      this.formErrors.confirmPassword = setErrorMessage(
        this.confirmPassword,
        'confirmPassword'
      );
    });
  }

  changePassword() {
    if (this.changePasswordForm.valid) {
      this.spinner.show();
      this.afAuth.auth.confirmPasswordReset(this.code, this.password.value)
      .then((res) => {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'New password successfully saved.',
          showConfirmButton: false,
          timer: 2000
        }).then(() => {
          this.router.navigate(['auth/login']);
        });
      }).catch(err => {
        console.error(err);
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'There was a problem with your request. Please contact support.',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          this.router.navigate(['contact-us']);
        });
      }).finally(() => {
        this.spinner.hide();
      })
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please fill up all the required fields.',
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-to-rent-panel',
  templateUrl: './how-to-rent-panel.component.html',
  styleUrls: ['./how-to-rent-panel.component.css']
})
export class HowToRentPanelComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { UploadTask } from '@angular/fire/storage/interfaces';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { LendiUserRecord, LendiUserVerificatioInfo, LendiPhoto, environment, LendiUserProfileAddress } from 'projects/lendi-business/src/public-api';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LendiUserService {

  userProfileUploadTask: UploadTask;
  profilePhotoPathStorage = '/profile-photo';
  currentUser: BehaviorSubject<LendiUserRecord>;
  userDocSubscription: Subscription;

  constructor(
    private afStore: AngularFirestore,
    private $storage: AngularFireStorage,
    private router: Router,
    private afAuthService: AngularFireAuth
    ) {

    this.currentUser = new BehaviorSubject<LendiUserRecord>(null);
    this.resetUserProfileSubscription();
    this.afAuthService.user.subscribe((user) => {
      if (user) {
        this.setUserProfile(user.uid);
      } else {
        this.currentUser.next(null);
      }
    });
  }

  get ref() {
    return this.afStore.collection(environment.collections.userProfiles);
  }

  getUserProfilesByUIDs(uids: string[]) {
    return this.afStore.collection(environment.collections.userProfiles,
      ref => ref
      .where('id', 'in', uids)
    ).get();
  }

  isMobileNumberInUse(mobile: string) {
    return this.afStore.collection(environment.collections.userProfiles,
      ref => ref
      .limit(1)
      .where('profile.mobile', '==', mobile.toString())).get();
  }

  isEmailAlreadyInUse(email: string) {
    return this.afStore.collection(environment.collections.userProfiles,
      ref => ref
      .limit(1)
      .where('profile.email', '==', email)).get();
  }

  updateProfileAddress(uid: string, profileAddress: LendiUserProfileAddress) {
    return this.afStore.doc(`${environment.collections.userProfiles}/${uid}`).update({ profileAddress }).catch(err => {
      console.error(err);
    });
  }

  resetUserProfileSubscription() {
    this.currentUser.next(null);

    if (this.userDocSubscription) {
      this.userDocSubscription.unsubscribe();
    }
  }

  setUserProfile(userId: string) {
    this.resetUserProfileSubscription();
    this.userDocSubscription = this.ref.doc(userId).valueChanges().subscribe((userDoc: LendiUserRecord) => {
      if (userDoc === undefined) {
        this.currentUser.next(null);
        this.afAuthService.auth.signOut().finally(() => {
          this.router.navigate(['/']);
        });
      } else {
        this.currentUser.next(userDoc);
      }
    });
  }

  uploadVerificationPhoto(id: string, upload: LendiPhoto) {
    const uploadAndUpdate = new Promise((resolve, reject) => {
      this.userProfileUploadTask = this.$storage.storage
        .ref(`${environment.storagePath.profileVerificationPhotos}/${id}/${upload.file.name}`)
        .put(upload.file);

      return this.userProfileUploadTask.then(
        (res) => {
          return this.$storage.storage.ref(res.metadata.fullPath).getDownloadURL().then(url => {
            return this.ref.doc(id).update({ verificationInfo: {
                  verificationPhoto: url,
                  isVerified: false
                } as LendiUserVerificatioInfo
              }).then(updateResponse => {
              resolve(url);
              return updateResponse;
            });
          });
        }).catch(error => {
          console.error(error);
          reject(error);
          return false;
        });
    });

    return uploadAndUpdate;
  }

  uploadProfilePhoto(upload: LendiPhoto) {
    const uploadProfilePhotoPromise = new Promise((resolve, reject) => {

      this.clearProfilePhotos(this.currentUser.value.id).then((clearRes) => {

        this.userProfileUploadTask = this.$storage.storage
        .ref(`${this.profilePhotoPathStorage}/${this.currentUser.value.id}/${upload.file.name}`)
        .put(upload.file);

        this.userProfileUploadTask.then(
          (res) => {
            resolve(res.metadata.fullPath);
          }).catch(err => {
            console.error(err);
            reject(err);
        });

      }).catch(err => {
        console.error(err);
        reject(err);
      });
    });

    return uploadProfilePhotoPromise;
  }

  clearProfilePhotos(uid: string) {
    const clearProfilePhotosPromise = new Promise((resolve, reject) => {

      this.$storage.storage.ref(`${this.profilePhotoPathStorage}/${uid}/`).listAll().then(res => {
        if (res.items.length) {

          Promise.all(res.items.map(image => {
            return image.delete();
          })).then(d => {
            resolve(d);
          }).catch(err => {
            console.error(err);
            reject(err);
          });

        } else {
          resolve(true);
        }
      }).catch(err => {
        console.error(err);
        reject(err);
      });

    });

    return clearProfilePhotosPromise;
  }

  getProfileUrlByFullPath(fullPath) {
    return this.$storage.storage.ref(fullPath).getDownloadURL().then((url) => {
      return url;
    }).catch(error => {
      console.error(error);
      return false;
    });
  }

  getProfileUrlById(uid: string) {
    return this.$storage.storage.ref(`${this.profilePhotoPathStorage}/${uid}/`).list({maxResults: 1}).then(res => {
      if (res.items.length) {
        return res.items[0].getDownloadURL().then(url => {
          return url;
        }).catch(error => {
          console.error(error);
          return false;
        });
      } else {
        return false;
      }
    }).catch(error => {
      console.error(error);
      return false;
    });
  }

}

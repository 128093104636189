import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-product-overall-rating',
  templateUrl: './product-overall-rating.component.html',
  styleUrls: ['./product-overall-rating.component.css']
})
export class ProductOverallRatingComponent implements OnInit {

  @Input() overallRating: number;
  @Input() totalRatings: number;

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { LendiUserService } from 'projects/core/services/lendi-user.service';
import { Subscription } from 'rxjs';
import { BorrowService } from 'projects/core/services/borrow.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  isAuthenticated = false;
  myRequestCountSubscription: Subscription;
  myRequestCount = 0;

  constructor(private $user: LendiUserService, private $borrowRequest: BorrowService) { }

  ngOnInit() {
    this.$user.currentUser.subscribe(user => {
      if (user) {
        this.isAuthenticated = true;
      } else {
        this.isAuthenticated = false;
      }
    });

    this.myRequestCountSubscription = this.$borrowRequest.myCurrentRequestCount.subscribe(count => {
      this.myRequestCount = count;
    });
  }

  ngOnDestroy() {
    if (this.myRequestCountSubscription) {
      this.myRequestCountSubscription.unsubscribe();
    }
  }

}

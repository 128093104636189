import { Injectable } from '@angular/core';
import { environment, AppDeliveryFee, LendiProductRecord, BorrowRequestRecord,
  BorrowRequestStatus, VerificationStatus, BorrowRequest, BorrowRequestState,
  BorrowRequestFees, LenderPayoutStatus, LendiUserRecord, ItemTrackerStatus } from 'projects/lendi-business/src/public-api';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import * as DateDiff from 'date-diff';
import * as firebase from 'firebase';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Subscription } from 'rxjs/internal/Subscription';
import { combineLatest } from 'rxjs';
import { LendiUserService } from './lendi-user.service';

export const RENTAL_DAY_LIMIT = 4;

@Injectable({ providedIn: 'root' })
export class BorrowService {

  myCurrentRequestCount: BehaviorSubject<number>;
  myCurrentBorrowRequestCount: BehaviorSubject<number>;
  myCurrentLendingRequestCount: BehaviorSubject<number>;
  myPendingRequestSubscription: Subscription;
  myPendingBorrowRequestSubscription: Subscription;
  myPendingLendingRequestSubscription: Subscription;

  constructor(private $db: AngularFirestore, private http: HttpClient, private $user: LendiUserService) {
    this.myCurrentRequestCount = new BehaviorSubject<number>(0);
    this.myCurrentBorrowRequestCount = new BehaviorSubject<number>(0);
    this.myCurrentLendingRequestCount = new BehaviorSubject<number>(0);

    this.$user.currentUser.subscribe((user: LendiUserRecord) => {
      if (user) {
        this.startMyCurrentRequestCountSubscription(user.id);
      } else {
        this.resetMyCurrentRequestCountSubscription();
      }
    });
  }

  get ref() {
    return this.$db.collection(environment.collections.borrowRequests);
  }

  resetMyCurrentRequestCountSubscription() {
    this.myCurrentRequestCount.next(0);
    this.myCurrentBorrowRequestCount.next(0);
    this.myCurrentLendingRequestCount.next(0);

    if (this.myPendingRequestSubscription) {
      this.myPendingRequestSubscription.unsubscribe();
    }
  }

  startMyCurrentRequestCountSubscription(uid: string) {
    this.resetMyCurrentRequestCountSubscription();

    this.myPendingRequestSubscription = combineLatest(
      this.getMyPendingBorrowRequests(uid).snapshotChanges(),
      this.getMyPendingLendingRequests(uid).snapshotChanges()
    ).subscribe(res => {
      const borrowRequests = res[0] as any[];
      const lendingRequests = res[1] as any[];

      const borrowRequestCount = borrowRequests.length > 99 ? 99 : borrowRequests.length;
      const lendingRequestCount = lendingRequests.length > 99 ? 99 : lendingRequests.length;
      const totalRequestCount = borrowRequestCount + lendingRequestCount;
      const requestCount = totalRequestCount > 99 ? 99 : totalRequestCount;

      this.myCurrentBorrowRequestCount.next(borrowRequestCount);
      this.myCurrentLendingRequestCount.next(lendingRequestCount);
      this.myCurrentRequestCount.next(requestCount);
    });
  }

  validateBorrowRequestDate(borrowRequest: BorrowRequestRecord) {
    const validationPromise = new Promise((resolve, reject) => {

      this.getAllIncompleteRequestByProductId(borrowRequest.product.id).toPromise().then(res => {
        const overlap = false;
        const contextFrom = new Date(borrowRequest.borrowRequest.fromDate);
        const contextTo = new Date(borrowRequest.borrowRequest.toDate);

        res.docs.forEach(r => {
          const br = r.data() as BorrowRequestRecord;
          const from = new Date(br.borrowRequest.fromDate);
          const to = new Date(br.borrowRequest.fromDate);

          if ((contextFrom >= from && contextFrom <= to) || (contextTo >= from && contextTo <= to)) {
            resolve(overlap);
            return false;
          }
        });

        resolve(!overlap);
      }).catch(err => {
        console.error(err);
        reject(err);
      });
    });

    return validationPromise;
  }

  /* My Requests  */

  getMyBorrowRequests(uid: string) {
    return this.$db.collection(environment.collections.borrowRequests,
      ref => ref
      .where('uid', '==', uid)
      .where('isDeleted', '==', false)
      .where('isActive', '==', true)
      .where('verificationStatus', '<', VerificationStatus.Denied)
      .where('itemTrackerStatus', 'in', [
        ItemTrackerStatus.Pending,
        ItemTrackerStatus.Processing,
        ItemTrackerStatus.AssignedRiderFromLendertoBorrower,
        ItemTrackerStatus.RiderOnTheWayToLender,
        ItemTrackerStatus.PickedUpFromLender,
        ItemTrackerStatus.DeliverySuccessfulToBorrower,
        ItemTrackerStatus.AssignedRiderFromBorrowerToLender,
        ItemTrackerStatus.RiderOnTheWayToBorrower,
        ItemTrackerStatus.PickedUpFromBorrower,
      ])
      .orderBy('verificationStatus')
      .orderBy('createdAt', 'desc')
    );
  }

  getMyLendingRequests(uid: string) {
    return this.$db.collection(environment.collections.borrowRequests,
      ref => ref
      .where('product.uid', '==', uid)
      .where('isDeleted', '==', false)
      .where('isActive', '==', true)
      .where('verificationStatus', '<', VerificationStatus.Denied)
      .where('itemTrackerStatus', 'in', [
        ItemTrackerStatus.Pending,
        ItemTrackerStatus.Processing,
        ItemTrackerStatus.AssignedRiderFromLendertoBorrower,
        ItemTrackerStatus.RiderOnTheWayToLender,
        ItemTrackerStatus.PickedUpFromLender,
        ItemTrackerStatus.DeliverySuccessfulToBorrower,
        ItemTrackerStatus.AssignedRiderFromBorrowerToLender,
        ItemTrackerStatus.RiderOnTheWayToBorrower,
        ItemTrackerStatus.PickedUpFromBorrower,
      ])
      .orderBy('verificationStatus')
      .orderBy('createdAt', 'desc')
    );
  }

  /* My Requests  */

  /* Bubble Notif */

  getMyPendingBorrowRequests(uid: string) {
    return this.$db.collection(environment.collections.borrowRequests,
      ref => ref
      .where('uid', '==', uid)
      .where('status', '==', BorrowRequestStatus.Pending)
      .where('verificationStatus', '==', VerificationStatus.Pending)
      .where('isActive', '==', true)
      .where('isDeleted', '==', false)
      .orderBy('createdAt', 'desc')
    );
  }

  getMyPendingLendingRequests(uid: string) {
    return this.$db.collection(environment.collections.borrowRequests,
      ref => ref
      .where('product.uid', '==', uid)
      .where('status', '==', BorrowRequestStatus.Pending)
      .where('verificationStatus', '==', VerificationStatus.Pending)
      .where('isActive', '==', true)
      .where('isDeleted', '==', false)
      .orderBy('createdAt', 'desc')
    );
  }

  /* Bubble Notif */


  /* My Transactions */

  getByBorrowRequestStatusByBorrowerUserId(uid: string, status: BorrowRequestStatus) {
    return this.$db.collection(environment.collections.borrowRequests,
      ref => ref
      .where('uid', '==', uid)
      .where('isActive', '==', true)
      .where('status', '==', status)
      .where('isDeleted', '==', false)
      .where('verificationStatus', '==', VerificationStatus.Approved)
      .orderBy('createdAt', 'desc')
    );
  }

  getByLenderPayoutStatusByLenderUserId(uid: string, status: LenderPayoutStatus) {
    return this.$db.collection(environment.collections.borrowRequests,
      ref => ref
      .where('product.uid', '==', uid)
      .where('isActive', '==', true)
      .where('isDeleted', '==', false)
      .where('status', '<', BorrowRequestStatus.Cancelled)
      .where('isPaid', '==', true)
      .where('lenderPayoutStatus', '==', status)
      .where('verificationStatus', '==', VerificationStatus.Approved)
      .where('itemTrackerStatus', 'in', [
        ItemTrackerStatus.Pending,
        ItemTrackerStatus.AssignedRiderFromLendertoBorrower,
        ItemTrackerStatus.RiderOnTheWayToLender,
        ItemTrackerStatus.PickedUpFromLender,
        ItemTrackerStatus.DeliverySuccessfulToBorrower,
        ItemTrackerStatus.AssignedRiderFromBorrowerToLender,
        ItemTrackerStatus.RiderOnTheWayToBorrower,
        ItemTrackerStatus.PickedUpFromBorrower,
        ItemTrackerStatus.DeliverySuccessfulToLender,
      ])
      .orderBy('status')
      .orderBy('createdAt', 'desc')
    );
  }

  /* My Transactions */

  getAllPendingRequestsByProductOwnerId(uid: string) {
    return this.$db.collection(environment.collections.borrowRequests,
      ref => ref
      .where('isDeleted', '==', false)
      .where('product.uid', '==', uid)
      .where('status', '==', BorrowRequestStatus.Pending)
      .orderBy('createdAt', 'desc')
    ).get();
  }

  getAllIncompleteRequestByProductId(productId: string) {
    return this.$db.collection(environment.collections.borrowRequests,
      ref => ref
      .where('isDeleted', '==', false)
      .where('product.id', '==', productId)
      .where('verificationStatus', '==', VerificationStatus.Approved)
      .where('status', 'in', [BorrowRequestStatus.Pending, BorrowRequestStatus.Current])
      .orderBy('createdAt', 'desc')
    ).get();
  }

  getAllByBorrowerUserId(uid: string) {
    return this.$db.collection(environment.collections.borrowRequests,
      ref => ref
      .where('isDeleted', '==', false)
      .where('uid', '==', uid)
      .orderBy('createdAt', 'desc')
    ).get();
  }

  fix2Decimal(n: number) {
    return Math.round(n * 100) / 100;
  }

  getFeesByProduct(borrowRequestState: BorrowRequestState): BorrowRequestFees {

    const _totalDiscountedPrice = this.fix2Decimal(this.getDiscountedPrice(borrowRequestState));
    const _adminFee = this.fix2Decimal(this.getAdminFeePrice(_totalDiscountedPrice, borrowRequestState.deliveryFee.adminFeeRate));
    const _totalDeliveryFee = this.fix2Decimal(this.getDeliveryFee(borrowRequestState.deliveryFee, borrowRequestState.distance));
    const _totalPayment = this.fix2Decimal(_totalDiscountedPrice + _adminFee + _totalDeliveryFee);

    return {
      adminFee: _adminFee,
      distance: borrowRequestState.distance,
      deliveryFee: borrowRequestState.deliveryFee,
      totalDeliveryFee: _totalDeliveryFee,
      rentalFee: borrowRequestState.product.product.rentalPricePerDay,
      totalRentalFee: this.getPerDayMultipliedToInterval(borrowRequestState.borrowRequest, borrowRequestState.product),
      totalPayment: _totalPayment,
      totalDiscountedRentalFee: _totalDiscountedPrice,
      totalDiscount: this.getTotalDiscount(borrowRequestState)
    };
  }

  private getDeliveryFee(deliveryFee: AppDeliveryFee, distance: number) {
    return +((deliveryFee.baseRate + ((distance / deliveryFee.distanceMultiplier) * deliveryFee.costPerDistance))).toFixed(2);
  }

  private getPerDayMultipliedToInterval(borrowRequest: BorrowRequest, product: LendiProductRecord): number {
    return +product.product.rentalPricePerDay * this.getIntervalDate(borrowRequest);
  }

  private getIntervalDate(borrowRequest: BorrowRequest): number {
    if (borrowRequest.fromDate && borrowRequest.toDate) {
      const diff = new DateDiff(new Date(borrowRequest.toDate), new Date(borrowRequest.fromDate));
      return +diff.days() + 1;
    }
    return 0;
  }

  private getTotalAmount(borrowRequestState: BorrowRequestState) {
    return +(
      + this.getDeliveryFee(borrowRequestState.deliveryFee, borrowRequestState.distance)
      + this.getDiscountedPrice(borrowRequestState)
      + this.getAdminFeePrice(this.getTotalDiscount(borrowRequestState), borrowRequestState.deliveryFee.adminFeeRate)
    );
  }

  public getDistance(originId: string, destinationId: string) {
    const googleGetDistanceAPI = firebase.functions().httpsCallable('googleGetDistance');
    return googleGetDistanceAPI({origins: originId, destinations: destinationId});
  }

  private getWeeks(borrowRequestState: BorrowRequestState) {
    const days = this.getIntervalDate(borrowRequestState.borrowRequest);
    return (days > 6) ? Math.floor(days / 7) : 0;
  }

  private getTotalDiscount(borrowRequestState: BorrowRequestState) {
    return +((this.getPerDayMultipliedToInterval(borrowRequestState.borrowRequest, borrowRequestState.product) * (borrowRequestState.product.product.weeklyDiscount / 100)) * this.getWeeks(borrowRequestState)).toFixed(2);
  }

  private getDiscountedPrice(borrowRequestState: BorrowRequestState) {
    return this.getPerDayMultipliedToInterval(borrowRequestState.borrowRequest, borrowRequestState.product) - this.getTotalDiscount(borrowRequestState);
  }

  private getAdminFeePrice(discountedPrice: number, adminFeeRate: number) {
    return discountedPrice * (adminFeeRate / 100);
  }

  getCutOffDate() {
    const currentDate = new Date();
    if ((currentDate.getHours() === 15 && currentDate.getMinutes() > 30) || currentDate.getHours() >= 16) {
      currentDate.setDate(currentDate.getDate() + 1);
      return currentDate;
    } else {
      return currentDate;
    }
  }

}

import { Component, enableProdMode } from '@angular/core';
import { AppSettingsService } from 'projects/core/services/app-settings.service';
import { environment } from 'projects/lendi-business/src/public-api';
import { LoggerService } from 'projects/core/services/logger.service';

if (environment.production) {
  enableProdMode();
}

@Component({
  selector: 'main-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Lendi';

  constructor(private appSettings: AppSettingsService, private logService: LoggerService) {
    this.logService.initLogs();
    this.appSettings.loadDeliveryFee();
    this.appSettings.loadLendiCredits();
    this.appSettings.loadPromoBanners();
  }
}

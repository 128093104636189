import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ads-boxed-image',
  templateUrl: './ads-boxed-image.component.html',
  styleUrls: ['./ads-boxed-image.component.css']
})
export class AdsBoxedImageComponent implements OnInit {

  @Input() sideBannerUrl: string;

  constructor() { }

  ngOnInit() {
  }

}

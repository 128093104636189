import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ads-carousel',
  templateUrl: './ads-carousel.component.html',
  styleUrls: ['./ads-carousel.component.css']
})
export class AdsCarouselComponent implements OnInit {

  @Input() mainBannerUrl: string;

  constructor() { }

  ngOnInit() {
  }

}

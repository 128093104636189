import { Component, OnInit } from '@angular/core';
import { AppSettingsService } from 'projects/core/services/app-settings.service';

@Component({
  selector: 'app-ads-section',
  templateUrl: './ads-section.component.html',
  styleUrls: ['./ads-section.component.css']
})
export class AdsSectionComponent implements OnInit {

  mainBannerUrl = '';
  sideBannerUrl = '';

  constructor(public appSettings: AppSettingsService) { }

  ngOnInit() {
    if (this.appSettings.mainBannerUrl.length === 0 || this.appSettings.sideBannerUrl.length === 0) {
      this.appSettings.loadPromoBanners().then(res => {
        this.mainBannerUrl = this.appSettings.mainBannerUrl;
        this.sideBannerUrl = this.appSettings.sideBannerUrl;
      });
    } else {
      this.mainBannerUrl = this.appSettings.mainBannerUrl;
      this.sideBannerUrl = this.appSettings.sideBannerUrl;
    }
  }

}

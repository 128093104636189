import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { first } from 'rxjs/operators';
import { environment, ProductCategory } from 'projects/lendi-business/src/public-api';


@Injectable({providedIn: 'root'})
export class CategoryService {

  productCategories: ProductCategory[] = [];
  productSubCategories: ProductCategory[] = [];

  constructor(private afStore: AngularFirestore) {}

  get categories() {
    return this.afStore
    .collection('app-config')
    .doc('categories')
    .collection('categories')
    .valueChanges().pipe(first());
  }

  categoriesAsLinks() {
    const categoriesAsLinksPromise = new Promise((resolve, reject) => {
      if (this.productCategories.length) {
        resolve(this.productCategories);
      } else {
        this.afStore.collection(environment.collections.appCategories, ref =>
        ref.where('isActive', '==', true)).get().toPromise().then(res => {
          const cats = res.docs.map(d => d.data() as ProductCategory);
          resolve(cats);
        }).catch(err => {
          console.error(err);
          reject([]);
        });
      }
    });

    return categoriesAsLinksPromise;
  }

  categoriesForProduct() {
    const categoriesForProductPromise = new Promise((resolve, reject) => {

      if (this.productCategories.length) {
        resolve(this.productCategories);
      } else {
        Promise.all([this.afStore.collection(environment.collections.appCategories, ref =>
          ref.where('isActive', '==', true)).get().toPromise().then(res => {
          this.productCategories = res.docs.map(d => d.data() as ProductCategory).map(pc => {
            pc.subCategories = [];
            return pc;
          });
        }),
        this.afStore.collectionGroup(environment.collections.appSubCategories, ref =>
          ref.where('isActive', '==', true)).get().toPromise().then(res => {
          this.productSubCategories = res.docs.map(d => d.data() as ProductCategory).map(pc => {
            pc.subCategories = [];
            return pc;
          });
        })]).then(res => {

          this.productSubCategories.map(subCat => {
            this.productSubCategories.map(subSubCat => {
              if (subSubCat.category === subCat.name && subSubCat.id.indexOf(subCat.id) !== -1) {
                subCat.subCategories.push(subSubCat);
              }
            });
          });

          this.productCategories.map(cat => {
            this.productSubCategories.map(subCat => {
              if (subCat.category === cat.name) {
                cat.subCategories.push(subCat);
              }
            });
          });

          resolve(this.productCategories);
        }).catch(err => {
          console.error(err);
          reject([]);
        });
      }

    });

    return categoriesForProductPromise;
  }

// lagyan ng ID para sa dropdown

 // updateCategories() {
    //category
    // this.afStore.collection(environment.collections.appCategories).get().toPromise().then(res => {
    //   res.docs.map(d => {
    //     const id = d.id;
    //     d.ref.update({
    //       id
    //     }).then(catRes => {

    //       //sub category
    //       d.ref.collection(environment.collections.appSubCategories).get().then(subRes => {
    //         subRes.docs.map(sd => {
    //           const subId = sd.id;
    //           sd.ref.update({
    //             id: `${id}/${subId}`
    //           }).then(subCatRes => {

    //             //sub sub category
    //             sd.ref.collection(environment.collections.appSubCategories).get().then(subSubRes => {
    //               subSubRes.docs.map(ssd => {
    //                 const subSubId = ssd.id;
    //                 ssd.ref.update({
    //                   id: `${id}/${subId}/${subSubId}`
    //                 });
    //               });
    //             });

    //           });
    //         });
    //       });

    //     });
    //   });
    // });

    // catlist.map((c: any) => {

    //   //category
    //   const catId = Object.keys(c)[0];

    //   this.afStore.collection(environment.collections.appCategories).doc(catId).set({
    //     name: catId,
    //     isActive: true,
    //     image: ''
    //   }).then(res => {

    //     //sub category
    //     c[catId].map(sub => {
    //       const subCatId = this.getSubCatId(sub);
    //       if (typeof sub === 'string') {
    //         this.processSubCat(catId, subCatId, sub);
    //       } else if (sub instanceof Object) {
    //         this.processMultiSub(catId, sub);
    //       }

    //     });

    //   });

    // });

  //}

  // processMultiSub(catId, c) {
  //   const subSubCatId = Object.keys(c)[0];

  //   const data = c[subSubCatId];

  //   console.log('-----------------------' + subSubCatId);

  //   if (Array.isArray(data)) {
  //     console.log('array');
  //     this.afStore.collection(`${environment.collections.appCategories}/${catId}/${environment.collections.appSubCategories}`)
  //     .doc(subSubCatId).set({
  //       name: subSubCatId,
  //       category: catId,
  //       isActive: true,
  //       note: ''
  //     }).then(res => {
  //       data.map(o => {

  //         const id = Object.keys(o)[0];
  //         const subData = o[id];

  //         this.afStore.collection(`${environment.collections.appCategories}/${catId}/${environment.collections.appSubCategories}/${subSubCatId}/${environment.collections.appSubCategories}`)
  //         .doc(id).set({
  //           name: id,
  //           category: subSubCatId,
  //           isActive: true,
  //           note: subData.note
  //         });

  //       });
  //     });
  //   } else if (data instanceof Object) {
  //     console.log('Object');
  //     this.afStore.collection(`${environment.collections.appCategories}/${catId}/${environment.collections.appSubCategories}`)
  //     .doc(subSubCatId).set({
  //       name: subSubCatId,
  //       category: catId,
  //       isActive: true,
  //       note: data.note
  //     });
  //   }

  // }

  // getSubCatId(sub) {
  //   if (typeof sub === 'string') {
  //     return sub;
  //   }

  //   if (sub instanceof Object) {
  //     return Object.keys(sub)[0];
  //   }
  // }

  // processSubCat(catId: string, subCatId: string, sub) {
  //   if (typeof sub === 'string') {
  //     this.afStore.collection(`${environment.collections.appCategories}/${catId}/${environment.collections.appSubCategories}`)
  //     .doc(subCatId).set({
  //       name: subCatId,
  //       category: catId,
  //       isActive: true,
  //       note: ''
  //     });
  //   }

  //   if (sub instanceof Object) {

  //   }
  // }

}


// const catlist = [
//   {'Arts & Craft': [
//       'Tools for Arts & Craft',
//       'Other'
//     ]
//   },

//   {'Automotive & Motorcycles': [
//       'Car Accessories',
//       'Motorcycle Accessories',
//       'Protective Gear',
//       'Scooters E-bike',
//       'Other'
//     ]
//   },

//   {'Fashion Accessories': [
//       'Watches',
//       'Jewelry',
//       'Eyewear',
//       'Other'
//     ]
//   },

//   {'Mens Fashion': [
//       { 'Formal Wear': [
//           { 'Formal Shirts': {
//               'note': '(Please give them an option to indicate the size category (XS,S,M,L,XL,XXL) and cm size of each: Collar, Shoulder, Chest, Lower Chest, Sleeve Length.)'
//             }
//           },
//           { 'Suits & Blazers': {
//               'note': '(Please give them an option to indicate the size category (XS,S,M,L,XL,XXL) and cm size of each: Collar, Shoulder, Chest, Lower Chest, Sleeve Length.)'
//             }
//           },
//           { 'Pants': {
//               'note': '(Size Category (XS,S,M,L,XL,XXL), cm size of Waist, Length, Hip, Thigh, Bottom/Ankle)'
//             }
//           }
//         ]
//       },
//       'Other Formal Clothing and Accessories',
//       { 'Clothing': {
//           'note': '(Size Category (XS,S,M,L,XL,XXL)'
//         }
//       },
//       {'Shoes': [
//           { 'Active': {
//               'note': '(Should have a sizing option: either UK, EU, US. Lender will indicate the size number)'
//             }
//           },
//           { 'Casual': {
//               'note': '(Should have a sizing option: either UK, EU, US. Lender will indicate the size number)'
//             }
//           },
//           { 'Formal': {
//               'note': '(Should have a sizing option: either UK, EU, US. Lender will indicate the size number)'
//             }
//           },
//           { 'Other': {
//               'note': '(Should have a sizing option: either UK, EU, US. Lender will indicate the size number)'
//             }
//           }
//         ]
//       },
//       'Bags',
//       'Costume',
//       'Other'
//     ]
//   },

//   {'Womens Fashion': [
//       { 'Formal Wear': [
//           { 'Gowns': {
//               'note': '(Please indicate Sizes for Bust, Waist, Hips)'
//             }
//           },
//           { 'Formal Shirts': {
//             'note': '(Please indicate Size category (XS,S,M,L,XL,XXL) and Size for (Total Length, Shoulder, Bust, Waist, Hem, Sleeve Length, Sleeve Opening.'
//             }
//           },
//           { 'Suits & Blazers': {
//             'note': '(Please indicate Size category (XS,S,M,L,XL,XXL) and Size for (Total Length, Shoulder, Bust, Waist, Hem, Sleeve Length, Sleeve Opening.'
//             }
//           },
//           { 'Pants': {
//             'note': '(Please indicate Size Category (XS,S,M,L,XL,XXL), cm size of Waist, Length, Hip, Thigh, Bottom/Ankle)'
//             }
//           }
//         ]
//       },
//       { 'Clothing': {
//           'note': '(Please indicate Size Category (XS,S,M,L,XL,XXL)'
//         }
//       },
//       {'Shoes': [
//           {'Active': {
//               'note': '(Should have a sizing option: either UK, EU, US. Lender will indicate the size number)'
//             }
//           },
//           {'Casual': {
//               'note': '(Should have a sizing option: either UK, EU, US. Lender will indicate the size number)'
//             }
//           },
//           {'Formal': {
//               'note': '(Should have a sizing option: either UK, EU, US. Lender will indicate the size number)'
//             }
//           },
//           {'Other': {
//               'note': '(Should have a sizing option: either UK, EU, US. Lender will indicate the size number)'
//             }
//           }
//         ]
//       },
//       'Bags',
//       {
//         'Costume': {
//           'note': '(Please indicate Size Category (XS,S,M,L,XL,XXL)'
//         }
//       },
//       'Other'
//     ]
//   },


//   {'Kids': [
//       {'Formal Wear': [
//           { 'Formal Shirts': {
//               'note': '(Please give them an option to indicate the size category (XS,S,M,L,XL,XXL) and cm size of each: Collar, Shoulder, Chest, Lower Chest, Sleeve Length.)'
//             }
//           },
//           { 'Suits & Blazers': {
//               'note': '(Please give them an option to indicate the size category (XS,S,M,L,XL,XXL) and cm size of each: Collar, Shoulder, Chest, Lower Chest, Sleeve Length.)'
//             }
//           },
//           { 'Pants': {
//               'note': '(Size Category (XS,S,M,L,XL,XXL), cm size of Waist, Length, Hip, Thigh, Bottom/Ankle)'
//             }
//           },
//           { 'Gowns': {
//               'note': '(Please indicate Sizes for Chest, Waist, Hips)'
//             }
//           }
//         ]
//       },
//       { 'Clothing': {
//           'note': '(Please indicate Size Category (XS,S,M,L,XL,XXL)'
//         }
//       },
//       {'Shoes': [
//           { 'Active': {
//               'note' : '(Should have a sizing option: either UK, EU, US. Lender will indicate the size number)'
//             }
//           },
//           { 'Casual': {
//               'note' : '(Should have a sizing option: either UK, EU, US. Lender will indicate the size number)'
//             }
//           },
//           { 'Formal': {
//               'note' : '(Should have a sizing option: either UK, EU, US. Lender will indicate the size number)'
//             }
//           },
//           { 'Other': {
//               'note' : '(Should have a sizing option: either UK, EU, US. Lender will indicate the size number)'
//             }
//           }
//         ]
//       },
//       'Bags',
//       { 'Costume': {
//           'note': '(Please indicate Size Category (XS,S,M,L,XL,XXL)'
//         }
//       },
//       'Other'
//     ]
//   },


//   { 'Travelling': [
//       'Travel Accessories',
//       'Luggage',
//       'Other Travel Accessories'
//     ]
//   },


//   {'Having a Party?': [
//       'Tables & Chairs',
//       'Audio Equipment',
//       'Drinking Games',
//       'Lights',
//       'Board Games',
//       'Coolers',
//       'Other',
//     ]
//   },

//   {'For Adults only': [
//     'Adult items for rent'
//     ]
//   }
// ];

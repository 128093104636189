import { Component, OnInit, Input } from '@angular/core';
import { LendiProductRecord } from 'projects/lendi-business/src/public-api';

@Component({
  selector: 'prdc-product-status-badge',
  templateUrl: './product-status-badge.component.html',
  styleUrls: ['./product-status-badge.component.css']
})
export class ProductStatusBadgeComponent implements OnInit {

  @Input() product: LendiProductRecord;
  @Input() isToShip: boolean;
  @Input() isExpiring: boolean;

  constructor() { }

  ngOnInit() {
  }

  getClass(): string {
    if (this.product) {

      if (!this.product.isActive) {
        return 'text-danger';
      }

      if(this.isToShip) {
        return 'item-to-ship';
      }

      if(this.isExpiring) {
        return 'item-to-ship';
      }

      if (this.product.isBorrowed) {
        return 'item-borrowed';
      } else if (this.product.isCancelled) {
        return 'text-danger';
      } else if (this.product.isCompleted) {
        return 'item-available';
      } else if (this.product.isVerified) {
        return 'item-available';
      } else if (!this.product.isVerified) {
        return 'item-borrowed';
      } else {
        return 'item-borrowed';
      }
    }
  }

  getStatusLabel() {
    if (this.product) {

      if (!this.product.isActive) {
        return 'Unavailable';
      }

      if(this.isToShip) {
        return 'To Ship';
      }

      if(this.isExpiring) {
        return 'Expiring';
      }

      if (this.product.isBorrowed) {
        return 'Borrowed';
      } else if (this.product.isCancelled) {
        return 'Cancelled';
      } else if (this.product.isCompleted) {
        return 'Completed';
      } else if (this.product.isVerified) {
        return 'Available';
      } else if (!this.product.isVerified) {
        return 'For Verification';
      } else {
        return '';
      }
    }
  }

}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-profile-image',
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.css']
})
export class ProfileImageComponent implements OnInit {

  defaultProfilePhoto = 'assets/images/verify-placeholder.png';

  @Input() profilePhoto: string;

  constructor() { }

  ngOnInit() {
    if (this.profilePhoto === undefined || this.profilePhoto === null || this.profilePhoto === '' || !this.profilePhoto) {
      this.profilePhoto = this.defaultProfilePhoto;
    }
  }

}

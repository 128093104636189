export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyA9wmLjY_HBuThS9b-oI5JZO4dzCDso8lE',
    authDomain: 'lendi-4edd9.firebaseapp.com',
    databaseURL: 'https://lendi-4edd9.firebaseio.com',
    projectId: 'lendi-4edd9',
    storageBucket: 'lendi-4edd9.appspot.com',
    messagingSenderId: '796380984345',
    appId: '1:796380984345:web:14efe92e3908c4af82e688',
    measurementId: 'G-CLPC00BWG3'
  },
  collections: {
    appConfig: 'app-config',
    appCredits: 'app-credits',
    appDeliveryFee: 'app-delivery-fee',
    borrowRequests: 'borrow-requests',
    products: 'products',
    userProfiles: 'user-profile',
    userCredits: 'user-credits',
    userCreditTransactions: 'transactions',
    riderProfiles: 'rider-profile',
    employeeProfiles: 'employee-profile',
    itemStatusTracker: 'item-status-tracker',
    riderTransaction: 'rider-transaction',
    credits: 'credits',
    productSlots: 'product-slots',
    payments: 'payment-receipts',
    borrowRequestpPayments: 'payment-receipts-borrow-requests',
    lendiCreditPayments: 'payment-receipts-lendi-credits',
    productLikes: 'product-likes',
    productLikeCounter: 'product-like-counter',
    chat: 'chat',
    userRatingsAsLender: 'user-ratings-as-lender',
    userRatingsAsBorrower: 'user-ratings-as-borrower',
    productRatings: 'product-ratings',
    ratings: 'ratings',
    promoBanners: 'promo-banners',
    appCategories: 'app-categories',
    appSubCategories: 'sub-categories'
  },
  storagePath: {
    userProfilePhotos: 'profile-photo',
    riderProfilePhotos: 'rider-profile-photo',
    employeeProfilePhotos: 'employee-profile-photo',
    profileVerificationPhotos: 'profile-verification-photo',
    promoBanners: 'promo-banners'
  },
  cloudFunctions: {
    requestPayment: 'requestPayment'
  },
  ubConfig: {
    paymentUrl: 'https://secureacceptance.cybersource.com/silent/pay'
  },
  spinnerConfig: {
    size: 'medium',
    color: '#5c297f',
    type: 'ball-spin',
    bdColor: 'rgba(255,255,255,0.5)',
    fullScreen: true
  },
  spinnerContainerConfig: {
    size: 'medium',
    color: '#5c297f',
    type: 'ball-spin',
    bdColor: 'rgba(255,255,255,0.5)',
    fullScreen: false
  },
  provinces: [
    {id: 1, description: 'ABRA'},
    {id: 2, description: 'AGUSAN DEL NORTE'},
    {id: 3, description: 'AGUSAN DEL SUR'},
    {id: 4, description: 'AKLAN'},
    {id: 5, description: 'ALBAY'},
    {id: 6, description: 'ANTIQUE'},
    {id: 7, description: 'APAYAO'},
    {id: 8, description: 'AURORA'},
    {id: 9, description: 'BASILAN'},
    {id: 10, description: 'BATAAN'},
    {id: 11, description: 'BATANES'},
    {id: 12, description: 'BATANGAS'},
    {id: 13, description: 'BENGUET'},
    {id: 14, description: 'BILIRAN'},
    {id: 15, description: 'BOHOL'},
    {id: 16, description: 'BUKIDNON'},
    {id: 17, description: 'BULACAN'},
    {id: 18, description: 'CAGAYAN'},
    {id: 19, description: 'CAMARINES NORTE'},
    {id: 20, description: 'CAMARINES SUR'},
    {id: 21, description: 'CAMIGUIN'},
    {id: 22, description: 'CAPIZ'},
    {id: 23, description: 'CATANDUANES'},
    {id: 24, description: 'CAVITE'},
    {id: 25, description: 'CEBU'},
    {id: 26, description: 'COMPOSTELA VALLEY'},
    {id: 27, description: 'COTABATO'},
    {id: 28, description: 'DAVAO DEL NORTE'},
    {id: 29, description: 'DAVAO DEL SUR'},
    {id: 30, description: 'DAVAO ORIENTAL'},
    {id: 31, description: 'EASTERN SAMAR'},
    {id: 32, description: 'GUIMARAS'},
    {id: 33, description: 'IFUGAO'},
    {id: 34, description: 'ILOCOS NORTE'},
    {id: 35, description: 'ILOCOS SUR'},
    {id: 36, description: 'ILOILO'},
    {id: 37, description: 'ISABELA'},
    {id: 38, description: 'KALINGA'},
    {id: 39, description: 'LA UNION'},
    {id: 40, description: 'LAGUNA'},
    {id: 41, description: 'LANAO DEL NORTE'},
    {id: 42, description: 'LANAO DEL SUR'},
    {id: 43, description: 'LEYTE'},
    {id: 44, description: 'MAGUINDANAO'},
    {id: 45, description: 'MARINDUQUE'},
    {id: 46, description: 'MASBATE'},
    {id: 47, description: 'METRO MANILA'},
    {id: 48, description: 'MISAMIS OCCIDENTAL'},
    {id: 49, description: 'MISAMIS ORIENTAL'},
    {id: 50, description: 'MOUNTAIN PROVINCE'},
    {id: 51, description: 'NEGROS OCCIDENTAL'},
    {id: 52, description: 'NEGROS ORIENTAL'},
    {id: 53, description: 'NORTHERN SAMAR'},
    {id: 54, description: 'NUEVA ECIJA'},
    {id: 55, description: 'NUEVA VIZCAYA'},
    {id: 56, description: 'OCCIDENTAL MINDORO'},
    {id: 57, description: 'ORIENTAL MINDORO'},
    {id: 58, description: 'PALAWAN'},
    {id: 59, description: 'PAMPANGA'},
    {id: 60, description: 'PANGASINAN'},
    {id: 61, description: 'QUEZON'},
    {id: 62, description: 'QUIRINO'},
    {id: 63, description: 'RIZAL'},
    {id: 64, description: 'ROMBLON'},
    {id: 65, description: 'SAMAR'},
    {id: 66, description: 'SARANGANI'},
    {id: 67, description: 'SIQUIJOR'},
    {id: 68, description: 'SORSOGON'},
    {id: 69, description: 'SOUTH COTABATO'},
    {id: 70, description: 'SOUTHERN LEYTE'},
    {id: 71, description: 'SULTAN KUDARAT'},
    {id: 72, description: 'SULU'},
    {id: 73, description: 'SURIGAO DEL NORTE'},
    {id: 74, description: 'SURIGAO DEL SUR'},
    {id: 75, description: 'TARLAC'},
    {id: 76, description: 'TAWI-TAWI'},
    {id: 77, description: 'ZAMBALES'},
    {id: 78, description: 'ZAMBOANGA DEL NORTE'},
    {id: 79, description: 'ZAMBOANGA DEL SUR'},
    {id: 80, description: 'ZAMBOANGA SIBUGAY'},
    {id: 81, description: 'DAVAO OCCIDENTAL'},
    {id: 82, description: 'DINAGAT ISLANDS'},
    {id: 1214, description: 'CAD'},
    {id: 2233, description: 'ISLA PUTING BATO'}
  ],
  cities: [
    {id: 1, description: 'BANGUED', prov_dcode: 1},
    {id: 2, description: 'BOLINEY', prov_dcode: 1},
    {id: 3, description: 'BUCAY', prov_dcode: 1},
    {id: 4, description: 'BUCLOC', prov_dcode: 1},
    {id: 5, description: 'DAGUIOMAN', prov_dcode: 1},
    {id: 6, description: 'DANGLAS', prov_dcode: 1},
    {id: 7, description: 'DOLORES', prov_dcode: 1},
    {id: 8, description: 'LA PAZ', prov_dcode: 1},
    {id: 9, description: 'LACUB', prov_dcode: 1},
    {id: 10, description: 'LAGANGILANG', prov_dcode: 1},
    {id: 11, description: 'LAGAYAN', prov_dcode: 1},
    {id: 12, description: 'LANGIDEN', prov_dcode: 1},
    {id: 13, description: 'LICUAN-BAAY', prov_dcode: 1},
    {id: 14, description: 'LUBA', prov_dcode: 1},
    {id: 15, description: 'MALIBCONG', prov_dcode: 1},
    {id: 16, description: 'MANABO', prov_dcode: 1},
    {id: 17, description: 'PENARRUBIA', prov_dcode: 1},
    {id: 18, description: 'PIDIGAN', prov_dcode: 1},
    {id: 19, description: 'PILAR', prov_dcode: 1},
    {id: 21, description: 'SAN ISIDRO', prov_dcode: 1},
    {id: 22, description: 'SAN JUAN', prov_dcode: 1},
    {id: 23, description: 'SAN QUINTIN', prov_dcode: 1},
    {id: 24, description: 'TAYUM', prov_dcode: 1},
    {id: 25, description: 'TINEG', prov_dcode: 1},
    {id: 26, description: 'TUBO', prov_dcode: 1},
    {id: 27, description: 'VILLAVICIOSA', prov_dcode: 1},
    {id: 28, description: 'BUTUAN CITY', prov_dcode: 2},
    {id: 29, description: 'BUENAVISTA', prov_dcode: 2},
    {id: 30, description: 'CABADBARAN', prov_dcode: 2},
    {id: 31, description: 'CARMEN', prov_dcode: 2},
    {id: 32, description: 'JABONGA', prov_dcode: 2},
    {id: 33, description: 'KITCHARAO', prov_dcode: 2},
    {id: 34, description: 'LAS NIEVES', prov_dcode: 2},
    {id: 35, description: 'MAGALLANES', prov_dcode: 2},
    {id: 36, description: 'NASIPIT', prov_dcode: 2},
    {id: 37, description: 'REMEDIOS T. ROMUALDEZ', prov_dcode: 2},
    {id: 38, description: 'SANTIAGO', prov_dcode: 2},
    {id: 39, description: 'TUBAY', prov_dcode: 2},
    {id: 40, description: 'BAYUGAN', prov_dcode: 3},
    {id: 41, description: 'BUNAWAN', prov_dcode: 3},
    {id: 42, description: 'ESPERANZA', prov_dcode: 3},
    {id: 43, description: 'LA PAZ', prov_dcode: 3},
    {id: 44, description: 'LORETO', prov_dcode: 3},
    {id: 45, description: 'PROSPERIDAD', prov_dcode: 3},
    {id: 46, description: 'ROSARIO', prov_dcode: 3},
    {id: 47, description: 'SAN FRANCISCO', prov_dcode: 3},
    {id: 48, description: 'SAN LUIS', prov_dcode: 3},
    {id: 49, description: 'SANTA JOSEFA', prov_dcode: 3},
    {id: 50, description: 'SIBAGAT', prov_dcode: 3},
    {id: 51, description: 'TALACOGON', prov_dcode: 3},
    {id: 52, description: 'TRENTO', prov_dcode: 3},
    {id: 53, description: 'VERUELA', prov_dcode: 3},
    {id: 54, description: 'ALTAVAS', prov_dcode: 4},
    {id: 55, description: 'BALETE', prov_dcode: 4},
    {id: 56, description: 'BANGA', prov_dcode: 4},
    {id: 57, description: 'BATAN', prov_dcode: 4},
    {id: 58, description: 'BURUANGA', prov_dcode: 4},
    {id: 59, description: 'IBAJAY', prov_dcode: 4},
    {id: 60, description: 'KALIBO', prov_dcode: 4},
    {id: 61, description: 'LEZO', prov_dcode: 4},
    {id: 62, description: 'LIBACAO', prov_dcode: 4},
    {id: 63, description: 'MADALAG', prov_dcode: 4},
    {id: 64, description: 'MAKATO', prov_dcode: 4},
    {id: 65, description: 'MALAY', prov_dcode: 4},
    {id: 66, description: 'MALINAO', prov_dcode: 4},
    {id: 67, description: 'NABAS', prov_dcode: 4},
    {id: 68, description: 'NEW WASHINGTON', prov_dcode: 4},
    {id: 69, description: 'NUMANCIA', prov_dcode: 4},
    {id: 70, description: 'TANGALAN', prov_dcode: 4},
    {id: 71, description: 'LEGAZPI CITY', prov_dcode: 5},
    {id: 72, description: 'LIGAO CITY', prov_dcode: 5},
    {id: 73, description: 'TABACO CITY', prov_dcode: 5},
    {id: 74, description: 'BACACAY', prov_dcode: 5},
    {id: 75, description: 'CAMALIG', prov_dcode: 5},
    {id: 76, description: 'DARAGA', prov_dcode: 5},
    {id: 77, description: 'GUINOBATAN', prov_dcode: 5},
    {id: 78, description: 'JOVELLAR', prov_dcode: 5},
    {id: 79, description: 'LIBON', prov_dcode: 5},
    {id: 80, description: 'MALILIPOT', prov_dcode: 5},
    {id: 81, description: 'MALINAO', prov_dcode: 5},
    {id: 82, description: 'MANITO', prov_dcode: 5},
    {id: 83, description: 'OAS', prov_dcode: 5},
    {id: 84, description: 'PIO DURAN', prov_dcode: 5},
    {id: 85, description: 'POLANGUI', prov_dcode: 5},
    {id: 86, description: 'RAPU-RAPU', prov_dcode: 5},
    {id: 87, description: 'SANTO DOMINGO', prov_dcode: 5},
    {id: 88, description: 'TIWI', prov_dcode: 5},
    {id: 89, description: 'ANINI-Y', prov_dcode: 6},
    {id: 90, description: 'BARBAZA', prov_dcode: 6},
    {id: 91, description: 'BELISON', prov_dcode: 6},
    {id: 92, description: 'BUGASONG', prov_dcode: 6},
    {id: 93, description: 'CALUYA', prov_dcode: 6},
    {id: 94, description: 'CULASI', prov_dcode: 6},
    {id: 95, description: 'HAMTIC', prov_dcode: 6},
    {id: 96, description: 'LAUA-AN', prov_dcode: 6},
    {id: 97, description: 'LIBERTAD', prov_dcode: 6},
    {id: 98, description: 'PANDAN', prov_dcode: 6},
    {id: 99, description: 'PATNONGON', prov_dcode: 6},
    {id: 100, description: 'SAN JOSE', prov_dcode: 6},
    {id: 101, description: 'SAN REMIGIO', prov_dcode: 6},
    {id: 102, description: 'SEBASTE', prov_dcode: 6},
    {id: 103, description: 'SIBALOM', prov_dcode: 6},
    {id: 104, description: 'TIBIAO', prov_dcode: 6},
    {id: 105, description: 'TOBIAS FORNIER', prov_dcode: 6},
    {id: 106, description: 'VALDERRAMA', prov_dcode: 6},
    {id: 107, description: 'CALANASAN', prov_dcode: 7},
    {id: 108, description: 'CONNER', prov_dcode: 7},
    {id: 109, description: 'FLORA', prov_dcode: 7},
    {id: 110, description: 'KABUGAO', prov_dcode: 7},
    {id: 111, description: 'LUNA', prov_dcode: 7},
    {id: 112, description: 'PUDTOL', prov_dcode: 7},
    {id: 113, description: 'SANTA MARCELA', prov_dcode: 7},
    {id: 114, description: 'BALER', prov_dcode: 8},
    {id: 115, description: 'CASIGURAN', prov_dcode: 8},
    {id: 116, description: 'DILASAG', prov_dcode: 8},
    {id: 117, description: 'DINALUNGAN', prov_dcode: 8},
    {id: 118, description: 'DINGALAN', prov_dcode: 8},
    {id: 119, description: 'DIPACULAO', prov_dcode: 8},
    {id: 120, description: 'MARIA AURORA', prov_dcode: 8},
    {id: 121, description: 'SAN LUIS', prov_dcode: 8},
    {id: 122, description: 'ISABELA CITY', prov_dcode: 9},
    {id: 123, description: 'AKBAR', prov_dcode: 9},
    {id: 124, description: 'AL-BARKA', prov_dcode: 9},
    {id: 125, description: 'HADJI MOHAMMAD AJUL', prov_dcode: 9},
    {id: 126, description: 'HADJI MUHTAMAD', prov_dcode: 9},
    {id: 127, description: 'LAMITAN', prov_dcode: 9},
    {id: 128, description: 'LANTAWAN', prov_dcode: 9},
    {id: 129, description: 'MALUSO', prov_dcode: 9},
    {id: 130, description: 'SUMISIP', prov_dcode: 9},
    {id: 131, description: 'TABUAN-LASA', prov_dcode: 9},
    {id: 132, description: 'TIPO-TIPO', prov_dcode: 9},
    {id: 133, description: 'TUBURAN', prov_dcode: 9},
    {id: 134, description: 'UNGKAYA PUKAN', prov_dcode: 9},
    {id: 135, description: 'BALANGA CITY', prov_dcode: 10},
    {id: 136, description: 'ABUCAY', prov_dcode: 10},
    {id: 137, description: 'BAGAC', prov_dcode: 10},
    {id: 138, description: 'DINALUPIHAN', prov_dcode: 10},
    {id: 139, description: 'HERMOSA', prov_dcode: 10},
    {id: 140, description: 'LIMAY', prov_dcode: 10},
    {id: 141, description: 'MARIVELES', prov_dcode: 10},
    {id: 142, description: 'MORONG', prov_dcode: 10},
    {id: 143, description: 'ORANI', prov_dcode: 10},
    {id: 144, description: 'ORION', prov_dcode: 10},
    {id: 145, description: 'PILAR', prov_dcode: 10},
    {id: 146, description: 'SAMAL', prov_dcode: 10},
    {id: 147, description: 'BASCO', prov_dcode: 11},
    {id: 148, description: 'ITBAYAT', prov_dcode: 11},
    {id: 149, description: 'IVANA', prov_dcode: 11},
    {id: 150, description: 'MAHATAO', prov_dcode: 11},
    {id: 151, description: 'SABTANG', prov_dcode: 11},
    {id: 152, description: 'UYUGAN', prov_dcode: 11},
    {id: 153, description: 'BATANGAS CITY', prov_dcode: 12},
    {id: 154, description: 'LIPA CITY', prov_dcode: 12},
    {id: 155, description: 'TANAUAN CITY', prov_dcode: 12},
    {id: 156, description: 'AGONCILLO', prov_dcode: 12},
    {id: 157, description: 'ALITAGTAG', prov_dcode: 12},
    {id: 158, description: 'BALAYAN', prov_dcode: 12},
    {id: 159, description: 'BALETE', prov_dcode: 12},
    {id: 160, description: 'BAUAN', prov_dcode: 12},
    {id: 161, description: 'CALACA', prov_dcode: 12},
    {id: 162, description: 'CALATAGAN', prov_dcode: 12},
    {id: 163, description: 'CUENCA', prov_dcode: 12},
    {id: 164, description: 'IBAAN', prov_dcode: 12},
    {id: 165, description: 'LAUREL', prov_dcode: 12},
    {id: 166, description: 'LEMERY', prov_dcode: 12},
    {id: 167, description: 'LIAN', prov_dcode: 12},
    {id: 168, description: 'LOBO', prov_dcode: 12},
    {id: 169, description: 'MABINI', prov_dcode: 12},
    {id: 170, description: 'MALVAR', prov_dcode: 12},
    {id: 171, description: 'MATAAS NA KAHOY', prov_dcode: 12},
    {id: 172, description: 'NASUGBU', prov_dcode: 12},
    {id: 173, description: 'PADRE GARCIA', prov_dcode: 12},
    {id: 174, description: 'ROSARIO', prov_dcode: 12},
    {id: 175, description: 'SAN JOSE', prov_dcode: 12},
    {id: 176, description: 'SAN JUAN', prov_dcode: 12},
    {id: 177, description: 'SAN LUIS', prov_dcode: 12},
    {id: 178, description: 'SAN NICOLAS', prov_dcode: 12},
    {id: 179, description: 'SAN PASCUAL', prov_dcode: 12},
    {id: 180, description: 'SANTA TERESITA', prov_dcode: 12},
    {id: 181, description: 'SANTO TOMAS', prov_dcode: 12},
    {id: 182, description: 'TAAL', prov_dcode: 12},
    {id: 183, description: 'TALISAY', prov_dcode: 12},
    {id: 184, description: 'TAYSAN', prov_dcode: 12},
    {id: 185, description: 'TINGLOY', prov_dcode: 12},
    {id: 186, description: 'TUY', prov_dcode: 12},
    {id: 187, description: 'BAGUIO CITY', prov_dcode: 13},
    {id: 188, description: 'ATOK', prov_dcode: 13},
    {id: 189, description: 'BAKUN', prov_dcode: 13},
    {id: 190, description: 'BOKOD', prov_dcode: 13},
    {id: 191, description: 'BUGUIAS', prov_dcode: 13},
    {id: 192, description: 'ITOGON', prov_dcode: 13},
    {id: 193, description: 'KABAYAN', prov_dcode: 13},
    {id: 194, description: 'KAPANGAN', prov_dcode: 13},
    {id: 195, description: 'KIBUNGAN', prov_dcode: 13},
    {id: 196, description: 'LA TRINIDAD', prov_dcode: 13},
    {id: 197, description: 'MANKAYAN', prov_dcode: 13},
    {id: 198, description: 'SABLAN', prov_dcode: 13},
    {id: 199, description: 'TUBA', prov_dcode: 13},
    {id: 200, description: 'TUBLAY', prov_dcode: 13},
    {id: 201, description: 'ALMERIA', prov_dcode: 14},
    {id: 202, description: 'BILIRAN', prov_dcode: 14},
    {id: 203, description: 'CABUCGAYAN', prov_dcode: 14},
    {id: 204, description: 'CAIBIRAN', prov_dcode: 14},
    {id: 205, description: 'CULABA', prov_dcode: 14},
    {id: 206, description: 'KAWAYAN', prov_dcode: 14},
    {id: 207, description: 'MARIPIPI', prov_dcode: 14},
    {id: 208, description: 'NAVAL', prov_dcode: 14},
    {id: 209, description: 'TAGBILARAN CITY', prov_dcode: 15},
    {id: 210, description: 'ALBURQUERQUE', prov_dcode: 15},
    {id: 211, description: 'ALICIA', prov_dcode: 15},
    {id: 212, description: 'ANDA', prov_dcode: 15},
    {id: 213, description: 'ANTEQUERA', prov_dcode: 15},
    {id: 214, description: 'BACLAYON', prov_dcode: 15},
    {id: 215, description: 'BALILIHAN', prov_dcode: 15},
    {id: 216, description: 'BATUAN', prov_dcode: 15},
    {id: 217, description: 'BIEN UNIDO', prov_dcode: 15},
    {id: 218, description: 'BILAR', prov_dcode: 15},
    {id: 219, description: 'BUENAVISTA', prov_dcode: 15},
    {id: 220, description: 'CALAPE', prov_dcode: 15},
    {id: 221, description: 'CANDIJAY', prov_dcode: 15},
    {id: 222, description: 'CARMEN', prov_dcode: 15},
    {id: 223, description: 'CATIGBIAN', prov_dcode: 15},
    {id: 224, description: 'CLARIN', prov_dcode: 15},
    {id: 225, description: 'CORELLA', prov_dcode: 15},
    {id: 226, description: 'CORTES', prov_dcode: 15},
    {id: 227, description: 'DAGOHOY', prov_dcode: 15},
    {id: 228, description: 'DANAO', prov_dcode: 15},
    {id: 229, description: 'DAUIS', prov_dcode: 15},
    {id: 230, description: 'DIMIAO', prov_dcode: 15},
    {id: 231, description: 'DUERO', prov_dcode: 15},
    {id: 232, description: 'GARCIA HERNANDEZ', prov_dcode: 15},
    {id: 233, description: 'GETAFE', prov_dcode: 15},
    {id: 234, description: 'GUINDULMAN', prov_dcode: 15},
    {id: 235, description: 'INABANGA', prov_dcode: 15},
    {id: 236, description: 'JAGNA', prov_dcode: 15},
    {id: 237, description: 'LILA', prov_dcode: 15},
    {id: 238, description: 'LOAY', prov_dcode: 15},
    {id: 239, description: 'LOBOC', prov_dcode: 15},
    {id: 240, description: 'LOON', prov_dcode: 15},
    {id: 241, description: 'MABINI', prov_dcode: 15},
    {id: 242, description: 'MARIBOJOC', prov_dcode: 15},
    {id: 243, description: 'PANGLAO', prov_dcode: 15},
    {id: 244, description: 'PILAR', prov_dcode: 15},
    {id: 245, description: 'PRESIDENT CARLOS P. GARCIA', prov_dcode: 15},
    {id: 246, description: 'SAGBAYAN', prov_dcode: 15},
    {id: 247, description: 'SAN ISIDRO', prov_dcode: 15},
    {id: 248, description: 'SAN MIGUEL', prov_dcode: 15},
    {id: 249, description: 'SEVILLA', prov_dcode: 15},
    {id: 250, description: 'SIERRA BULLONES', prov_dcode: 15},
    {id: 251, description: 'SIKATUNA', prov_dcode: 15},
    {id: 252, description: 'TALIBON', prov_dcode: 15},
    {id: 253, description: 'TRINIDAD', prov_dcode: 15},
    {id: 254, description: 'TUBIGON', prov_dcode: 15},
    {id: 255, description: 'UBAY', prov_dcode: 15},
    {id: 256, description: 'VALENCIA', prov_dcode: 15},
    {id: 257, description: 'MALAYBALAY CITY', prov_dcode: 16},
    {id: 258, description: 'VALENCIA CITY', prov_dcode: 16},
    {id: 259, description: 'BAUNGON', prov_dcode: 16},
    {id: 260, description: 'CABANGLASAN', prov_dcode: 16},
    {id: 261, description: 'DAMULOG', prov_dcode: 16},
    {id: 262, description: 'DANGCAGAN', prov_dcode: 16},
    {id: 263, description: 'DON CARLOS', prov_dcode: 16},
    {id: 264, description: 'IMPASUG-ONG', prov_dcode: 16},
    {id: 265, description: 'KADINGILAN', prov_dcode: 16},
    {id: 266, description: 'KALILANGAN', prov_dcode: 16},
    {id: 267, description: 'KIBAWE', prov_dcode: 16},
    {id: 268, description: 'KITAOTAO', prov_dcode: 16},
    {id: 269, description: 'LANTAPAN', prov_dcode: 16},
    {id: 270, description: 'LIBONA', prov_dcode: 16},
    {id: 271, description: 'MALITBOG', prov_dcode: 16},
    {id: 272, description: 'MANOLO FORTICH', prov_dcode: 16},
    {id: 273, description: 'MARAMAG', prov_dcode: 16},
    {id: 274, description: 'PANGANTUCAN', prov_dcode: 16},
    {id: 275, description: 'QUEZON', prov_dcode: 16},
    {id: 276, description: 'SAN FERNANDO', prov_dcode: 16},
    {id: 277, description: 'SUMILAO', prov_dcode: 16},
    {id: 278, description: 'TALAKAG', prov_dcode: 16},
    {id: 279, description: 'MALOLOS CITY', prov_dcode: 17},
    {id: 280, description: 'MEYCAUAYAN CITY', prov_dcode: 17},
    {id: 281, description: 'SAN JOSE DEL MONTE CITY', prov_dcode: 17},
    {id: 282, description: 'ANGAT', prov_dcode: 17},
    {id: 283, description: 'BALAGTAS', prov_dcode: 17},
    {id: 284, description: 'BALIUAG', prov_dcode: 17},
    {id: 285, description: 'BOCAUE', prov_dcode: 17},
    {id: 286, description: 'BULACAN', prov_dcode: 17},
    {id: 287, description: 'BUSTOS', prov_dcode: 17},
    {id: 288, description: 'CALUMPIT', prov_dcode: 17},
    {id: 289, description: 'DONA REMEDIOS TRINIDAD', prov_dcode: 17},
    {id: 290, description: 'GUIGUINTO', prov_dcode: 17},
    {id: 291, description: 'HAGONOY', prov_dcode: 17},
    {id: 292, description: 'MARILAO', prov_dcode: 17},
    {id: 293, description: 'NORZAGARAY', prov_dcode: 17},
    {id: 294, description: 'OBANDO', prov_dcode: 17},
    {id: 295, description: 'PANDI', prov_dcode: 17},
    {id: 296, description: 'PAOMBONG', prov_dcode: 17},
    {id: 297, description: 'PLARIDEL', prov_dcode: 17},
    {id: 298, description: 'PULILAN', prov_dcode: 17},
    {id: 299, description: 'SAN ILDEFONSO', prov_dcode: 17},
    {id: 300, description: 'SAN MIGUEL', prov_dcode: 17},
    {id: 301, description: 'SAN RAFAEL', prov_dcode: 17},
    {id: 302, description: 'SANTA MARIA', prov_dcode: 17},
    {id: 303, description: 'TUGUEGARAO CITY', prov_dcode: 18},
    {id: 304, description: 'ABULUG', prov_dcode: 18},
    {id: 305, description: 'ALCALA', prov_dcode: 18},
    {id: 306, description: 'ALLACAPAN', prov_dcode: 18},
    {id: 307, description: 'AMULUNG', prov_dcode: 18},
    {id: 308, description: 'APARRI', prov_dcode: 18},
    {id: 309, description: 'BAGGAO', prov_dcode: 18},
    {id: 310, description: 'BALLESTEROS', prov_dcode: 18},
    {id: 311, description: 'BUGUEY', prov_dcode: 18},
    {id: 312, description: 'CALAYAN', prov_dcode: 18},
    {id: 313, description: 'CAMALANIUGAN', prov_dcode: 18},
    {id: 314, description: 'CLAVERIA', prov_dcode: 18},
    {id: 315, description: 'ENRILE', prov_dcode: 18},
    {id: 316, description: 'GATTARAN', prov_dcode: 18},
    {id: 317, description: 'GONZAGA', prov_dcode: 18},
    {id: 318, description: 'IGUIG', prov_dcode: 18},
    {id: 319, description: 'LAL-LO', prov_dcode: 18},
    {id: 320, description: 'LASAM', prov_dcode: 18},
    {id: 321, description: 'PAMPLONA', prov_dcode: 18},
    {id: 322, description: 'PENABLANCA', prov_dcode: 18},
    {id: 323, description: 'PIAT', prov_dcode: 18},
    {id: 324, description: 'RIZAL', prov_dcode: 18},
    {id: 325, description: 'SANCHEZ-MIRA', prov_dcode: 18},
    {id: 326, description: 'SANTA ANA', prov_dcode: 18},
    {id: 327, description: 'SANTA PRAXEDES', prov_dcode: 18},
    {id: 328, description: 'SANTA TERESITA', prov_dcode: 18},
    {id: 329, description: 'SANTO NINO', prov_dcode: 18},
    {id: 330, description: 'SOLANA', prov_dcode: 18},
    {id: 331, description: 'TUAO', prov_dcode: 18},
    {id: 332, description: 'BASUD', prov_dcode: 19},
    {id: 333, description: 'CAPALONGA', prov_dcode: 19},
    {id: 334, description: 'DAET', prov_dcode: 19},
    {id: 335, description: 'JOSE PANGANIBAN', prov_dcode: 19},
    {id: 336, description: 'LABO', prov_dcode: 19},
    {id: 337, description: 'MERCEDES', prov_dcode: 19},
    {id: 338, description: 'PARACALE', prov_dcode: 19},
    {id: 339, description: 'SAN LORENZO RUIZ', prov_dcode: 19},
    {id: 340, description: 'SAN VICENTE', prov_dcode: 19},
    {id: 341, description: 'SANTA ELENA', prov_dcode: 19},
    {id: 342, description: 'TALISAY', prov_dcode: 19},
    {id: 343, description: 'VINZONS', prov_dcode: 19},
    {id: 344, description: 'IRIGA CITY', prov_dcode: 20},
    {id: 345, description: 'NAGA CITY', prov_dcode: 20},
    {id: 346, description: 'BAAO', prov_dcode: 20},
    {id: 347, description: 'BALATAN', prov_dcode: 20},
    {id: 348, description: 'BATO', prov_dcode: 20},
    {id: 349, description: 'BOMBON', prov_dcode: 20},
    {id: 350, description: 'BUHI', prov_dcode: 20},
    {id: 351, description: 'BULA', prov_dcode: 20},
    {id: 352, description: 'CABUSAO', prov_dcode: 20},
    {id: 353, description: 'CALABANGA', prov_dcode: 20},
    {id: 354, description: 'CAMALIGAN', prov_dcode: 20},
    {id: 355, description: 'CANAMAN', prov_dcode: 20},
    {id: 356, description: 'CARAMOAN', prov_dcode: 20},
    {id: 357, description: 'DEL GALLEGO', prov_dcode: 20},
    {id: 358, description: 'GAINZA', prov_dcode: 20},
    {id: 359, description: 'GARCHITORENA', prov_dcode: 20},
    {id: 360, description: 'GOA', prov_dcode: 20},
    {id: 361, description: 'LAGONOY', prov_dcode: 20},
    {id: 362, description: 'LIBMANAN', prov_dcode: 20},
    {id: 363, description: 'LUPI', prov_dcode: 20},
    {id: 364, description: 'MAGARAO', prov_dcode: 20},
    {id: 365, description: 'MILAOR', prov_dcode: 20},
    {id: 366, description: 'MINALABAC', prov_dcode: 20},
    {id: 367, description: 'NABUA', prov_dcode: 20},
    {id: 368, description: 'OCAMPO', prov_dcode: 20},
    {id: 369, description: 'PAMPLONA', prov_dcode: 20},
    {id: 370, description: 'PASACAO', prov_dcode: 20},
    {id: 371, description: 'PILI', prov_dcode: 20},
    {id: 372, description: 'PRESENTACION', prov_dcode: 20},
    {id: 373, description: 'RAGAY', prov_dcode: 20},
    {id: 374, description: 'SAGNAY', prov_dcode: 20},
    {id: 375, description: 'SAN FERNANDO', prov_dcode: 20},
    {id: 376, description: 'SAN JOSE', prov_dcode: 20},
    {id: 377, description: 'SIPOCOT', prov_dcode: 20},
    {id: 378, description: 'SIRUMA', prov_dcode: 20},
    {id: 379, description: 'TIGAON', prov_dcode: 20},
    {id: 380, description: 'TINAMBAC', prov_dcode: 20},
    {id: 381, description: 'CATARMAN', prov_dcode: 21},
    {id: 382, description: 'GUINSILIBAN', prov_dcode: 21},
    {id: 383, description: 'MAHINOG', prov_dcode: 21},
    {id: 384, description: 'MAMBAJAO', prov_dcode: 21},
    {id: 385, description: 'SAGAY', prov_dcode: 21},
    {id: 386, description: 'ROXAS CITY', prov_dcode: 22},
    {id: 387, description: 'CUARTERO', prov_dcode: 22},
    {id: 388, description: 'DAO', prov_dcode: 22},
    {id: 389, description: 'DUMALAG', prov_dcode: 22},
    {id: 390, description: 'DUMARAO', prov_dcode: 22},
    {id: 391, description: 'IVISAN', prov_dcode: 22},
    {id: 392, description: 'JAMINDAN', prov_dcode: 22},
    {id: 393, description: 'MA-AYON', prov_dcode: 22},
    {id: 394, description: 'MAMBUSAO', prov_dcode: 22},
    {id: 395, description: 'PANAY', prov_dcode: 22},
    {id: 396, description: 'PANITAN', prov_dcode: 22},
    {id: 397, description: 'PILAR', prov_dcode: 22},
    {id: 398, description: 'PONTEVEDRA', prov_dcode: 22},
    {id: 399, description: 'PRESIDENT ROXAS', prov_dcode: 22},
    {id: 400, description: 'SAPI-AN', prov_dcode: 22},
    {id: 401, description: 'SIGMA', prov_dcode: 22},
    {id: 402, description: 'TAPAZ', prov_dcode: 22},
    {id: 403, description: 'BAGAMANOC', prov_dcode: 23},
    {id: 404, description: 'BARAS', prov_dcode: 23},
    {id: 405, description: 'BATO', prov_dcode: 23},
    {id: 406, description: 'CARAMORAN', prov_dcode: 23},
    {id: 407, description: 'GIGMOTO', prov_dcode: 23},
    {id: 408, description: 'PANDAN', prov_dcode: 23},
    {id: 409, description: 'PANGANIBAN', prov_dcode: 23},
    {id: 410, description: 'SAN ANDRES', prov_dcode: 23},
    {id: 411, description: 'SAN MIGUEL', prov_dcode: 23},
    {id: 412, description: 'VIGA', prov_dcode: 23},
    {id: 413, description: 'VIRAC', prov_dcode: 23},
    {id: 414, description: 'CAVITE CITY', prov_dcode: 24},
    {id: 415, description: 'DASMARINAS CITY', prov_dcode: 24},
    {id: 416, description: 'TAGAYTAY CITY', prov_dcode: 24},
    {id: 417, description: 'TRECE MARTIRES CITY', prov_dcode: 24},
    {id: 418, description: 'ALFONSO', prov_dcode: 24},
    {id: 419, description: 'AMADEO', prov_dcode: 24},
    {id: 420, description: 'BACOOR', prov_dcode: 24},
    {id: 421, description: 'CARMONA', prov_dcode: 24},
    {id: 422, description: 'GENERAL MARIANO ALVAREZ', prov_dcode: 24},
    {id: 423, description: 'GENERAL EMILIO AGUINALDO', prov_dcode: 24},
    {id: 424, description: 'GENERAL TRIAS', prov_dcode: 24},
    {id: 425, description: 'IMUS', prov_dcode: 24},
    {id: 426, description: 'INDANG', prov_dcode: 24},
    {id: 427, description: 'KAWIT', prov_dcode: 24},
    {id: 428, description: 'MAGALLANES', prov_dcode: 24},
    {id: 429, description: 'MARAGONDON', prov_dcode: 24},
    {id: 430, description: 'MENDEZ', prov_dcode: 24},
    {id: 431, description: 'NAIC', prov_dcode: 24},
    {id: 432, description: 'NOVELETA', prov_dcode: 24},
    {id: 433, description: 'ROSARIO', prov_dcode: 24},
    {id: 434, description: 'SILANG', prov_dcode: 24},
    {id: 435, description: 'TANZA', prov_dcode: 24},
    {id: 436, description: 'TERNATE', prov_dcode: 24},
    {id: 437, description: 'BOGO CITY', prov_dcode: 25},
    {id: 438, description: 'CEBU CITY', prov_dcode: 25},
    {id: 439, description: 'CARCAR CITY', prov_dcode: 25},
    {id: 440, description: 'DANAO CITY', prov_dcode: 25},
    {id: 441, description: 'LAPU-LAPU CITY', prov_dcode: 25},
    {id: 442, description: 'MANDAUE CITY', prov_dcode: 25},
    {id: 443, description: 'NAGA CITY', prov_dcode: 25},
    {id: 444, description: 'TALISAY CITY', prov_dcode: 25},
    {id: 445, description: 'TOLEDO CITY', prov_dcode: 25},
    {id: 446, description: 'ALCANTARA', prov_dcode: 25},
    {id: 447, description: 'ALCOY', prov_dcode: 25},
    {id: 448, description: 'ALEGRIA', prov_dcode: 25},
    {id: 449, description: 'ALOGUINSAN', prov_dcode: 25},
    {id: 450, description: 'ARGAO', prov_dcode: 25},
    {id: 451, description: 'ASTURIAS', prov_dcode: 25},
    {id: 452, description: 'BADIAN', prov_dcode: 25},
    {id: 453, description: 'BALAMBAN', prov_dcode: 25},
    {id: 454, description: 'BANTAYAN', prov_dcode: 25},
    {id: 455, description: 'BARILI', prov_dcode: 25},
    {id: 456, description: 'BOLJOON', prov_dcode: 25},
    {id: 457, description: 'BORBON', prov_dcode: 25},
    {id: 458, description: 'CARMEN', prov_dcode: 25},
    {id: 459, description: 'CATMON', prov_dcode: 25},
    {id: 460, description: 'COMPOSTELA', prov_dcode: 25},
    {id: 461, description: 'CONSOLACION', prov_dcode: 25},
    {id: 462, description: 'CORDOBA', prov_dcode: 25},
    {id: 463, description: 'DAANBANTAYAN', prov_dcode: 25},
    {id: 464, description: 'DALAGUETE', prov_dcode: 25},
    {id: 465, description: 'DUMANJUG', prov_dcode: 25},
    {id: 466, description: 'GINATILAN', prov_dcode: 25},
    {id: 467, description: 'LILOAN', prov_dcode: 25},
    {id: 468, description: 'MADRIDEJOS', prov_dcode: 25},
    {id: 469, description: 'MALABUYOC', prov_dcode: 25},
    {id: 470, description: 'MEDELLIN', prov_dcode: 25},
    {id: 471, description: 'MINGLANILLA', prov_dcode: 25},
    {id: 472, description: 'MOALBOAL', prov_dcode: 25},
    {id: 473, description: 'OSLOB', prov_dcode: 25},
    {id: 474, description: 'PILAR', prov_dcode: 25},
    {id: 475, description: 'PINAMUNGAHAN', prov_dcode: 25},
    {id: 476, description: 'PORO', prov_dcode: 25},
    {id: 477, description: 'RONDA', prov_dcode: 25},
    {id: 478, description: 'SAMBOAN', prov_dcode: 25},
    {id: 479, description: 'SAN FERNANDO', prov_dcode: 25},
    {id: 480, description: 'SAN FRANCISCO', prov_dcode: 25},
    {id: 481, description: 'SAN REMIGIO', prov_dcode: 25},
    {id: 482, description: 'SANTA FE', prov_dcode: 25},
    {id: 483, description: 'SANTANDER', prov_dcode: 25},
    {id: 484, description: 'SIBONGA', prov_dcode: 25},
    {id: 485, description: 'SOGOD', prov_dcode: 25},
    {id: 486, description: 'TABOGON', prov_dcode: 25},
    {id: 487, description: 'TABUELAN', prov_dcode: 25},
    {id: 488, description: 'TUBURAN', prov_dcode: 25},
    {id: 489, description: 'TUDELA', prov_dcode: 25},
    {id: 490, description: 'COMPOSTELA', prov_dcode: 26},
    {id: 491, description: 'LAAK', prov_dcode: 26},
    {id: 492, description: 'MABINI', prov_dcode: 26},
    {id: 493, description: 'MACO', prov_dcode: 26},
    {id: 494, description: 'MARAGUSAN', prov_dcode: 26},
    {id: 495, description: 'MAWAB', prov_dcode: 26},
    {id: 496, description: 'MONKAYO', prov_dcode: 26},
    {id: 497, description: 'MONTEVISTA', prov_dcode: 26},
    {id: 498, description: 'NABUNTURAN', prov_dcode: 26},
    {id: 499, description: 'NEW BATAAN', prov_dcode: 26},
    {id: 500, description: 'PANTUKAN', prov_dcode: 26},
    {id: 501, description: 'KIDAPAWAN CITY', prov_dcode: 27},
    {id: 502, description: 'ALAMADA', prov_dcode: 27},
    {id: 503, description: 'ALEOSAN', prov_dcode: 27},
    {id: 504, description: 'ANTIPAS', prov_dcode: 27},
    {id: 505, description: 'ARAKAN', prov_dcode: 27},
    {id: 506, description: 'BANISILAN', prov_dcode: 27},
    {id: 507, description: 'CARMEN', prov_dcode: 27},
    {id: 508, description: 'KABACAN', prov_dcode: 27},
    {id: 509, description: 'LIBUNGAN', prov_dcode: 27},
    {id: 510, description: 'MLANG', prov_dcode: 27},
    {id: 511, description: 'MAGPET', prov_dcode: 27},
    {id: 512, description: 'MAKILALA', prov_dcode: 27},
    {id: 513, description: 'MATALAM', prov_dcode: 27},
    {id: 514, description: 'MIDSAYAP', prov_dcode: 27},
    {id: 515, description: 'PIGKAWAYAN', prov_dcode: 27},
    {id: 516, description: 'PIKIT', prov_dcode: 27},
    {id: 517, description: 'PRESIDENT ROXAS', prov_dcode: 27},
    {id: 518, description: 'TULUNAN', prov_dcode: 27},
    {id: 519, description: 'PANABO CITY', prov_dcode: 28},
    {id: 520, description: 'ISLAND GARDEN CITY OF SAMAL', prov_dcode: 28},
    {id: 521, description: 'TAGUM CITY', prov_dcode: 28},
    {id: 522, description: 'ASUNCION', prov_dcode: 28},
    {id: 523, description: 'BRAULIO E. DUJALI', prov_dcode: 28},
    {id: 524, description: 'CARMEN', prov_dcode: 28},
    {id: 525, description: 'KAPALONG', prov_dcode: 28},
    {id: 526, description: 'NEW CORELLA', prov_dcode: 28},
    {id: 527, description: 'SAN ISIDRO', prov_dcode: 28},
    {id: 528, description: 'SANTO TOMAS', prov_dcode: 28},
    {id: 529, description: 'TALAINGOD', prov_dcode: 28},
    {id: 530, description: 'DAVAO CITY', prov_dcode: 29},
    {id: 531, description: 'DIGOS CITY', prov_dcode: 29},
    {id: 532, description: 'BANSALAN', prov_dcode: 29},
    {id: 533, description: 'DON MARCELINO', prov_dcode: 29},
    {id: 534, description: 'HAGONOY', prov_dcode: 29},
    {id: 535, description: 'JOSE ABAD SANTOS', prov_dcode: 29},
    {id: 536, description: 'KIBLAWAN', prov_dcode: 29},
    {id: 537, description: 'MAGSAYSAY', prov_dcode: 29},
    {id: 538, description: 'MALALAG', prov_dcode: 29},
    {id: 539, description: 'MALITA', prov_dcode: 29},
    {id: 540, description: 'MATANAO', prov_dcode: 29},
    {id: 541, description: 'PADADA', prov_dcode: 29},
    {id: 542, description: 'SANTA CRUZ', prov_dcode: 29},
    {id: 543, description: 'SANTA MARIA', prov_dcode: 29},
    {id: 544, description: 'SARANGANI', prov_dcode: 29},
    {id: 545, description: 'SULOP', prov_dcode: 29},
    {id: 546, description: 'MATI CITY', prov_dcode: 30},
    {id: 547, description: 'BAGANGA', prov_dcode: 30},
    {id: 548, description: 'BANAYBANAY', prov_dcode: 30},
    {id: 549, description: 'BOSTON', prov_dcode: 30},
    {id: 550, description: 'CARAGA', prov_dcode: 30},
    {id: 551, description: 'CATEEL', prov_dcode: 30},
    {id: 552, description: 'GOVERNOR GENEROSO', prov_dcode: 30},
    {id: 553, description: 'LUPON', prov_dcode: 30},
    {id: 554, description: 'MANAY', prov_dcode: 30},
    {id: 555, description: 'SAN ISIDRO', prov_dcode: 30},
    {id: 556, description: 'TARRAGONA', prov_dcode: 30},
    {id: 557, description: 'ARTECHE', prov_dcode: 31},
    {id: 558, description: 'BALANGIGA', prov_dcode: 31},
    {id: 559, description: 'BALANGKAYAN', prov_dcode: 31},
    {id: 560, description: 'BORONGAN', prov_dcode: 31},
    {id: 561, description: 'CAN-AVID', prov_dcode: 31},
    {id: 562, description: 'DOLORES', prov_dcode: 31},
    {id: 563, description: 'GENERAL MACARTHUR', prov_dcode: 31},
    {id: 564, description: 'GIPORLOS', prov_dcode: 31},
    {id: 565, description: 'GUIUAN', prov_dcode: 31},
    {id: 566, description: 'HERNANI', prov_dcode: 31},
    {id: 567, description: 'JIPAPAD', prov_dcode: 31},
    {id: 568, description: 'LAWAAN', prov_dcode: 31},
    {id: 569, description: 'LLORENTE', prov_dcode: 31},
    {id: 570, description: 'MASLOG', prov_dcode: 31},
    {id: 571, description: 'MAYDOLONG', prov_dcode: 31},
    {id: 572, description: 'MERCEDES', prov_dcode: 31},
    {id: 573, description: 'ORAS', prov_dcode: 31},
    {id: 574, description: 'QUINAPONDAN', prov_dcode: 31},
    {id: 575, description: 'SALCEDO', prov_dcode: 31},
    {id: 576, description: 'SAN JULIAN', prov_dcode: 31},
    {id: 577, description: 'SAN POLICARPO', prov_dcode: 31},
    {id: 578, description: 'SULAT', prov_dcode: 31},
    {id: 579, description: 'TAFT', prov_dcode: 31},
    {id: 580, description: 'BUENAVISTA', prov_dcode: 32},
    {id: 581, description: 'JORDAN', prov_dcode: 32},
    {id: 582, description: 'NUEVA VALENCIA', prov_dcode: 32},
    {id: 583, description: 'SAN LORENZO', prov_dcode: 32},
    {id: 584, description: 'SIBUNAG', prov_dcode: 32},
    {id: 585, description: 'AGUINALDO', prov_dcode: 33},
    {id: 586, description: 'ALFONSO LISTA', prov_dcode: 33},
    {id: 587, description: 'ASIPULO', prov_dcode: 33},
    {id: 588, description: 'BANAUE', prov_dcode: 33},
    {id: 589, description: 'HINGYON', prov_dcode: 33},
    {id: 590, description: 'HUNGDUAN', prov_dcode: 33},
    {id: 591, description: 'KIANGAN', prov_dcode: 33},
    {id: 592, description: 'LAGAWE', prov_dcode: 33},
    {id: 593, description: 'LAMUT', prov_dcode: 33},
    {id: 594, description: 'MAYOYAO', prov_dcode: 33},
    {id: 595, description: 'TINOC', prov_dcode: 33},
    {id: 596, description: 'BATAC CITY', prov_dcode: 34},
    {id: 597, description: 'LAOAG CITY', prov_dcode: 34},
    {id: 598, description: 'ADAMS', prov_dcode: 34},
    {id: 599, description: 'BACARRA', prov_dcode: 34},
    {id: 600, description: 'BADOC', prov_dcode: 34},
    {id: 601, description: 'BANGUI', prov_dcode: 34},
    {id: 602, description: 'BANNA', prov_dcode: 34},
    {id: 603, description: 'BURGOS', prov_dcode: 34},
    {id: 604, description: 'CARASI', prov_dcode: 34},
    {id: 605, description: 'CURRIMAO', prov_dcode: 34},
    {id: 606, description: 'DINGRAS', prov_dcode: 34},
    {id: 607, description: 'DUMALNEG', prov_dcode: 34},
    {id: 608, description: 'MARCOS', prov_dcode: 34},
    {id: 609, description: 'NUEVA ERA', prov_dcode: 34},
    {id: 610, description: 'PAGUDPUD', prov_dcode: 34},
    {id: 611, description: 'PAOAY', prov_dcode: 34},
    {id: 612, description: 'PASUQUIN', prov_dcode: 34},
    {id: 613, description: 'PIDDIG', prov_dcode: 34},
    {id: 614, description: 'PINILI', prov_dcode: 34},
    {id: 615, description: 'SAN NICOLAS', prov_dcode: 34},
    {id: 616, description: 'SARRAT', prov_dcode: 34},
    {id: 617, description: 'SOLSONA', prov_dcode: 34},
    {id: 618, description: 'VINTAR', prov_dcode: 34},
    {id: 619, description: 'CANDON CITY', prov_dcode: 35},
    {id: 620, description: 'VIGAN CITY', prov_dcode: 35},
    {id: 621, description: 'ALILEM', prov_dcode: 35},
    {id: 622, description: 'BANAYOYO', prov_dcode: 35},
    {id: 623, description: 'BANTAY', prov_dcode: 35},
    {id: 624, description: 'BURGOS', prov_dcode: 35},
    {id: 625, description: 'CABUGAO', prov_dcode: 35},
    {id: 626, description: 'CAOAYAN', prov_dcode: 35},
    {id: 627, description: 'CERVANTES', prov_dcode: 35},
    {id: 628, description: 'GALIMUYOD', prov_dcode: 35},
    {id: 629, description: 'GREGORIO DEL PILAR', prov_dcode: 35},
    {id: 630, description: 'LIDLIDDA', prov_dcode: 35},
    {id: 631, description: 'MAGSINGAL', prov_dcode: 35},
    {id: 632, description: 'NAGBUKEL', prov_dcode: 35},
    {id: 633, description: 'NARVACAN', prov_dcode: 35},
    {id: 634, description: 'QUIRINO', prov_dcode: 35},
    {id: 635, description: 'SALCEDO', prov_dcode: 35},
    {id: 636, description: 'SAN EMILIO', prov_dcode: 35},
    {id: 637, description: 'SAN ESTEBAN', prov_dcode: 35},
    {id: 638, description: 'SAN ILDEFONSO', prov_dcode: 35},
    {id: 639, description: 'SAN JUAN', prov_dcode: 35},
    {id: 640, description: 'SAN VICENTE', prov_dcode: 35},
    {id: 641, description: 'SANTA', prov_dcode: 35},
    {id: 642, description: 'SANTA CATALINA', prov_dcode: 35},
    {id: 643, description: 'SANTA CRUZ', prov_dcode: 35},
    {id: 644, description: 'SANTA LUCIA', prov_dcode: 35},
    {id: 645, description: 'SANTA MARIA', prov_dcode: 35},
    {id: 646, description: 'SANTIAGO', prov_dcode: 35},
    {id: 647, description: 'SANTO DOMINGO', prov_dcode: 35},
    {id: 648, description: 'SIGAY', prov_dcode: 35},
    {id: 649, description: 'SINAIT', prov_dcode: 35},
    {id: 650, description: 'SUGPON', prov_dcode: 35},
    {id: 651, description: 'SUYO', prov_dcode: 35},
    {id: 652, description: 'TAGUDIN', prov_dcode: 35},
    {id: 653, description: 'ILOILO CITY', prov_dcode: 36},
    {id: 654, description: 'PASSI CITY', prov_dcode: 36},
    {id: 655, description: 'AJUY', prov_dcode: 36},
    {id: 656, description: 'ALIMODIAN', prov_dcode: 36},
    {id: 657, description: 'ANILAO', prov_dcode: 36},
    {id: 658, description: 'BADIANGAN', prov_dcode: 36},
    {id: 659, description: 'BALASAN', prov_dcode: 36},
    {id: 660, description: 'BANATE', prov_dcode: 36},
    {id: 661, description: 'BAROTAC NUEVO', prov_dcode: 36},
    {id: 662, description: 'BAROTAC VIEJO', prov_dcode: 36},
    {id: 663, description: 'BATAD', prov_dcode: 36},
    {id: 664, description: 'BINGAWAN', prov_dcode: 36},
    {id: 665, description: 'CABATUAN', prov_dcode: 36},
    {id: 666, description: 'CALINOG', prov_dcode: 36},
    {id: 667, description: 'CARLES', prov_dcode: 36},
    {id: 668, description: 'CONCEPCION', prov_dcode: 36},
    {id: 669, description: 'DINGLE', prov_dcode: 36},
    {id: 670, description: 'DUENAS', prov_dcode: 36},
    {id: 671, description: 'DUMANGAS', prov_dcode: 36},
    {id: 672, description: 'ESTANCIA', prov_dcode: 36},
    {id: 673, description: 'GUIMBAL', prov_dcode: 36},
    {id: 674, description: 'IGBARAS', prov_dcode: 36},
    {id: 675, description: 'JANIUAY', prov_dcode: 36},
    {id: 676, description: 'LAMBUNAO', prov_dcode: 36},
    {id: 677, description: 'LEGANES', prov_dcode: 36},
    {id: 678, description: 'LEMERY', prov_dcode: 36},
    {id: 679, description: 'LEON', prov_dcode: 36},
    {id: 680, description: 'MAASIN', prov_dcode: 36},
    {id: 681, description: 'MIAGAO', prov_dcode: 36},
    {id: 682, description: 'MINA', prov_dcode: 36},
    {id: 683, description: 'NEW LUCENA', prov_dcode: 36},
    {id: 684, description: 'OTON', prov_dcode: 36},
    {id: 685, description: 'PAVIA', prov_dcode: 36},
    {id: 686, description: 'POTOTAN', prov_dcode: 36},
    {id: 687, description: 'SAN DIONISIO', prov_dcode: 36},
    {id: 688, description: 'SAN ENRIQUE', prov_dcode: 36},
    {id: 689, description: 'SAN JOAQUIN', prov_dcode: 36},
    {id: 690, description: 'SAN MIGUEL', prov_dcode: 36},
    {id: 691, description: 'SAN RAFAEL', prov_dcode: 36},
    {id: 692, description: 'SANTA BARBARA', prov_dcode: 36},
    {id: 693, description: 'SARA', prov_dcode: 36},
    {id: 694, description: 'TIGBAUAN', prov_dcode: 36},
    {id: 695, description: 'TUBUNGAN', prov_dcode: 36},
    {id: 696, description: 'ZARRAGA', prov_dcode: 36},
    {id: 697, description: 'CAUAYAN CITY', prov_dcode: 37},
    {id: 698, description: 'SANTIAGO CITY', prov_dcode: 37},
    {id: 699, description: 'ALICIA', prov_dcode: 37},
    {id: 700, description: 'ANGADANAN', prov_dcode: 37},
    {id: 701, description: 'AURORA', prov_dcode: 37},
    {id: 702, description: 'BENITO SOLIVEN', prov_dcode: 37},
    {id: 703, description: 'BURGOS', prov_dcode: 37},
    {id: 704, description: 'CABAGAN', prov_dcode: 37},
    {id: 705, description: 'CABATUAN', prov_dcode: 37},
    {id: 706, description: 'CORDON', prov_dcode: 37},
    {id: 707, description: 'DELFIN ALBANO', prov_dcode: 37},
    {id: 708, description: 'DINAPIGUE', prov_dcode: 37},
    {id: 709, description: 'DIVILACAN', prov_dcode: 37},
    {id: 710, description: 'ECHAGUE', prov_dcode: 37},
    {id: 711, description: 'GAMU', prov_dcode: 37},
    {id: 712, description: 'ILAGAN', prov_dcode: 37},
    {id: 713, description: 'JONES', prov_dcode: 37},
    {id: 714, description: 'LUNA', prov_dcode: 37},
    {id: 715, description: 'MACONACON', prov_dcode: 37},
    {id: 716, description: 'MALLIG', prov_dcode: 37},
    {id: 717, description: 'NAGUILIAN', prov_dcode: 37},
    {id: 718, description: 'PALANAN', prov_dcode: 37},
    {id: 719, description: 'QUEZON', prov_dcode: 37},
    {id: 720, description: 'QUIRINO', prov_dcode: 37},
    {id: 721, description: 'RAMON', prov_dcode: 37},
    {id: 722, description: 'REINA MERCEDES', prov_dcode: 37},
    {id: 723, description: 'ROXAS', prov_dcode: 37},
    {id: 724, description: 'SAN AGUSTIN', prov_dcode: 37},
    {id: 725, description: 'SAN GUILLERMO', prov_dcode: 37},
    {id: 726, description: 'SAN ISIDRO', prov_dcode: 37},
    {id: 727, description: 'SAN MANUEL', prov_dcode: 37},
    {id: 728, description: 'SAN MARIANO', prov_dcode: 37},
    {id: 729, description: 'SAN MATEO', prov_dcode: 37},
    {id: 730, description: 'SAN PABLO', prov_dcode: 37},
    {id: 731, description: 'SANTA MARIA', prov_dcode: 37},
    {id: 732, description: 'SANTO TOMAS', prov_dcode: 37},
    {id: 733, description: 'TUMAUINI', prov_dcode: 37},
    {id: 734, description: 'TABUK', prov_dcode: 38},
    {id: 735, description: 'BALBALAN', prov_dcode: 38},
    {id: 736, description: 'LUBUAGAN', prov_dcode: 38},
    {id: 737, description: 'PASIL', prov_dcode: 38},
    {id: 738, description: 'PINUKPUK', prov_dcode: 38},
    {id: 739, description: 'RIZAL', prov_dcode: 38},
    {id: 740, description: 'TANUDAN', prov_dcode: 38},
    {id: 741, description: 'TINGLAYAN', prov_dcode: 38},
    {id: 742, description: 'SAN FERNANDO CITY', prov_dcode: 39},
    {id: 743, description: 'AGOO', prov_dcode: 39},
    {id: 744, description: 'ARINGAY', prov_dcode: 39},
    {id: 745, description: 'BACNOTAN', prov_dcode: 39},
    {id: 746, description: 'BAGULIN', prov_dcode: 39},
    {id: 747, description: 'BALAOAN', prov_dcode: 39},
    {id: 748, description: 'BANGAR', prov_dcode: 39},
    {id: 749, description: 'BAUANG', prov_dcode: 39},
    {id: 750, description: 'BURGOS', prov_dcode: 39},
    {id: 751, description: 'CABA', prov_dcode: 39},
    {id: 752, description: 'LUNA', prov_dcode: 39},
    {id: 753, description: 'NAGUILIAN', prov_dcode: 39},
    {id: 754, description: 'PUGO', prov_dcode: 39},
    {id: 755, description: 'ROSARIO', prov_dcode: 39},
    {id: 756, description: 'SAN GABRIEL', prov_dcode: 39},
    {id: 757, description: 'SAN JUAN', prov_dcode: 39},
    {id: 758, description: 'SANTO TOMAS', prov_dcode: 39},
    {id: 759, description: 'SANTOL', prov_dcode: 39},
    {id: 760, description: 'SUDIPEN', prov_dcode: 39},
    {id: 761, description: 'TUBAO', prov_dcode: 39},
    {id: 762, description: 'BINAN CITY', prov_dcode: 40},
    {id: 763, description: 'CALAMBA CITY', prov_dcode: 40},
    {id: 764, description: 'SAN PABLO CITY', prov_dcode: 40},
    {id: 765, description: 'SANTA ROSA CITY', prov_dcode: 40},
    {id: 766, description: 'ALAMINOS', prov_dcode: 40},
    {id: 767, description: 'BAY', prov_dcode: 40},
    {id: 768, description: 'CABUYAO', prov_dcode: 40},
    {id: 769, description: 'CALAUAN', prov_dcode: 40},
    {id: 770, description: 'CAVINTI', prov_dcode: 40},
    {id: 771, description: 'FAMY', prov_dcode: 40},
    {id: 772, description: 'KALAYAAN', prov_dcode: 40},
    {id: 773, description: 'LILIW', prov_dcode: 40},
    {id: 774, description: 'LOS BANOS', prov_dcode: 40},
    {id: 775, description: 'LUISIANA', prov_dcode: 40},
    {id: 776, description: 'LUMBAN', prov_dcode: 40},
    {id: 777, description: 'MABITAC', prov_dcode: 40},
    {id: 778, description: 'MAGDALENA', prov_dcode: 40},
    {id: 779, description: 'MAJAYJAY', prov_dcode: 40},
    {id: 780, description: 'NAGCARLAN', prov_dcode: 40},
    {id: 781, description: 'PAETE', prov_dcode: 40},
    {id: 782, description: 'PAGSANJAN', prov_dcode: 40},
    {id: 783, description: 'PAKIL', prov_dcode: 40},
    {id: 784, description: 'PANGIL', prov_dcode: 40},
    {id: 785, description: 'PILA', prov_dcode: 40},
    {id: 786, description: 'RIZAL', prov_dcode: 40},
    {id: 787, description: 'SAN PEDRO', prov_dcode: 40},
    {id: 788, description: 'SANTA CRUZ', prov_dcode: 40},
    {id: 789, description: 'SANTA MARIA', prov_dcode: 40},
    {id: 790, description: 'SINILOAN', prov_dcode: 40},
    {id: 791, description: 'VICTORIA', prov_dcode: 40},
    {id: 792, description: 'ILIGAN CITY', prov_dcode: 41},
    {id: 793, description: 'BACOLOD', prov_dcode: 41},
    {id: 794, description: 'BALOI', prov_dcode: 41},
    {id: 795, description: 'BAROY', prov_dcode: 41},
    {id: 796, description: 'KAPATAGAN', prov_dcode: 41},
    {id: 797, description: 'KAUSWAGAN', prov_dcode: 41},
    {id: 798, description: 'KOLAMBUGAN', prov_dcode: 41},
    {id: 799, description: 'LALA', prov_dcode: 41},
    {id: 800, description: 'LINAMON', prov_dcode: 41},
    {id: 801, description: 'MAGSAYSAY', prov_dcode: 41},
    {id: 802, description: 'MAIGO', prov_dcode: 41},
    {id: 803, description: 'MATUNGAO', prov_dcode: 41},
    {id: 804, description: 'MUNAI', prov_dcode: 41},
    {id: 805, description: 'NUNUNGAN', prov_dcode: 41},
    {id: 806, description: 'PANTAO RAGAT', prov_dcode: 41},
    {id: 807, description: 'PANTAR', prov_dcode: 41},
    {id: 808, description: 'POONA PIAGAPO', prov_dcode: 41},
    {id: 809, description: 'SALVADOR', prov_dcode: 41},
    {id: 810, description: 'SAPAD', prov_dcode: 41},
    {id: 811, description: 'SULTAN NAGA DIMAPORO', prov_dcode: 41},
    {id: 812, description: 'TAGOLOAN', prov_dcode: 41},
    {id: 813, description: 'TANGCAL', prov_dcode: 41},
    {id: 814, description: 'TUBOD', prov_dcode: 41},
    {id: 815, description: 'MARAWI CITY', prov_dcode: 42},
    {id: 816, description: 'BACOLOD-KALAWI', prov_dcode: 42},
    {id: 817, description: 'BALABAGAN', prov_dcode: 42},
    {id: 818, description: 'BALINDONG', prov_dcode: 42},
    {id: 819, description: 'BAYANG', prov_dcode: 42},
    {id: 820, description: 'BINIDAYAN', prov_dcode: 42},
    {id: 821, description: 'BUADIPOSO-BUNTONG', prov_dcode: 42},
    {id: 822, description: 'BUBONG', prov_dcode: 42},
    {id: 823, description: 'BUMBARAN', prov_dcode: 42},
    {id: 824, description: 'BUTIG', prov_dcode: 42},
    {id: 825, description: 'CALANOGAS', prov_dcode: 42},
    {id: 826, description: 'DITSAAN-RAMAIN', prov_dcode: 42},
    {id: 827, description: 'GANASSI', prov_dcode: 42},
    {id: 828, description: 'KAPAI', prov_dcode: 42},
    {id: 829, description: 'KAPATAGAN', prov_dcode: 42},
    {id: 830, description: 'LUMBA-BAYABAO', prov_dcode: 42},
    {id: 831, description: 'LUMBACA-UNAYAN', prov_dcode: 42},
    {id: 832, description: 'LUMBATAN', prov_dcode: 42},
    {id: 833, description: 'LUMBAYANAGUE', prov_dcode: 42},
    {id: 834, description: 'MADALUM', prov_dcode: 42},
    {id: 835, description: 'MADAMBA', prov_dcode: 42},
    {id: 836, description: 'MAGUING', prov_dcode: 42},
    {id: 837, description: 'MALABANG', prov_dcode: 42},
    {id: 838, description: 'MARANTAO', prov_dcode: 42},
    {id: 839, description: 'MAROGONG', prov_dcode: 42},
    {id: 840, description: 'MASIU', prov_dcode: 42},
    {id: 841, description: 'MULONDO', prov_dcode: 42},
    {id: 842, description: 'PAGAYAWAN', prov_dcode: 42},
    {id: 843, description: 'PIAGAPO', prov_dcode: 42},
    {id: 844, description: 'POONA BAYABAO', prov_dcode: 42},
    {id: 845, description: 'PUALAS', prov_dcode: 42},
    {id: 846, description: 'SAGUIARAN', prov_dcode: 42},
    {id: 847, description: 'SULTAN DUMALONDONG', prov_dcode: 42},
    {id: 848, description: 'PICONG', prov_dcode: 42},
    {id: 849, description: 'TAGOLOAN II', prov_dcode: 42},
    {id: 850, description: 'TAMPARAN', prov_dcode: 42},
    {id: 851, description: 'TARAKA', prov_dcode: 42},
    {id: 852, description: 'TUBARAN', prov_dcode: 42},
    {id: 853, description: 'TUGAYA', prov_dcode: 42},
    {id: 854, description: 'WAO', prov_dcode: 42},
    {id: 855, description: 'ORMOC CITY', prov_dcode: 43},
    {id: 856, description: 'TACLOBAN CITY', prov_dcode: 43},
    {id: 857, description: 'ABUYOG', prov_dcode: 43},
    {id: 858, description: 'ALANGALANG', prov_dcode: 43},
    {id: 859, description: 'ALBUERA', prov_dcode: 43},
    {id: 860, description: 'BABATNGON', prov_dcode: 43},
    {id: 861, description: 'BARUGO', prov_dcode: 43},
    {id: 862, description: 'BATO', prov_dcode: 43},
    {id: 863, description: 'BAYBAY', prov_dcode: 43},
    {id: 864, description: 'BURAUEN', prov_dcode: 43},
    {id: 865, description: 'CALUBIAN', prov_dcode: 43},
    {id: 866, description: 'CAPOOCAN', prov_dcode: 43},
    {id: 867, description: 'CARIGARA', prov_dcode: 43},
    {id: 868, description: 'DAGAMI', prov_dcode: 43},
    {id: 869, description: 'DULAG', prov_dcode: 43},
    {id: 870, description: 'HILONGOS', prov_dcode: 43},
    {id: 871, description: 'HINDANG', prov_dcode: 43},
    {id: 872, description: 'INOPACAN', prov_dcode: 43},
    {id: 873, description: 'ISABEL', prov_dcode: 43},
    {id: 874, description: 'JARO', prov_dcode: 43},
    {id: 875, description: 'JAVIER', prov_dcode: 43},
    {id: 876, description: 'JULITA', prov_dcode: 43},
    {id: 877, description: 'KANANGA', prov_dcode: 43},
    {id: 878, description: 'LA PAZ', prov_dcode: 43},
    {id: 879, description: 'LEYTE', prov_dcode: 43},
    {id: 880, description: 'LILOAN', prov_dcode: 43},
    {id: 881, description: 'MACARTHUR', prov_dcode: 43},
    {id: 882, description: 'MAHAPLAG', prov_dcode: 43},
    {id: 883, description: 'MATAG-OB', prov_dcode: 43},
    {id: 884, description: 'MATALOM', prov_dcode: 43},
    {id: 885, description: 'MAYORGA', prov_dcode: 43},
    {id: 886, description: 'MERIDA', prov_dcode: 43},
    {id: 887, description: 'PALO', prov_dcode: 43},
    {id: 888, description: 'PALOMPON', prov_dcode: 43},
    {id: 889, description: 'PASTRANA', prov_dcode: 43},
    {id: 890, description: 'SAN ISIDRO', prov_dcode: 43},
    {id: 891, description: 'SAN MIGUEL', prov_dcode: 43},
    {id: 892, description: 'SANTA FE', prov_dcode: 43},
    {id: 893, description: 'SOGOD', prov_dcode: 43},
    {id: 894, description: 'TABANGO', prov_dcode: 43},
    {id: 895, description: 'TABONTABON', prov_dcode: 43},
    {id: 896, description: 'TANAUAN', prov_dcode: 43},
    {id: 897, description: 'TOLOSA', prov_dcode: 43},
    {id: 898, description: 'TUNGA', prov_dcode: 43},
    {id: 899, description: 'VILLABA', prov_dcode: 43},
    {id: 900, description: 'COTABATO CITY', prov_dcode: 44},
    {id: 901, description: 'AMPATUAN', prov_dcode: 44},
    {id: 902, description: 'BARIRA', prov_dcode: 44},
    {id: 903, description: 'BULDON', prov_dcode: 44},
    {id: 904, description: 'BULUAN', prov_dcode: 44},
    {id: 905, description: 'DATU ABDULLAH SANGKI', prov_dcode: 44},
    {id: 906, description: 'DATU ANGGAL MIDTIMBANG', prov_dcode: 44},
    {id: 907, description: 'DATU BLAH T. SINSUAT', prov_dcode: 44},
    {id: 908, description: 'DATU HOFFER AMPATUAN', prov_dcode: 44},
    {id: 909, description: 'DATU MONTAWAL', prov_dcode: 44},
    {id: 910, description: 'DATU ODIN SINSUAT', prov_dcode: 44},
    {id: 911, description: 'DATU PAGLAS', prov_dcode: 44},
    {id: 912, description: 'DATU PIANG', prov_dcode: 44},
    {id: 913, description: 'DATU SALIBO', prov_dcode: 44},
    {id: 914, description: 'DATU SAUDI-AMPATUAN', prov_dcode: 44},
    {id: 915, description: 'DATU UNSAY', prov_dcode: 44},
    {id: 916, description: 'GENERAL SALIPADA K. PENDATUN', prov_dcode: 44},
    {id: 917, description: 'GUINDULUNGAN', prov_dcode: 44},
    {id: 918, description: 'KABUNTALAN', prov_dcode: 44},
    {id: 919, description: 'MAMASAPANO', prov_dcode: 44},
    {id: 920, description: 'MANGUDADATU', prov_dcode: 44},
    {id: 921, description: 'MATANOG', prov_dcode: 44},
    {id: 922, description: 'NORTHERN KABUNTALAN', prov_dcode: 44},
    {id: 923, description: 'PAGALUNGAN', prov_dcode: 44},
    {id: 924, description: 'PAGLAT', prov_dcode: 44},
    {id: 925, description: 'PANDAG', prov_dcode: 44},
    {id: 926, description: 'PARANG', prov_dcode: 44},
    {id: 927, description: 'RAJAH BUAYAN', prov_dcode: 44},
    {id: 928, description: 'SHARIFF AGUAK', prov_dcode: 44},
    {id: 929, description: 'SHARIFF SAYDONA MUSTAPHA', prov_dcode: 44},
    {id: 930, description: 'SOUTH UPI', prov_dcode: 44},
    {id: 931, description: 'SULTAN KUDARAT', prov_dcode: 44},
    {id: 932, description: 'SULTAN MASTURA', prov_dcode: 44},
    {id: 933, description: 'SULTAN SA BARONGIS', prov_dcode: 44},
    {id: 934, description: 'TALAYAN', prov_dcode: 44},
    {id: 935, description: 'TALITAY', prov_dcode: 44},
    {id: 936, description: 'UPI', prov_dcode: 44},
    {id: 937, description: 'BOAC', prov_dcode: 45},
    {id: 938, description: 'BUENAVISTA', prov_dcode: 45},
    {id: 939, description: 'GASAN', prov_dcode: 45},
    {id: 940, description: 'MOGPOG', prov_dcode: 45},
    {id: 941, description: 'SANTA CRUZ', prov_dcode: 45},
    {id: 942, description: 'TORRIJOS', prov_dcode: 45},
    {id: 943, description: 'MASBATE CITY', prov_dcode: 46},
    {id: 944, description: 'AROROY', prov_dcode: 46},
    {id: 945, description: 'BALENO', prov_dcode: 46},
    {id: 946, description: 'BALUD', prov_dcode: 46},
    {id: 947, description: 'BATUAN', prov_dcode: 46},
    {id: 948, description: 'CATAINGAN', prov_dcode: 46},
    {id: 949, description: 'CAWAYAN', prov_dcode: 46},
    {id: 950, description: 'CLAVERIA', prov_dcode: 46},
    {id: 951, description: 'DIMASALANG', prov_dcode: 46},
    {id: 952, description: 'ESPERANZA', prov_dcode: 46},
    {id: 953, description: 'MANDAON', prov_dcode: 46},
    {id: 954, description: 'MILAGROS', prov_dcode: 46},
    {id: 955, description: 'MOBO', prov_dcode: 46},
    {id: 956, description: 'MONREAL', prov_dcode: 46},
    {id: 957, description: 'PALANAS', prov_dcode: 46},
    {id: 958, description: 'PIO V. CORPUZ', prov_dcode: 46},
    {id: 959, description: 'PLACER', prov_dcode: 46},
    {id: 960, description: 'SAN FERNANDO', prov_dcode: 46},
    {id: 961, description: 'SAN JACINTO', prov_dcode: 46},
    {id: 962, description: 'SAN PASCUAL', prov_dcode: 46},
    {id: 963, description: 'USON', prov_dcode: 46},
    {id: 964, description: 'CALOOCAN', prov_dcode: 47},
    {id: 965, description: 'LAS PINAS', prov_dcode: 47},
    {id: 966, description: 'MAKATI', prov_dcode: 47},
    {id: 967, description: 'MALABON', prov_dcode: 47},
    {id: 968, description: 'MANDALUYONG', prov_dcode: 47},
    {id: 969, description: 'MANILA', prov_dcode: 47},
    {id: 970, description: 'MARIKINA', prov_dcode: 47},
    {id: 971, description: 'MUNTINLUPA', prov_dcode: 47},
    {id: 972, description: 'NAVOTAS', prov_dcode: 47},
    {id: 973, description: 'PARANAQUE', prov_dcode: 47},
    {id: 974, description: 'PASAY', prov_dcode: 47},
    {id: 975, description: 'PASIG', prov_dcode: 47},
    {id: 976, description: 'QUEZON CITY', prov_dcode: 47},
    {id: 977, description: 'SAN JUAN CITY', prov_dcode: 47},
    {id: 978, description: 'TAGUIG', prov_dcode: 47},
    {id: 979, description: 'VALENZUELA CITY', prov_dcode: 47},
    {id: 980, description: 'PATEROS', prov_dcode: 47},
    {id: 981, description: 'OROQUIETA CITY', prov_dcode: 48},
    {id: 982, description: 'OZAMIZ CITY', prov_dcode: 48},
    {id: 983, description: 'TANGUB CITY', prov_dcode: 48},
    {id: 984, description: 'ALORAN', prov_dcode: 48},
    {id: 985, description: 'BALIANGAO', prov_dcode: 48},
    {id: 986, description: 'BONIFACIO', prov_dcode: 48},
    {id: 987, description: 'CALAMBA', prov_dcode: 48},
    {id: 988, description: 'CLARIN', prov_dcode: 48},
    {id: 989, description: 'CONCEPCION', prov_dcode: 48},
    {id: 990, description: 'DON VICTORIANO CHIONGBIAN', prov_dcode: 48},
    {id: 991, description: 'JIMENEZ', prov_dcode: 48},
    {id: 992, description: 'LOPEZ JAENA', prov_dcode: 48},
    {id: 993, description: 'PANAON', prov_dcode: 48},
    {id: 994, description: 'PLARIDEL', prov_dcode: 48},
    {id: 995, description: 'SAPANG DALAGA', prov_dcode: 48},
    {id: 996, description: 'SINACABAN', prov_dcode: 48},
    {id: 997, description: 'TUDELA', prov_dcode: 48},
    {id: 998, description: 'CAGAYAN DE ORO', prov_dcode: 49},
    {id: 999, description: 'GINGOOG CITY', prov_dcode: 49},
    {id: 1000, description: 'ALUBIJID', prov_dcode: 49},
    {id: 1001, description: 'BALINGASAG', prov_dcode: 49},
    {id: 1002, description: 'BALINGOAN', prov_dcode: 49},
    {id: 1003, description: 'BINUANGAN', prov_dcode: 49},
    {id: 1004, description: 'CLAVERIA', prov_dcode: 49},
    {id: 1005, description: 'EL SALVADOR', prov_dcode: 49},
    {id: 1006, description: 'GITAGUM', prov_dcode: 49},
    {id: 1007, description: 'INITAO', prov_dcode: 49},
    {id: 1008, description: 'JASAAN', prov_dcode: 49},
    {id: 1009, description: 'KINOGUITAN', prov_dcode: 49},
    {id: 1010, description: 'LAGONGLONG', prov_dcode: 49},
    {id: 1011, description: 'LAGUINDINGAN', prov_dcode: 49},
    {id: 1012, description: 'LIBERTAD', prov_dcode: 49},
    {id: 1013, description: 'LUGAIT', prov_dcode: 49},
    {id: 1014, description: 'MAGSAYSAY', prov_dcode: 49},
    {id: 1015, description: 'MANTICAO', prov_dcode: 49},
    {id: 1016, description: 'MEDINA', prov_dcode: 49},
    {id: 1017, description: 'NAAWAN', prov_dcode: 49},
    {id: 1018, description: 'OPOL', prov_dcode: 49},
    {id: 1019, description: 'SALAY', prov_dcode: 49},
    {id: 1020, description: 'SUGBONGCOGON', prov_dcode: 49},
    {id: 1021, description: 'TAGOLOAN', prov_dcode: 49},
    {id: 1022, description: 'TALISAYAN', prov_dcode: 49},
    {id: 1023, description: 'VILLANUEVA', prov_dcode: 49},
    {id: 1024, description: 'BARLIG', prov_dcode: 50},
    {id: 1025, description: 'BAUKO', prov_dcode: 50},
    {id: 1026, description: 'BESAO', prov_dcode: 50},
    {id: 1027, description: 'BONTOC', prov_dcode: 50},
    {id: 1028, description: 'NATONIN', prov_dcode: 50},
    {id: 1029, description: 'PARACELIS', prov_dcode: 50},
    {id: 1030, description: 'SABANGAN', prov_dcode: 50},
    {id: 1031, description: 'SADANGA', prov_dcode: 50},
    {id: 1032, description: 'SAGADA', prov_dcode: 50},
    {id: 1033, description: 'TADIAN', prov_dcode: 50},
    {id: 1034, description: 'BACOLOD CITY', prov_dcode: 51},
    {id: 1035, description: 'BAGO CITY', prov_dcode: 51},
    {id: 1036, description: 'CADIZ CITY', prov_dcode: 51},
    {id: 1037, description: 'ESCALANTE CITY', prov_dcode: 51},
    {id: 1038, description: 'HIMAMAYLAN CITY', prov_dcode: 51},
    {id: 1039, description: 'KABANKALAN CITY', prov_dcode: 51},
    {id: 1040, description: 'LA CARLOTA CITY', prov_dcode: 51},
    {id: 1041, description: 'SAGAY CITY', prov_dcode: 51},
    {id: 1042, description: 'SAN CARLOS CITY', prov_dcode: 51},
    {id: 1043, description: 'SILAY CITY', prov_dcode: 51},
    {id: 1044, description: 'SIPALAY CITY', prov_dcode: 51},
    {id: 1045, description: 'TALISAY CITY', prov_dcode: 51},
    {id: 1046, description: 'VICTORIAS CITY', prov_dcode: 51},
    {id: 1047, description: 'BINALBAGAN', prov_dcode: 51},
    {id: 1048, description: 'CALATRAVA', prov_dcode: 51},
    {id: 1049, description: 'CANDONI', prov_dcode: 51},
    {id: 1050, description: 'CAUAYAN', prov_dcode: 51},
    {id: 1051, description: 'ENRIQUE B. MAGALONA', prov_dcode: 51},
    {id: 1052, description: 'HINIGARAN', prov_dcode: 51},
    {id: 1053, description: 'HINOBA-AN', prov_dcode: 51},
    {id: 1054, description: 'ILOG', prov_dcode: 51},
    {id: 1055, description: 'ISABELA', prov_dcode: 51},
    {id: 1056, description: 'LA CASTELLANA', prov_dcode: 51},
    {id: 1057, description: 'MANAPLA', prov_dcode: 51},
    {id: 1058, description: 'MOISES PADILLA', prov_dcode: 51},
    {id: 1059, description: 'MURCIA', prov_dcode: 51},
    {id: 1060, description: 'PONTEVEDRA', prov_dcode: 51},
    {id: 1061, description: 'PULUPANDAN', prov_dcode: 51},
    {id: 1062, description: 'SALVADOR BENEDICTO', prov_dcode: 51},
    {id: 1063, description: 'SAN ENRIQUE', prov_dcode: 51},
    {id: 1064, description: 'TOBOSO', prov_dcode: 51},
    {id: 1065, description: 'VALLADOLID', prov_dcode: 51},
    {id: 1066, description: 'BAIS CITY', prov_dcode: 52},
    {id: 1067, description: 'BAYAWAN CITY', prov_dcode: 52},
    {id: 1068, description: 'CANLAON CITY', prov_dcode: 52},
    {id: 1069, description: 'GUIHULNGAN CITY', prov_dcode: 52},
    {id: 1070, description: 'DUMAGUETE CITY', prov_dcode: 52},
    {id: 1071, description: 'TANJAY CITY', prov_dcode: 52},
    {id: 1072, description: 'AMLAN', prov_dcode: 52},
    {id: 1073, description: 'AYUNGON', prov_dcode: 52},
    {id: 1074, description: 'BACONG', prov_dcode: 52},
    {id: 1075, description: 'BASAY', prov_dcode: 52},
    {id: 1076, description: 'BINDOY', prov_dcode: 52},
    {id: 1077, description: 'DAUIN', prov_dcode: 52},
    {id: 1078, description: 'JIMALALUD', prov_dcode: 52},
    {id: 1079, description: 'LA LIBERTAD', prov_dcode: 52},
    {id: 1080, description: 'MABINAY', prov_dcode: 52},
    {id: 1081, description: 'MANJUYOD', prov_dcode: 52},
    {id: 1082, description: 'PAMPLONA', prov_dcode: 52},
    {id: 1083, description: 'SAN JOSE', prov_dcode: 52},
    {id: 1084, description: 'SANTA CATALINA', prov_dcode: 52},
    {id: 1085, description: 'SIATON', prov_dcode: 52},
    {id: 1086, description: 'SIBULAN', prov_dcode: 52},
    {id: 1087, description: 'TAYASAN', prov_dcode: 52},
    {id: 1088, description: 'VALENCIA', prov_dcode: 52},
    {id: 1089, description: 'VALLEHERMOSO', prov_dcode: 52},
    {id: 1090, description: 'ZAMBOANGUITA', prov_dcode: 52},
    {id: 1091, description: 'ALLEN', prov_dcode: 53},
    {id: 1092, description: 'BIRI', prov_dcode: 53},
    {id: 1093, description: 'BOBON', prov_dcode: 53},
    {id: 1094, description: 'CAPUL', prov_dcode: 53},
    {id: 1095, description: 'CATARMAN', prov_dcode: 53},
    {id: 1096, description: 'CATUBIG', prov_dcode: 53},
    {id: 1097, description: 'GAMAY', prov_dcode: 53},
    {id: 1098, description: 'LAOANG', prov_dcode: 53},
    {id: 1099, description: 'LAPINIG', prov_dcode: 53},
    {id: 1100, description: 'LAS NAVAS', prov_dcode: 53},
    {id: 1101, description: 'LAVEZARES', prov_dcode: 53},
    {id: 1102, description: 'LOPE DE VEGA', prov_dcode: 53},
    {id: 1103, description: 'MAPANAS', prov_dcode: 53},
    {id: 1104, description: 'MONDRAGON', prov_dcode: 53},
    {id: 1105, description: 'PALAPAG', prov_dcode: 53},
    {id: 1106, description: 'PAMBUJAN', prov_dcode: 53},
    {id: 1107, description: 'ROSARIO', prov_dcode: 53},
    {id: 1108, description: 'SAN ANTONIO', prov_dcode: 53},
    {id: 1109, description: 'SAN ISIDRO', prov_dcode: 53},
    {id: 1110, description: 'SAN JOSE', prov_dcode: 53},
    {id: 1111, description: 'SAN ROQUE', prov_dcode: 53},
    {id: 1112, description: 'SAN VICENTE', prov_dcode: 53},
    {id: 1113, description: 'SILVINO LOBOS', prov_dcode: 53},
    {id: 1114, description: 'VICTORIA', prov_dcode: 53},
    {id: 1115, description: 'CABANATUAN CITY', prov_dcode: 54},
    {id: 1116, description: 'GAPAN CITY', prov_dcode: 54},
    {id: 1117, description: 'SCIENCE CITY OF MUNOZ', prov_dcode: 54},
    {id: 1118, description: 'PALAYAN CITY', prov_dcode: 54},
    {id: 1119, description: 'SAN JOSE CITY', prov_dcode: 54},
    {id: 1120, description: 'ALIAGA', prov_dcode: 54},
    {id: 1121, description: 'BONGABON', prov_dcode: 54},
    {id: 1122, description: 'CABIAO', prov_dcode: 54},
    {id: 1123, description: 'CARRANGLAN', prov_dcode: 54},
    {id: 1124, description: 'CUYAPO', prov_dcode: 54},
    {id: 1125, description: 'GABALDON', prov_dcode: 54},
    {id: 1126, description: 'GENERAL MAMERTO NATIVIDAD', prov_dcode: 54},
    {id: 1127, description: 'GENERAL TINIO', prov_dcode: 54},
    {id: 1128, description: 'GUIMBA', prov_dcode: 54},
    {id: 1129, description: 'JAEN', prov_dcode: 54},
    {id: 1130, description: 'LAUR', prov_dcode: 54},
    {id: 1131, description: 'LICAB', prov_dcode: 54},
    {id: 1132, description: 'LLANERA', prov_dcode: 54},
    {id: 1133, description: 'LUPAO', prov_dcode: 54},
    {id: 1134, description: 'NAMPICUAN', prov_dcode: 54},
    {id: 1135, description: 'PANTABANGAN', prov_dcode: 54},
    {id: 1136, description: 'PENARANDA', prov_dcode: 54},
    {id: 1137, description: 'QUEZON', prov_dcode: 54},
    {id: 1138, description: 'RIZAL', prov_dcode: 54},
    {id: 1139, description: 'SAN ANTONIO', prov_dcode: 54},
    {id: 1140, description: 'SAN ISIDRO', prov_dcode: 54},
    {id: 1141, description: 'SAN LEONARDO', prov_dcode: 54},
    {id: 1142, description: 'SANTA ROSA', prov_dcode: 54},
    {id: 1143, description: 'SANTO DOMINGO', prov_dcode: 54},
    {id: 1144, description: 'TALAVERA', prov_dcode: 54},
    {id: 1145, description: 'TALUGTUG', prov_dcode: 54},
    {id: 1146, description: 'ZARAGOZA', prov_dcode: 54},
    {id: 1147, description: 'ALFONSO CASTANEDA', prov_dcode: 55},
    {id: 1148, description: 'AMBAGUIO', prov_dcode: 55},
    {id: 1149, description: 'ARITAO', prov_dcode: 55},
    {id: 1150, description: 'BAGABAG', prov_dcode: 55},
    {id: 1151, description: 'BAMBANG', prov_dcode: 55},
    {id: 1152, description: 'BAYOMBONG', prov_dcode: 55},
    {id: 1153, description: 'DIADI', prov_dcode: 55},
    {id: 1154, description: 'DUPAX DEL NORTE', prov_dcode: 55},
    {id: 1155, description: 'DUPAX DEL SUR', prov_dcode: 55},
    {id: 1156, description: 'KASIBU', prov_dcode: 55},
    {id: 1157, description: 'KAYAPA', prov_dcode: 55},
    {id: 1158, description: 'QUEZON', prov_dcode: 55},
    {id: 1159, description: 'SANTA FE', prov_dcode: 55},
    {id: 1160, description: 'SOLANO', prov_dcode: 55},
    {id: 1161, description: 'VILLAVERDE', prov_dcode: 55},
    {id: 1162, description: 'ABRA DE ILOG', prov_dcode: 56},
    {id: 1163, description: 'CALINTAAN', prov_dcode: 56},
    {id: 1164, description: 'LOOC', prov_dcode: 56},
    {id: 1165, description: 'LUBANG', prov_dcode: 56},
    {id: 1166, description: 'MAGSAYSAY', prov_dcode: 56},
    {id: 1167, description: 'MAMBURAO', prov_dcode: 56},
    {id: 1168, description: 'PALUAN', prov_dcode: 56},
    {id: 1169, description: 'RIZAL', prov_dcode: 56},
    {id: 1170, description: 'SABLAYAN', prov_dcode: 56},
    {id: 1171, description: 'SAN JOSE', prov_dcode: 56},
    {id: 1172, description: 'SANTA CRUZ', prov_dcode: 56},
    {id: 1173, description: 'CALAPAN CITY', prov_dcode: 57},
    {id: 1174, description: 'BACO', prov_dcode: 57},
    {id: 1175, description: 'BANSUD', prov_dcode: 57},
    {id: 1176, description: 'BONGABONG', prov_dcode: 57},
    {id: 1177, description: 'BULALACAO', prov_dcode: 57},
    {id: 1178, description: 'GLORIA', prov_dcode: 57},
    {id: 1179, description: 'MANSALAY', prov_dcode: 57},
    {id: 1180, description: 'NAUJAN', prov_dcode: 57},
    {id: 1181, description: 'PINAMALAYAN', prov_dcode: 57},
    {id: 1182, description: 'POLA', prov_dcode: 57},
    {id: 1183, description: 'PUERTO GALERA', prov_dcode: 57},
    {id: 1184, description: 'ROXAS', prov_dcode: 57},
    {id: 1185, description: 'SAN TEODORO', prov_dcode: 57},
    {id: 1186, description: 'SOCORRO', prov_dcode: 57},
    {id: 1187, description: 'VICTORIA', prov_dcode: 57},
    {id: 1188, description: 'PUERTO PRINCESA CITY', prov_dcode: 58},
    {id: 1189, description: 'ABORLAN', prov_dcode: 58},
    {id: 1190, description: 'AGUTAYA', prov_dcode: 58},
    {id: 1191, description: 'ARACELI', prov_dcode: 58},
    {id: 1192, description: 'BALABAC', prov_dcode: 58},
    {id: 1193, description: 'BATARAZA', prov_dcode: 58},
    {id: 1194, description: 'BROOKES POINT', prov_dcode: 58},
    {id: 1195, description: 'BUSUANGA', prov_dcode: 58},
    {id: 1196, description: 'CAGAYANCILLO', prov_dcode: 58},
    {id: 1197, description: 'CORON', prov_dcode: 58},
    {id: 1198, description: 'CULION', prov_dcode: 58},
    {id: 1199, description: 'CUYO', prov_dcode: 58},
    {id: 1200, description: 'DUMARAN', prov_dcode: 58},
    {id: 1201, description: 'EL NIDO', prov_dcode: 58},
    {id: 1202, description: 'KALAYAAN', prov_dcode: 58},
    {id: 1203, description: 'LINAPACAN', prov_dcode: 58},
    {id: 1204, description: 'MAGSAYSAY', prov_dcode: 58},
    {id: 1205, description: 'NARRA', prov_dcode: 58},
    {id: 1206, description: 'QUEZON', prov_dcode: 58},
    {id: 1207, description: 'RIZAL', prov_dcode: 58},
    {id: 1208, description: 'ROXAS', prov_dcode: 58},
    {id: 1209, description: 'SAN VICENTE', prov_dcode: 58},
    {id: 1210, description: 'SOFRONIO ESPANOLA', prov_dcode: 58},
    {id: 1211, description: 'TAYTAY', prov_dcode: 58},
    {id: 1212, description: 'ANGELES CITY', prov_dcode: 59},
    {id: 1213, description: 'CITY OF SAN FERNANDO', prov_dcode: 59},
    {id: 1214, description: 'APALIT', prov_dcode: 59},
    {id: 1215, description: 'ARAYAT', prov_dcode: 59},
    {id: 1216, description: 'BACOLOR', prov_dcode: 59},
    {id: 1217, description: 'CANDABA', prov_dcode: 59},
    {id: 1218, description: 'FLORIDABLANCA', prov_dcode: 59},
    {id: 1219, description: 'GUAGUA', prov_dcode: 59},
    {id: 1220, description: 'LUBAO', prov_dcode: 59},
    {id: 1221, description: 'MABALACAT', prov_dcode: 59},
    {id: 1222, description: 'MACABEBE', prov_dcode: 59},
    {id: 1223, description: 'MAGALANG', prov_dcode: 59},
    {id: 1224, description: 'MASANTOL', prov_dcode: 59},
    {id: 1225, description: 'MEXICO', prov_dcode: 59},
    {id: 1226, description: 'MINALIN', prov_dcode: 59},
    {id: 1227, description: 'PORAC', prov_dcode: 59},
    {id: 1228, description: 'SAN LUIS', prov_dcode: 59},
    {id: 1229, description: 'SAN SIMON', prov_dcode: 59},
    {id: 1230, description: 'SANTA ANA', prov_dcode: 59},
    {id: 1231, description: 'SANTA RITA', prov_dcode: 59},
    {id: 1232, description: 'SANTO TOMAS', prov_dcode: 59},
    {id: 1233, description: 'SASMUAN', prov_dcode: 59},
    {id: 1234, description: 'ALAMINOS CITY', prov_dcode: 60},
    {id: 1235, description: 'DAGUPAN CITY', prov_dcode: 60},
    {id: 1236, description: 'SAN CARLOS CITY', prov_dcode: 60},
    {id: 1237, description: 'URDANETA CITY', prov_dcode: 60},
    {id: 1238, description: 'AGNO', prov_dcode: 60},
    {id: 1239, description: 'AGUILAR', prov_dcode: 60},
    {id: 1240, description: 'ALCALA', prov_dcode: 60},
    {id: 1241, description: 'ANDA', prov_dcode: 60},
    {id: 1242, description: 'ASINGAN', prov_dcode: 60},
    {id: 1243, description: 'BALUNGAO', prov_dcode: 60},
    {id: 1244, description: 'BANI', prov_dcode: 60},
    {id: 1245, description: 'BASISTA', prov_dcode: 60},
    {id: 1246, description: 'BAUTISTA', prov_dcode: 60},
    {id: 1247, description: 'BAYAMBANG', prov_dcode: 60},
    {id: 1248, description: 'BINALONAN', prov_dcode: 60},
    {id: 1249, description: 'BINMALEY', prov_dcode: 60},
    {id: 1250, description: 'BOLINAO', prov_dcode: 60},
    {id: 1251, description: 'BUGALLON', prov_dcode: 60},
    {id: 1252, description: 'BURGOS', prov_dcode: 60},
    {id: 1253, description: 'CALASIAO', prov_dcode: 60},
    {id: 1254, description: 'DASOL', prov_dcode: 60},
    {id: 1255, description: 'INFANTA', prov_dcode: 60},
    {id: 1256, description: 'LABRADOR', prov_dcode: 60},
    {id: 1257, description: 'LAOAC', prov_dcode: 60},
    {id: 1258, description: 'LINGAYEN', prov_dcode: 60},
    {id: 1259, description: 'MABINI', prov_dcode: 60},
    {id: 1260, description: 'MALASIQUI', prov_dcode: 60},
    {id: 1261, description: 'MANAOAG', prov_dcode: 60},
    {id: 1262, description: 'MANGALDAN', prov_dcode: 60},
    {id: 1263, description: 'MANGATAREM', prov_dcode: 60},
    {id: 1264, description: 'MAPANDAN', prov_dcode: 60},
    {id: 1265, description: 'NATIVIDAD', prov_dcode: 60},
    {id: 1266, description: 'POZZORUBIO', prov_dcode: 60},
    {id: 1267, description: 'ROSALES', prov_dcode: 60},
    {id: 1268, description: 'SAN FABIAN', prov_dcode: 60},
    {id: 1269, description: 'SAN JACINTO', prov_dcode: 60},
    {id: 1270, description: 'SAN MANUEL', prov_dcode: 60},
    {id: 1271, description: 'SAN NICOLAS', prov_dcode: 60},
    {id: 1272, description: 'SAN QUINTIN', prov_dcode: 60},
    {id: 1273, description: 'SANTA BARBARA', prov_dcode: 60},
    {id: 1274, description: 'SANTA MARIA', prov_dcode: 60},
    {id: 1275, description: 'SANTO TOMAS', prov_dcode: 60},
    {id: 1276, description: 'SISON', prov_dcode: 60},
    {id: 1277, description: 'SUAL', prov_dcode: 60},
    {id: 1278, description: 'TAYUG', prov_dcode: 60},
    {id: 1279, description: 'UMINGAN', prov_dcode: 60},
    {id: 1280, description: 'URBIZTONDO', prov_dcode: 60},
    {id: 1281, description: 'VILLASIS', prov_dcode: 60},
    {id: 1282, description: 'LUCENA CITY', prov_dcode: 61},
    {id: 1283, description: 'TAYABAS CITY', prov_dcode: 61},
    {id: 1284, description: 'AGDANGAN', prov_dcode: 61},
    {id: 1285, description: 'ALABAT', prov_dcode: 61},
    {id: 1286, description: 'ATIMONAN', prov_dcode: 61},
    {id: 1287, description: 'BUENAVISTA', prov_dcode: 61},
    {id: 1288, description: 'BURDEOS', prov_dcode: 61},
    {id: 1289, description: 'CALAUAG', prov_dcode: 61},
    {id: 1290, description: 'CANDELARIA', prov_dcode: 61},
    {id: 1291, description: 'CATANAUAN', prov_dcode: 61},
    {id: 1292, description: 'DOLORES', prov_dcode: 61},
    {id: 1293, description: 'GENERAL LUNA', prov_dcode: 61},
    {id: 1294, description: 'GENERAL NAKAR', prov_dcode: 61},
    {id: 1295, description: 'GUINAYANGAN', prov_dcode: 61},
    {id: 1296, description: 'GUMACA', prov_dcode: 61},
    {id: 1297, description: 'INFANTA', prov_dcode: 61},
    {id: 1298, description: 'JOMALIG', prov_dcode: 61},
    {id: 1299, description: 'LOPEZ', prov_dcode: 61},
    {id: 1300, description: 'LUCBAN', prov_dcode: 61},
    {id: 1301, description: 'MACALELON', prov_dcode: 61},
    {id: 1302, description: 'MAUBAN', prov_dcode: 61},
    {id: 1303, description: 'MULANAY', prov_dcode: 61},
    {id: 1304, description: 'PADRE BURGOS', prov_dcode: 61},
    {id: 1305, description: 'PAGBILAO', prov_dcode: 61},
    {id: 1306, description: 'PANUKULAN', prov_dcode: 61},
    {id: 1307, description: 'PATNANUNGAN', prov_dcode: 61},
    {id: 1308, description: 'PEREZ', prov_dcode: 61},
    {id: 1309, description: 'PITOGO', prov_dcode: 61},
    {id: 1310, description: 'PLARIDEL', prov_dcode: 61},
    {id: 1311, description: 'POLILLO', prov_dcode: 61},
    {id: 1312, description: 'QUEZON', prov_dcode: 61},
    {id: 1313, description: 'REAL', prov_dcode: 61},
    {id: 1314, description: 'SAMPALOC', prov_dcode: 61},
    {id: 1315, description: 'SAN ANDRES', prov_dcode: 61},
    {id: 1316, description: 'SAN ANTONIO', prov_dcode: 61},
    {id: 1317, description: 'SAN FRANCISCO', prov_dcode: 61},
    {id: 1318, description: 'SAN NARCISO', prov_dcode: 61},
    {id: 1319, description: 'SARIAYA', prov_dcode: 61},
    {id: 1320, description: 'TAGKAWAYAN', prov_dcode: 61},
    {id: 1321, description: 'TIAONG', prov_dcode: 61},
    {id: 1322, description: 'UNISAN', prov_dcode: 61},
    {id: 1323, description: 'AGLIPAY', prov_dcode: 62},
    {id: 1324, description: 'CABARROGUIS', prov_dcode: 62},
    {id: 1325, description: 'DIFFUN', prov_dcode: 62},
    {id: 1326, description: 'MADDELA', prov_dcode: 62},
    {id: 1327, description: 'NAGTIPUNAN', prov_dcode: 62},
    {id: 1328, description: 'SAGUDAY', prov_dcode: 62},
    {id: 1329, description: 'ANTIPOLO CITY', prov_dcode: 63},
    {id: 1330, description: 'ANGONO', prov_dcode: 63},
    {id: 1331, description: 'BARAS', prov_dcode: 63},
    {id: 1332, description: 'BINANGONAN', prov_dcode: 63},
    {id: 1333, description: 'CAINTA', prov_dcode: 63},
    {id: 1334, description: 'CARDONA', prov_dcode: 63},
    {id: 1335, description: 'JALAJALA', prov_dcode: 63},
    {id: 1336, description: 'MORONG', prov_dcode: 63},
    {id: 1337, description: 'PILILLA', prov_dcode: 63},
    {id: 1338, description: 'RODRIGUEZ', prov_dcode: 63},
    {id: 1339, description: 'SAN MATEO', prov_dcode: 63},
    {id: 1340, description: 'TANAY', prov_dcode: 63},
    {id: 1341, description: 'TAYTAY', prov_dcode: 63},
    {id: 1342, description: 'TERESA', prov_dcode: 63},
    {id: 1343, description: 'ALCANTARA', prov_dcode: 64},
    {id: 1344, description: 'BANTON', prov_dcode: 64},
    {id: 1345, description: 'CAJIDIOCAN', prov_dcode: 64},
    {id: 1346, description: 'CALATRAVA', prov_dcode: 64},
    {id: 1347, description: 'CONCEPCION', prov_dcode: 64},
    {id: 1348, description: 'CORCUERA', prov_dcode: 64},
    {id: 1349, description: 'FERROL', prov_dcode: 64},
    {id: 1350, description: 'LOOC', prov_dcode: 64},
    {id: 1351, description: 'MAGDIWANG', prov_dcode: 64},
    {id: 1352, description: 'ODIONGAN', prov_dcode: 64},
    {id: 1353, description: 'ROMBLON', prov_dcode: 64},
    {id: 1354, description: 'SAN AGUSTIN', prov_dcode: 64},
    {id: 1355, description: 'SAN ANDRES', prov_dcode: 64},
    {id: 1356, description: 'SAN FERNANDO', prov_dcode: 64},
    {id: 1357, description: 'SAN JOSE', prov_dcode: 64},
    {id: 1358, description: 'SANTA FE', prov_dcode: 64},
    {id: 1359, description: 'SANTA MARIA', prov_dcode: 64},
    {id: 1360, description: 'CALBAYOG CITY', prov_dcode: 65},
    {id: 1361, description: 'CATBALOGAN CITY', prov_dcode: 65},
    {id: 1362, description: 'ALMAGRO', prov_dcode: 65},
    {id: 1363, description: 'BASEY', prov_dcode: 65},
    {id: 1364, description: 'CALBIGA', prov_dcode: 65},
    {id: 1365, description: 'DARAM', prov_dcode: 65},
    {id: 1366, description: 'GANDARA', prov_dcode: 65},
    {id: 1367, description: 'HINABANGAN', prov_dcode: 65},
    {id: 1368, description: 'JIABONG', prov_dcode: 65},
    {id: 1369, description: 'MARABUT', prov_dcode: 65},
    {id: 1370, description: 'MATUGUINAO', prov_dcode: 65},
    {id: 1371, description: 'MOTIONG', prov_dcode: 65},
    {id: 1372, description: 'PAGSANGHAN', prov_dcode: 65},
    {id: 1373, description: 'PARANAS', prov_dcode: 65},
    {id: 1374, description: 'PINABACDAO', prov_dcode: 65},
    {id: 1375, description: 'SAN JORGE', prov_dcode: 65},
    {id: 1376, description: 'SAN JOSE DE BUAN', prov_dcode: 65},
    {id: 1377, description: 'SAN SEBASTIAN', prov_dcode: 65},
    {id: 1378, description: 'SANTA MARGARITA', prov_dcode: 65},
    {id: 1379, description: 'SANTA RITA', prov_dcode: 65},
    {id: 1380, description: 'SANTO NINO', prov_dcode: 65},
    {id: 1381, description: 'TAGAPUL-AN', prov_dcode: 65},
    {id: 1382, description: 'TALALORA', prov_dcode: 65},
    {id: 1383, description: 'TARANGNAN', prov_dcode: 65},
    {id: 1384, description: 'VILLAREAL', prov_dcode: 65},
    {id: 1385, description: 'ZUMARRAGA', prov_dcode: 65},
    {id: 1386, description: 'ALABEL', prov_dcode: 66},
    {id: 1387, description: 'GLAN', prov_dcode: 66},
    {id: 1388, description: 'KIAMBA', prov_dcode: 66},
    {id: 1389, description: 'MAASIM', prov_dcode: 66},
    {id: 1390, description: 'MAITUM', prov_dcode: 66},
    {id: 1391, description: 'MALAPATAN', prov_dcode: 66},
    {id: 1392, description: 'MALUNGON', prov_dcode: 66},
    {id: 1393, description: 'ENRIQUE VILLANUEVA', prov_dcode: 67},
    {id: 1394, description: 'LARENA', prov_dcode: 67},
    {id: 1395, description: 'LAZI', prov_dcode: 67},
    {id: 1396, description: 'MARIA', prov_dcode: 67},
    {id: 1397, description: 'SAN JUAN', prov_dcode: 67},
    {id: 1398, description: 'SIQUIJOR', prov_dcode: 67},
    {id: 1399, description: 'SORSOGON CITY', prov_dcode: 68},
    {id: 1400, description: 'BARCELONA', prov_dcode: 68},
    {id: 1401, description: 'BULAN', prov_dcode: 68},
    {id: 1402, description: 'BULUSAN', prov_dcode: 68},
    {id: 1403, description: 'CASIGURAN', prov_dcode: 68},
    {id: 1404, description: 'CASTILLA', prov_dcode: 68},
    {id: 1405, description: 'DONSOL', prov_dcode: 68},
    {id: 1406, description: 'GUBAT', prov_dcode: 68},
    {id: 1407, description: 'IROSIN', prov_dcode: 68},
    {id: 1408, description: 'JUBAN', prov_dcode: 68},
    {id: 1409, description: 'MAGALLANES', prov_dcode: 68},
    {id: 1410, description: 'MATNOG', prov_dcode: 68},
    {id: 1411, description: 'PILAR', prov_dcode: 68},
    {id: 1412, description: 'PRIETO DIAZ', prov_dcode: 68},
    {id: 1413, description: 'SANTA MAGDALENA', prov_dcode: 68},
    {id: 1414, description: 'GENERAL SANTOS CITY', prov_dcode: 69},
    {id: 1415, description: 'KORONADAL CITY', prov_dcode: 69},
    {id: 1416, description: 'BANGA', prov_dcode: 69},
    {id: 1417, description: 'LAKE SEBU', prov_dcode: 69},
    {id: 1418, description: 'NORALA', prov_dcode: 69},
    {id: 1419, description: 'POLOMOLOK', prov_dcode: 69},
    {id: 1420, description: 'SANTO NINO', prov_dcode: 69},
    {id: 1421, description: 'SURALLAH', prov_dcode: 69},
    {id: 1422, description: 'TBOLI', prov_dcode: 69},
    {id: 1423, description: 'TAMPAKAN', prov_dcode: 69},
    {id: 1424, description: 'TANTANGAN', prov_dcode: 69},
    {id: 1425, description: 'TUPI', prov_dcode: 69},
    {id: 1426, description: 'MAASIN CITY', prov_dcode: 70},
    {id: 1427, description: 'ANAHAWAN', prov_dcode: 70},
    {id: 1428, description: 'BONTOC', prov_dcode: 70},
    {id: 1429, description: 'HINUNANGAN', prov_dcode: 70},
    {id: 1430, description: 'HINUNDAYAN', prov_dcode: 70},
    {id: 1431, description: 'LIBAGON', prov_dcode: 70},
    {id: 1432, description: 'LILOAN', prov_dcode: 70},
    {id: 1433, description: 'LIMASAWA', prov_dcode: 70},
    {id: 1434, description: 'MACROHON', prov_dcode: 70},
    {id: 1435, description: 'MALITBOG', prov_dcode: 70},
    {id: 1436, description: 'PADRE BURGOS', prov_dcode: 70},
    {id: 1437, description: 'PINTUYAN', prov_dcode: 70},
    {id: 1438, description: 'SAINT BERNARD', prov_dcode: 70},
    {id: 1439, description: 'SAN FRANCISCO', prov_dcode: 70},
    {id: 1440, description: 'SAN JUAN', prov_dcode: 70},
    {id: 1441, description: 'SAN RICARDO', prov_dcode: 70},
    {id: 1442, description: 'SILAGO', prov_dcode: 70},
    {id: 1443, description: 'SOGOD', prov_dcode: 70},
    {id: 1444, description: 'TOMAS OPPUS', prov_dcode: 70},
    {id: 1445, description: 'TACURONG CITY', prov_dcode: 71},
    {id: 1446, description: 'BAGUMBAYAN', prov_dcode: 71},
    {id: 1447, description: 'COLUMBIO', prov_dcode: 71},
    {id: 1448, description: 'ESPERANZA', prov_dcode: 71},
    {id: 1449, description: 'ISULAN', prov_dcode: 71},
    {id: 1450, description: 'KALAMANSIG', prov_dcode: 71},
    {id: 1451, description: 'LAMBAYONG', prov_dcode: 71},
    {id: 1452, description: 'LEBAK', prov_dcode: 71},
    {id: 1453, description: 'LUTAYAN', prov_dcode: 71},
    {id: 1454, description: 'PALIMBANG', prov_dcode: 71},
    {id: 1455, description: 'PRESIDENT QUIRINO', prov_dcode: 71},
    {id: 1456, description: 'SENATOR NINOY AQUINO', prov_dcode: 71},
    {id: 1457, description: 'BANGUINGUI', prov_dcode: 72},
    {id: 1458, description: 'HADJI PANGLIMA TAHIL', prov_dcode: 72},
    {id: 1459, description: 'INDANAN', prov_dcode: 72},
    {id: 1460, description: 'JOLO', prov_dcode: 72},
    {id: 1461, description: 'KALINGALAN CALUANG', prov_dcode: 72},
    {id: 1462, description: 'LUGUS', prov_dcode: 72},
    {id: 1463, description: 'LUUK', prov_dcode: 72},
    {id: 1464, description: 'MAIMBUNG', prov_dcode: 72},
    {id: 1465, description: 'OLD PANAMAO', prov_dcode: 72},
    {id: 1466, description: 'OMAR', prov_dcode: 72},
    {id: 1467, description: 'PANDAMI', prov_dcode: 72},
    {id: 1468, description: 'PANGLIMA ESTINO', prov_dcode: 72},
    {id: 1469, description: 'PANGUTARAN', prov_dcode: 72},
    {id: 1470, description: 'PARANG', prov_dcode: 72},
    {id: 1471, description: 'PATA', prov_dcode: 72},
    {id: 1472, description: 'PATIKUL', prov_dcode: 72},
    {id: 1473, description: 'SIASI', prov_dcode: 72},
    {id: 1474, description: 'TALIPAO', prov_dcode: 72},
    {id: 1475, description: 'TAPUL', prov_dcode: 72},
    {id: 1476, description: 'SURIGAO CITY', prov_dcode: 73},
    {id: 1477, description: 'ALEGRIA', prov_dcode: 73},
    {id: 1478, description: 'BACUAG', prov_dcode: 73},
    {id: 1479, description: 'BASILISA', prov_dcode: 73},
    {id: 1480, description: 'BURGOS', prov_dcode: 73},
    {id: 1481, description: 'CAGDIANAO', prov_dcode: 73},
    {id: 1482, description: 'CLAVER', prov_dcode: 73},
    {id: 1483, description: 'DAPA', prov_dcode: 73},
    {id: 1484, description: 'DEL CARMEN', prov_dcode: 73},
    {id: 1485, description: 'DINAGAT', prov_dcode: 73},
    {id: 1486, description: 'GENERAL LUNA', prov_dcode: 73},
    {id: 1487, description: 'GIGAQUIT', prov_dcode: 73},
    {id: 1488, description: 'LIBJO', prov_dcode: 73},
    {id: 1489, description: 'LORETO', prov_dcode: 73},
    {id: 1490, description: 'MAINIT', prov_dcode: 73},
    {id: 1491, description: 'MALIMONO', prov_dcode: 73},
    {id: 1492, description: 'PILAR', prov_dcode: 73},
    {id: 1493, description: 'PLACER', prov_dcode: 73},
    {id: 1494, description: 'SAN BENITO', prov_dcode: 73},
    {id: 1495, description: 'SAN FRANCISCO', prov_dcode: 73},
    {id: 1496, description: 'SAN ISIDRO', prov_dcode: 73},
    {id: 1497, description: 'SAN JOSE', prov_dcode: 73},
    {id: 1498, description: 'SANTA MONICA', prov_dcode: 73},
    {id: 1499, description: 'SISON', prov_dcode: 73},
    {id: 1500, description: 'SOCORRO', prov_dcode: 73},
    {id: 1501, description: 'TAGANA-AN', prov_dcode: 73},
    {id: 1502, description: 'TUBAJON', prov_dcode: 73},
    {id: 1503, description: 'TUBOD', prov_dcode: 73},
    {id: 1504, description: 'BISLIG CITY', prov_dcode: 74},
    {id: 1505, description: 'TANDAG CITY', prov_dcode: 74},
    {id: 1506, description: 'BAROBO', prov_dcode: 74},
    {id: 1507, description: 'BAYABAS', prov_dcode: 74},
    {id: 1508, description: 'CAGWAIT', prov_dcode: 74},
    {id: 1509, description: 'CANTILAN', prov_dcode: 74},
    {id: 1510, description: 'CARMEN', prov_dcode: 74},
    {id: 1511, description: 'CARRASCAL', prov_dcode: 74},
    {id: 1512, description: 'CORTES', prov_dcode: 74},
    {id: 1513, description: 'HINATUAN', prov_dcode: 74},
    {id: 1514, description: 'LANUZA', prov_dcode: 74},
    {id: 1515, description: 'LIANGA', prov_dcode: 74},
    {id: 1516, description: 'LINGIG', prov_dcode: 74},
    {id: 1517, description: 'MADRID', prov_dcode: 74},
    {id: 1518, description: 'MARIHATAG', prov_dcode: 74},
    {id: 1519, description: 'SAN AGUSTIN', prov_dcode: 74},
    {id: 1520, description: 'SAN MIGUEL', prov_dcode: 74},
    {id: 1521, description: 'TAGBINA', prov_dcode: 74},
    {id: 1522, description: 'TAGO', prov_dcode: 74},
    {id: 1523, description: 'TARLAC CITY', prov_dcode: 75},
    {id: 1524, description: 'ANAO', prov_dcode: 75},
    {id: 1525, description: 'BAMBAN', prov_dcode: 75},
    {id: 1526, description: 'CAMILING', prov_dcode: 75},
    {id: 1527, description: 'CAPAS', prov_dcode: 75},
    {id: 1528, description: 'CONCEPCION', prov_dcode: 75},
    {id: 1529, description: 'GERONA', prov_dcode: 75},
    {id: 1530, description: 'LA PAZ', prov_dcode: 75},
    {id: 1531, description: 'MAYANTOC', prov_dcode: 75},
    {id: 1532, description: 'MONCADA', prov_dcode: 75},
    {id: 1533, description: 'PANIQUI', prov_dcode: 75},
    {id: 1534, description: 'PURA', prov_dcode: 75},
    {id: 1535, description: 'RAMOS', prov_dcode: 75},
    {id: 1536, description: 'SAN CLEMENTE', prov_dcode: 75},
    {id: 1537, description: 'SAN JOSE', prov_dcode: 75},
    {id: 1538, description: 'SAN MANUEL', prov_dcode: 75},
    {id: 1539, description: 'SANTA IGNACIA', prov_dcode: 75},
    {id: 1540, description: 'VICTORIA', prov_dcode: 75},
    {id: 1541, description: 'BONGAO', prov_dcode: 76},
    {id: 1542, description: 'LANGUYAN', prov_dcode: 76},
    {id: 1543, description: 'MAPUN', prov_dcode: 76},
    {id: 1544, description: 'PANGLIMA SUGALA', prov_dcode: 76},
    {id: 1545, description: 'SAPA-SAPA', prov_dcode: 76},
    {id: 1546, description: 'SIBUTU', prov_dcode: 76},
    {id: 1547, description: 'SIMUNUL', prov_dcode: 76},
    {id: 1548, description: 'SITANGKAI', prov_dcode: 76},
    {id: 1549, description: 'SOUTH UBIAN', prov_dcode: 76},
    {id: 1550, description: 'TANDUBAS', prov_dcode: 76},
    {id: 1551, description: 'TURTLE ISLANDS', prov_dcode: 76},
    {id: 1552, description: 'OLONGAPO CITY', prov_dcode: 77},
    {id: 1553, description: 'BOTOLAN', prov_dcode: 77},
    {id: 1554, description: 'CABANGAN', prov_dcode: 77},
    {id: 1555, description: 'CANDELARIA', prov_dcode: 77},
    {id: 1556, description: 'CASTILLEJOS', prov_dcode: 77},
    {id: 1557, description: 'IBA', prov_dcode: 77},
    {id: 1558, description: 'MASINLOC', prov_dcode: 77},
    {id: 1559, description: 'PALAUIG', prov_dcode: 77},
    {id: 1560, description: 'SAN ANTONIO', prov_dcode: 77},
    {id: 1561, description: 'SAN FELIPE', prov_dcode: 77},
    {id: 1562, description: 'SAN MARCELINO', prov_dcode: 77},
    {id: 1563, description: 'SAN NARCISO', prov_dcode: 77},
    {id: 1564, description: 'SANTA CRUZ', prov_dcode: 77},
    {id: 1565, description: 'SUBIC', prov_dcode: 77},
    {id: 1566, description: 'DAPITAN CITY', prov_dcode: 78},
    {id: 1567, description: 'DIPOLOG CITY', prov_dcode: 78},
    {id: 1568, description: 'BACUNGAN', prov_dcode: 78},
    {id: 1569, description: 'BALIGUIAN', prov_dcode: 78},
    {id: 1570, description: 'GODOD', prov_dcode: 78},
    {id: 1571, description: 'GUTALAC', prov_dcode: 78},
    {id: 1572, description: 'JOSE DALMAN', prov_dcode: 78},
    {id: 1573, description: 'KALAWIT', prov_dcode: 78},
    {id: 1574, description: 'KATIPUNAN', prov_dcode: 78},
    {id: 1575, description: 'LA LIBERTAD', prov_dcode: 78},
    {id: 1576, description: 'LABASON', prov_dcode: 78},
    {id: 1577, description: 'LILOY', prov_dcode: 78},
    {id: 1578, description: 'MANUKAN', prov_dcode: 78},
    {id: 1579, description: 'MUTIA', prov_dcode: 78},
    {id: 1580, description: 'PINAN', prov_dcode: 78},
    {id: 1581, description: 'POLANCO', prov_dcode: 78},
    {id: 1582, description: 'PRESIDENT MANUEL A. ROXAS', prov_dcode: 78},
    {id: 1583, description: 'RIZAL', prov_dcode: 78},
    {id: 1584, description: 'SALUG', prov_dcode: 78},
    {id: 1585, description: 'SERGIO OSMENA SR.', prov_dcode: 78},
    {id: 1586, description: 'SIAYAN', prov_dcode: 78},
    {id: 1587, description: 'SIBUCO', prov_dcode: 78},
    {id: 1588, description: 'SIBUTAD', prov_dcode: 78},
    {id: 1589, description: 'SINDANGAN', prov_dcode: 78},
    {id: 1590, description: 'SIOCON', prov_dcode: 78},
    {id: 1591, description: 'SIRAWAI', prov_dcode: 78},
    {id: 1592, description: 'TAMPILISAN', prov_dcode: 78},
    {id: 1593, description: 'PAGADIAN CITY', prov_dcode: 79},
    {id: 1594, description: 'ZAMBOANGA CITY', prov_dcode: 79},
    {id: 1595, description: 'AURORA', prov_dcode: 79},
    {id: 1596, description: 'BAYOG', prov_dcode: 79},
    {id: 1597, description: 'DIMATALING', prov_dcode: 79},
    {id: 1598, description: 'DINAS', prov_dcode: 79},
    {id: 1599, description: 'DUMALINAO', prov_dcode: 79},
    {id: 1600, description: 'DUMINGAG', prov_dcode: 79},
    {id: 1601, description: 'GUIPOS', prov_dcode: 79},
    {id: 1602, description: 'JOSEFINA', prov_dcode: 79},
    {id: 1603, description: 'KUMALARANG', prov_dcode: 79},
    {id: 1604, description: 'LABANGAN', prov_dcode: 79},
    {id: 1605, description: 'LAKEWOOD', prov_dcode: 79},
    {id: 1606, description: 'LAPUYAN', prov_dcode: 79},
    {id: 1607, description: 'MAHAYAG', prov_dcode: 79},
    {id: 1608, description: 'MARGOSATUBIG', prov_dcode: 79},
    {id: 1609, description: 'MIDSALIP', prov_dcode: 79},
    {id: 1610, description: 'MOLAVE', prov_dcode: 79},
    {id: 1611, description: 'PITOGO', prov_dcode: 79},
    {id: 1612, description: 'RAMON MAGSAYSAY', prov_dcode: 79},
    {id: 1613, description: 'SAN MIGUEL', prov_dcode: 79},
    {id: 1614, description: 'SAN PABLO', prov_dcode: 79},
    {id: 1615, description: 'SOMINOT', prov_dcode: 79},
    {id: 1616, description: 'TABINA', prov_dcode: 79},
    {id: 1617, description: 'TAMBULIG', prov_dcode: 79},
    {id: 1618, description: 'TIGBAO', prov_dcode: 79},
    {id: 1619, description: 'TUKURAN', prov_dcode: 79},
    {id: 1620, description: 'VINCENZO A. SAGUN', prov_dcode: 79},
    {id: 1621, description: 'ALICIA', prov_dcode: 80},
    {id: 1622, description: 'BUUG', prov_dcode: 80},
    {id: 1623, description: 'DIPLAHAN', prov_dcode: 80},
    {id: 1624, description: 'IMELDA', prov_dcode: 80},
    {id: 1625, description: 'IPIL', prov_dcode: 80},
    {id: 1626, description: 'KABASALAN', prov_dcode: 80},
    {id: 1627, description: 'MABUHAY', prov_dcode: 80},
    {id: 1628, description: 'MALANGAS', prov_dcode: 80},
    {id: 1629, description: 'NAGA', prov_dcode: 80},
    {id: 1630, description: 'OLUTANGA', prov_dcode: 80},
    {id: 1631, description: 'PAYAO', prov_dcode: 80},
    {id: 1632, description: 'ROSELLER LIM', prov_dcode: 80},
    {id: 1633, description: 'SIAY', prov_dcode: 80},
    {id: 1634, description: 'TALUSAN', prov_dcode: 80},
    {id: 1635, description: 'TITAY', prov_dcode: 80},
    {id: 1636, description: 'TUNGAWAN', prov_dcode: 80},
    {id: 2233, description: 'BANABA', prov_dcode: 10},
    {id: 3321, description: 'AGONCILLIO', prov_dcode: 12},
  ]
};

import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'requestTime' })
export class RequestTimePipe implements PipeTransform {
  transform(requestTime: string) {
    switch (requestTime) {
      case 'afternoon':
        return '5:00PM - 7:30PM';
      case 'morning':
      default:
        return '6:00AM - 8:30AM';
    }
  }
}

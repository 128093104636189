import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

export interface GoolgeLocation {
  lat: any;
  lng: any;
}

export interface GooglePlacesGeometry {
  location: GoolgeLocation;
}

export interface GooglePlace {
  formatted_address: string;
  place_id: string;
  geometry: GooglePlacesGeometry;
}

@Component({
  selector: 'app-google-places',
  templateUrl: './google-places.component.html',
  styleUrls: ['./google-places.component.css']
})
export class GooglePlacesComponent implements OnInit {

  @Input() initialValue: string;
  @Output() OnAddressChange = new EventEmitter<GooglePlace>();

  options = {
    componentRestrictions: { country: ['PH'] }
  };

  constructor() {}

  ngOnInit() {
    if (this.initialValue === undefined) {
      this.initialValue = '';
    }

  }

  handleAddressChange(address: GooglePlace) {
    this.OnAddressChange.emit(address);
  }

}

import { NgModule } from "@angular/core";
import { HomeComponent } from "./home.component";
import { CategoriesSectionComponent } from "./categories-section/categories-section.component";
import { HowToRentPanelComponent } from "./how-to-rent-panel/how-to-rent-panel.component";
import { MostPopularComponent } from './most-popular/most-popular.component';
import { DailyDiscoverComponent } from './daily-discover/daily-discover.component';
import { SharedModule } from 'projects/shared/shared.module';

@NgModule({
  declarations: [
    HomeComponent,
    CategoriesSectionComponent,
    HowToRentPanelComponent,
    MostPopularComponent,
    DailyDiscoverComponent
  ],
  imports: [SharedModule],
  exports: [HomeComponent, CategoriesSectionComponent]
})
export class HomeModule {}

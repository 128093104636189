import { Injectable } from '@angular/core';
import { environment, UserRatingRecord } from 'projects/lendi-business/src/public-api';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';

@Injectable({ providedIn: 'root' })
export class UserRatingService {

  constructor(private $db: AngularFirestore) {}

  get refAsLender() {
    return this.$db.collection(environment.collections.userRatingsAsLender);
  }

  get refAsBorrower() {
    return this.$db.collection(environment.collections.userRatingsAsBorrower);
  }

  getUserRatingCountsAsLender(userId: string) {
    return this.refAsLender.doc(userId).get();
  }

  getUserRatingCountsAsBorrower(userId: string) {
    return this.refAsBorrower.doc(userId).get();
  }

  getUserReviewsAsLender(userId: string, limit = 4, lastDoc?: any) {
    if (lastDoc) {
      return this.refAsLender.doc(userId).collection(environment.collections.ratings,
        ref => ref
        .orderBy('createdAt', 'desc')
        .limit(limit)
        .startAfter(lastDoc)
      ).get();
    } else {
      return this.refAsLender.doc(userId).collection(environment.collections.ratings,
        ref => ref
        .orderBy('createdAt', 'desc')
        .limit(limit)
      ).get();
    }
  }

  getUserReviewsAsBorrower(userId: string, limit = 4, lastDoc?: any) {
    if (lastDoc) {
      return this.refAsBorrower.doc(userId).collection(environment.collections.ratings,
        ref => ref
        .orderBy('createdAt', 'desc')
        .limit(limit)
        .startAfter(lastDoc)
      ).get();
    } else {
      return this.refAsBorrower.doc(userId).collection(environment.collections.ratings,
        ref => ref
        .orderBy('createdAt', 'desc')
        .limit(limit)
      ).get();
    }
  }

  getUserReviewAsBorrowerByBorrowRequestId(userId: string, borrowerRequestId: string) {
    return this.$db.collection(environment.collections.userRatingsAsBorrower)
    .doc(userId)
    .collection(environment.collections.ratings,
      ref => ref.where('borrowRequestId', '==', borrowerRequestId).limit(1)).get();
  }

  getUserReviewAsLenderByBorrowRequestId(userId: string, borrowerRequestId: string) {
    return this.$db.collection(environment.collections.userRatingsAsLender)
    .doc(userId)
    .collection(environment.collections.ratings,
      ref => ref.where('borrowRequestId', '==', borrowerRequestId).limit(1)).get();
  }

  saveUserRatingAsBorrower(userRating: UserRatingRecord) {
    const processSaveUserRatingAsBorrowerAPI = firebase.functions().httpsCallable('processSaveUserRatingAsBorrower');
    return processSaveUserRatingAsBorrowerAPI({userRating});
  }

  saveUserRatingAsLender(userRating: UserRatingRecord) {
    const processSaveUserRatingAsLenderAPI = firebase.functions().httpsCallable('processSaveUserRatingAsLender');
    return processSaveUserRatingAsLenderAPI({userRating});
  }

}

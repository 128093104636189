import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { first } from 'rxjs/operators';
import { LendiUserService } from '../services/lendi-user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private afAuthService: AngularFireAuth,
    private $user: LendiUserService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      // if (this.$user.currentUser.value || this.afAuthService.auth.currentUser) {
      //   return true;
      // } else {
      //   this.router.navigate(['/auth/login']);
      //   return false;
      // }
      return this.afAuthService.authState.pipe(first()).toPromise().then(res => {
        if (res) {
          return true;
        } else {
          this.router.navigate(['/auth/login']);
          return false;
        }
      }).catch(error => {
        this.router.navigate(['/auth/login']);
        return false;
      });

  }

}

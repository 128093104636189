import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SpinnerService } from 'projects/shared/services/spinner.service';
import { debounceTime } from 'rxjs/operators';
import { setErrorMessage } from 'projects/shared/validators/validation-messages';
import { AngularFireAuth } from '@angular/fire/auth';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.css']
})
export class ForgotPasswordFormComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  formErrors: { [key: string]: string };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private spinner: SpinnerService,
    private afAuth: AngularFireAuth) {}

  get email() {
    return this.forgotPasswordForm.get('email');
  }

  ngOnInit() {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });

    this.formErrors = {
      email: ''
    };

    this.email.valueChanges.pipe(debounceTime(1000)).subscribe(value => {
      this.formErrors.email = setErrorMessage(this.email, 'email');
    });
  }

  sendEmail() {
    if (this.forgotPasswordForm.valid) {
      this.spinner.show();
      this.afAuth.auth.sendPasswordResetEmail(this.email.value)
      .then(res => {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Email successfully sent.',
          showConfirmButton: false,
          timer: 2000
        }).then(() => {
          this.router.navigate(['auth/login']);
        });
      }).catch(err => {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Account does not exists.',
          showConfirmButton: false,
          timer: 1500
        });
      }).finally(() => {
        this.spinner.hide();
      });
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Please fill up all the required fields.',
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
}

import { Injectable } from '@angular/core';
import * as firebase from 'firebase';

@Injectable({ providedIn: 'root' })
export class LoggerService {

  constructor() {}

  initLogs() {
    firebase.analytics();
    firebase.analytics().logEvent('app_start', {});
  }

  logLogin(email: string) {
    firebase.analytics().logEvent('login', {email});
  }

  logSearch(search: string) {
    firebase.analytics().logEvent('search', {search});
  }

  logSelectContent(borrowRequestId: string, productId: string, categoryId: string) {
    firebase.analytics().logEvent('select_content', {borrowRequestId, productId, categoryId});
  }

  logShare(productId: string, categoryId: string) {
    firebase.analytics().logEvent('share', {productId, categoryId});
  }

  logSignUp(email: string) {
    firebase.analytics().logEvent('sign_up', {email});
  }

  logSpendVirtualCurrency(name: string, amount: number) {
    firebase.analytics().logEvent('spend_virtual_currency', {name, amount});
  }

  logBeginCheckout(borrowRequestId: string, productId: string, categoryId: string, amount: number) {
    firebase.analytics().logEvent('add_payment_info', {borrowRequestId, productId, categoryId, amount});
  }

  logAddPaymentInfo(cardType: string, borrowRequestId: string, productId: string, categoryId: string, amount: number) {
    firebase.analytics().logEvent('add_payment_info', {cardType, borrowRequestId, productId, categoryId, amount});
  }

  logPurchase(transactionId: string, borrowRequestId: string, productId: string, categoryId: string, amount: number) {
    firebase.analytics().logEvent('purchase', {transaction_id: transactionId, borrowRequestId, productId, categoryId, amount});
  }

  logViewItem(productId: string, categoryId: string) {
    firebase.analytics().logEvent('view_item', {productId, categoryId});
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { LendiProductRecord, LendiUserRecord, LendiUserProfileAddress } from 'projects/lendi-business/src/public-api';
import { LendiUserService } from 'projects/core/services/lendi-user.service';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.css']
})
export class ProductCardComponent implements OnInit {

  @Input() product: LendiProductRecord;
  user: LendiUserRecord;
  userAddress: LendiUserProfileAddress;
  userProfilePhoto: any;

  constructor(private lendiUserService: LendiUserService) { }

  ngOnInit() {
    if (this.product) {
      this.lendiUserService.ref.doc(this.product.uid).get().toPromise().then((res) => {
        const userRecord = res.data() as LendiUserRecord;
        this.user = userRecord;
        this.userAddress = this.user.profileAddress;
        this.userProfilePhoto = this.user.profilePhoto.source;
      });
    }
  }

}

import { NgModule } from '@angular/core';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { AppDownloadBannerComponent } from './components/app-download-banner/app-download-banner.component';
import { CategoryLinksComponent } from './components/category-links/category-links.component';
import { SocialLinksComponent } from './components/social-links/social-links.component';
import { SocialLinksListComponent } from './components/social-links-list/social-links-list.component';
import { SiteLinksComponent } from './components/site-links/site-links.component';
import { BannerComponent } from './components/banner/banner.component';
import { GlobalSearchBarComponent } from './components/global-search-bar/global-search-bar.component';
import { RouterModule } from '@angular/router';
import { AdsSectionComponent } from './components/ads-section/ads-section.component';
import { AdsCarouselComponent } from './components/ads-carousel/ads-carousel.component';
import { AdsBoxedImageComponent } from './components/ads-boxed-image/ads-boxed-image.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { ProductDeckComponent } from './components/product-deck/product-deck.component';
import { CartLinkComponent } from './components/cart-link/cart-link.component';
import { LocationMapComponent } from './components/location-map/location-map.component';
import { AgmCoreModule } from '@agm/core';
import { AuthModule } from 'projects/main/src/app/auth/auth.module';
import { FormFieldValidatorDisplayComponent } from './components/form-field-validator-display/form-field-validator-display.component';
import { ProfileImageComponent } from './components/profile-image/profile-image.component';
import { ProductPhotoGalleryComponent } from './components/product-photo-gallery/product-photo-gallery.component';
import { ProductPhotoGalleryFullViewComponent } from './components/product-photo-gallery-full-view/product-photo-gallery-full-view.component';
import { ProductPhotoGalleryHorizontalPreviewComponent } from './components/product-photo-gallery-horizontal-preview/product-photo-gallery-horizontal-preview.component';
import { ProductPhotoGalleryVerticalPreviewComponent } from './components/product-photo-gallery-vertical-preview/product-photo-gallery-vertical-preview.component';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ProductRatingComponent } from './components/product-rating.ts/product-rating.component';
import { ProductStatusBadgeComponent } from './components/product-status-badge/product-status-badge.component';
import { ProductAverageRatingComponent } from './components/product-average-rating/product-average-rating.component';
import { ProductDumbCardComponent } from './components/product-dumb-card/product-dumb-card.component';
import { RequestTimePipe } from './pipes/request-time.pipe';
import { ProductPhotoGalleryHorizontalEditablePreviewComponent } from './components/product-photo-gallery-horizontal-editable-preview/product-photo-gallery-horizontal-editable-preview.component';
import { SecurityDepositNamePipe } from './pipes/security-deposit-name/security-deposit-name.pipe';
import { CityByProvinceFilterPipe } from './pipes/city-by-province-filter/city-by-province-filter.pipe';
import { CreditRecordFilterPipe } from './pipes/credit-record-filter/credit-record-filter.pipe';
import { GooglePlacesComponent } from './components/google-places/google-places.component';
import { FormsModule } from '@angular/forms';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { ProductInfoFullComponent } from './components/product-info-full/product-info-full.component';
import { ProductInfoLocationComponent } from './components/product-info-location/product-info-location.component';
import { ProductPillComponent } from './components/product-pill/product-pill.component';
import { ProductDropdownMenuComponent } from './components/product-dropdown-menu/product-dropdown-menu.component';
import { ProfilePillComponent } from './components/profile-pill/profile-pill.component';
import { ProductSlotOptionLabelPipe } from './pipes/product-slot-option-label/product-slot-option-label.pipe';
import { RateComponent } from './components/rate/rate.component';
import { BorrowRequestSimpleCardComponent } from './components/borrow-request-simple-card/borrow-request-simple-card.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ProfileSummaryCardComponent } from './components/profile-summary-card/profile-summary-card.component';
import { ProfileStatusBadgeComponent } from './components/profile-status-badge/profile-status-badge.component';
import { ProfileBorrowersRatingComponent } from './components/profile-borrowers-rating/profile-borrowers-rating.component';
import { ProfileLendersRatingComponent } from './components/profile-lenders-rating/profile-lenders-rating.component';
import { ProductOverallRatingComponent } from './components/product-overall-rating/product-overall-rating.component';
import { UserRatingCardComponent } from './components/user-rating-card/user-rating-card.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    AppDownloadBannerComponent,
    CategoryLinksComponent,
    SocialLinksComponent,
    SocialLinksListComponent,
    SiteLinksComponent,
    BannerComponent,
    GlobalSearchBarComponent,
    AdsSectionComponent,
    AdsCarouselComponent,
    AdsBoxedImageComponent,
    ProductCardComponent,
    ProductDumbCardComponent,
    ProductDeckComponent,
    CartLinkComponent,
    LocationMapComponent,
    FormFieldValidatorDisplayComponent,
    ProfileImageComponent,
    ProductPhotoGalleryComponent,
    ProductPhotoGalleryFullViewComponent,
    ProductPhotoGalleryHorizontalPreviewComponent,
    ProductPhotoGalleryVerticalPreviewComponent,
    ProductRatingComponent,
    ProductStatusBadgeComponent,
    ProductAverageRatingComponent,
    RequestTimePipe,
    ProductPhotoGalleryHorizontalEditablePreviewComponent,
    SecurityDepositNamePipe,
    CityByProvinceFilterPipe,
    CreditRecordFilterPipe,
    GooglePlacesComponent,
    ProductInfoFullComponent,
    ProductInfoLocationComponent,
    ProductPillComponent,
    ProductDropdownMenuComponent,
    ProfilePillComponent,
    ProductSlotOptionLabelPipe,
    RateComponent,
    BorrowRequestSimpleCardComponent,
    ProfileSummaryCardComponent,
    ProfileStatusBadgeComponent,
    ProfileBorrowersRatingComponent,
    ProfileLendersRatingComponent,
    ProductOverallRatingComponent,
    UserRatingCardComponent
  ],
  imports: [
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCYmoWyiGamWzp_MHthquZ8kzIP0fFTGPA'
    }),
    CommonModule,
    RouterModule,
    AuthModule,
    NgxSpinnerModule,
    FormsModule,
    GooglePlaceModule,
    SlickCarouselModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    AppDownloadBannerComponent,
    CategoryLinksComponent,
    SocialLinksComponent,
    SocialLinksListComponent,
    SiteLinksComponent,
    BannerComponent,
    GlobalSearchBarComponent,
    AdsSectionComponent,
    AdsCarouselComponent,
    AdsBoxedImageComponent,
    ProductCardComponent,
    ProductDumbCardComponent,
    ProductDeckComponent,
    CartLinkComponent,
    LocationMapComponent,
    FormFieldValidatorDisplayComponent,
    ProfileImageComponent,
    ProductPhotoGalleryComponent,
    ProductPhotoGalleryFullViewComponent,
    ProductPhotoGalleryHorizontalPreviewComponent,
    ProductPhotoGalleryVerticalPreviewComponent,
    CommonModule,
    RouterModule,
    NgxSpinnerModule,
    ProductRatingComponent,
    ProductStatusBadgeComponent,
    ProductAverageRatingComponent,
    RequestTimePipe,
    ProductPhotoGalleryHorizontalEditablePreviewComponent,
    SecurityDepositNamePipe,
    CityByProvinceFilterPipe,
    CreditRecordFilterPipe,
    GooglePlacesComponent,
    GooglePlaceModule,
    ProductInfoFullComponent,
    ProductInfoLocationComponent,
    ProductPillComponent,
    ProductDropdownMenuComponent,
    ProfilePillComponent,
    ProductSlotOptionLabelPipe,
    RateComponent,
    BorrowRequestSimpleCardComponent,
    SlickCarouselModule,
    ProfileSummaryCardComponent,
    ProfileStatusBadgeComponent,
    ProfileBorrowersRatingComponent,
    ProfileLendersRatingComponent,
    ProductOverallRatingComponent,
    UserRatingCardComponent
  ]
})
export class SharedModule {}

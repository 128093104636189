import { AbstractControl } from '@angular/forms';

const ERROR_MESSAGES = {
  email: {
    required: 'Please enter your email adress.',
    email: 'Please enter a valid email adress.'
  },
  password: {
    required: 'Please enter your password.',
    minlength: 'Please enter a minimum of six(6) characters.'
  },
  currentPassword: {
    required: 'Please enter your current password.',
    minlength: 'Please enter a minimum of six(6) characters.'
  },
  confirmPassword: {
    required: 'Please enter your confirmed password.'
  },
  passwordGroup: {
    match: 'Password doesn\'t match.'
  },
  newPasswordGroup: {
    match: 'Password doesn\'t match.'
  },
  firstname: {
    required: 'Please enter your first name.'
  },
  lastname: {
    required: 'Please enter your last name.'
  },
  birthdate: {
    required: 'Please enter your birth date.'
  },
  streetAddress: {
    required: 'Please enter your street address.',
    maxlength: 'Please enter a valid street address.'
  },
  barangay: {
    required: 'Please enter your barangay.',
    maxlength: 'Please enter a valid barangay.'
  },
  region: {
    required: 'Please select your region.',
    maxlength: 'Please enter a valid region.'
  },
  city: {
    required: 'Please select your city.',
    maxlength: 'Please enter a valid city.'
  },
  postalCode: {
    required: 'Please enter your postal code.',
    maxlength: 'Please enter a valid postal code.',
    pattern: 'Please enter a valid postal code.'
  },
  fromDate: {
    required: 'Please enter start date.',
    previousDate: 'Please enter a valid start date.'
  },
  fromTime: {
    required: 'Please select start time.'
  },
  toDate: {
    required: 'Please enter end date.',
    previousDate: 'Please enter a valid end date.'
  },
  toTime: {
    required: 'Please enter end time.'
  },
  slotId: {
    required: 'Please select product slot.'
  },
  category: {
    required: 'Please select product category.'
  },
  name: {
    required: 'Please enter product name.'
  },
  serialNumber: {
    required: 'Please enter product serial number.'
  },
  declaredItemPrice: {
    required: 'Please enter product declared price.',
    min: 'Please enter valid product declared price.'
  },
  rentalPricePerDay: {
    required: 'Please enter product renta price per day.',
    min: 'Please enter valid product price per day.'
  },
  depositFee: {
    required: 'Please enter product deposit fee.',
    min: 'Please enter valid product deposit fee.'
  },
  weeklyDiscount: {
    required: 'Please enter product weekly discount.',
    min: 'Please enter valid product weekly discount.'
  },
  itemWeight: {
    required: 'Please enter item weight',
    min: 'Please enter valid item weight.'
  },
  itemDimension: {
    required: 'Please complete item dimension details.'
  },
  itemLength: {
    required: 'Please enter item legnth.',
    min: 'Please enter valid item length.'
  },
  itemWidth: {
    required: 'Please enter item width.',
    min: 'Please enter valid item width.'
  },
  itemHeight: {
    required: 'Please enter item height.',
    min: 'Please enter valid item height.'
  },
  securityDeposit: {
    required: 'Please complete product security deposit details.'
  },
  securityDepositOption: {
    required: 'Please enter security deposit option.'
  },
  accountName: {
    required: 'Please enter account name.'
  },
  accountNumber: {
    required: 'Please enter account number.'
  },
  description: {
    required: 'Please enter product description.'
  },
  mobile: {
    required: 'Please enter mobile contact number.'
  },
  pickUpLocation: {
    required: 'Please enter pick up location.'
  },
  videoLink: {
    required: 'Please enter product video link.'
  },
  minimumRentalDays: {
    required: 'Please enter product minimum rental days.',
    min: 'Please enter valid minimum rental days'
  }
};

export function setErrorMessage(c: AbstractControl, formControlName: string): string {
  if ((c.touched || c.dirty) && c.errors) {
    return Object.keys(c.errors)
      .map(key => ERROR_MESSAGES[formControlName][key])
      .join(' ');
  } else {
    return '';
  }
}

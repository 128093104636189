import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeModule } from './home/home.module';
import { AuthModule } from './auth/auth.module';
import { SharedModule } from 'projects/shared/shared.module';
import { CoreModule } from 'projects/core/core.module';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { FaqComponent } from './faq/faq.component';

@NgModule({
  declarations: [
    AppComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    AboutUsComponent,
    FaqComponent
  ],
  imports: [
    CoreModule,
    AppRoutingModule,
    HomeModule,
    AuthModule,
    SharedModule
  ],
  exports: [CoreModule, SharedModule],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-global-search-bar',
  templateUrl: './global-search-bar.component.html',
  styleUrls: ['./global-search-bar.component.css']
})
export class GlobalSearchBarComponent implements OnInit {

  searchTerm = '';

  constructor(private router: Router) { }

  ngOnInit() {
  }

  triggerSearch() {
    this.router.navigate(['/products', 'search', this.searchTerm]);
  }

}

import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { LendiUserProfile, LendiUserProfileAddress, LendiUserRecord, RatingCount } from 'projects/lendi-business/src/public-api';
import { LendiUserService } from 'projects/core/services/lendi-user.service';
import { UserRatingService } from 'projects/core/services/user-rating.service';
import { SpinnerService } from 'projects/shared/services/spinner.service';
import { AuthService } from 'projects/core/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile-summary-card',
  templateUrl: './profile-summary-card.component.html',
  styleUrls: ['./profile-summary-card.component.css']
})
export class ProfileSummaryCardComponent implements OnInit, OnDestroy {

  @Input() userRecord: LendiUserRecord;

  userRatingAsLender: RatingCount;
  userRatingAsBorrower: RatingCount;
  userProfile: LendiUserProfile;
  userAddress: LendiUserProfileAddress;
  isPublic: boolean;
  profilePhoto: string;
  spinnerName = 'ProfileSummaryCardComponent';
  subscription: any;

  constructor(
    private spinner: SpinnerService,
    private lendiUserService: LendiUserService,
    private $db: UserRatingService,
    private authService: AuthService) {}

  ngOnInit() {
    this.spinner.show(this.spinnerName);

    if (this.userRecord === undefined) {
      this.isPublic = false;
      this.subscription = this.lendiUserService.currentUser.subscribe((userRecord: LendiUserRecord) => {
        if (userRecord) {
          this.userRecord = userRecord;
          this.loadUserData(userRecord);
        }
      });
    } else {
      this.isPublic = true;
      this.loadUserData(this.userRecord);
    }

  }

  loadUserData(userRecord: LendiUserRecord) {
    this.userProfile = userRecord.profile;
    this.userAddress = userRecord.profileAddress;
    this.profilePhoto = userRecord.profilePhoto.source;

    Promise.all([
      this.$db.getUserRatingCountsAsBorrower(userRecord.id).toPromise().then(res => {
        this.userRatingAsBorrower = res.data() as RatingCount;
      }),
      this.$db.getUserRatingCountsAsLender(userRecord.id).toPromise().then(res => {
        this.userRatingAsLender = res.data() as RatingCount;
      })
    ]).catch(err => {
      console.error(err);
    }).finally(() => {
      this.spinner.hide(this.spinnerName);
    });
  }

  logout() {
    this.spinner.show();
    this.authService
      .signOut()
      .then(res => {
        if (res) {
          this.lendiUserService.resetUserProfileSubscription();
          window.location.href = '/home';
        }
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}

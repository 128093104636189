import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { first } from 'rxjs/operators';
import { LendiUserService } from '../services/lendi-user.service';
import { LendiUserRecord } from 'projects/lendi-business/src/public-api';

@Injectable({
  providedIn: 'root'
})
export class AuthVerifiedGuard implements CanActivate {

  constructor(
    private router: Router,
    private afAuthService: AngularFireAuth,
    private $user: LendiUserService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const isVerified =  new Observable<boolean>((observer) => {

      this.afAuthService.authState.pipe(first()).toPromise().then(res => {
        if (res) {
          this.$user.ref.doc(res.uid).get().toPromise().then(userRecord => {
            if (userRecord.exists) {
              const user = userRecord.data() as LendiUserRecord;
              if (user.verificationInfo.isVerified) {
                observer.next(true);
              } else {
                this.router.navigate(['/profile', 'verification', 'proof-of-id']);
                observer.next(true);
              }
              observer.complete();
            }
          });
        } else {
          this.router.navigate(['/auth', 'login']);
          observer.next(false);
          observer.complete();
        }
      }).catch(error => {
        this.router.navigate(['/auth', 'login']);
        observer.next(false);
        observer.complete();
      });

    });

    return isVerified;
  }

}

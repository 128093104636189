import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { spinnerConfig, spinnerContainerConfig, spinnerSmallContainerConfig } from '../constants/ngx-spinner.config';
import { Spinner } from 'ngx-spinner/lib/ngx-spinner.enum';

export const pageSpinner = 'fullPage';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  constructor(private spinner: NgxSpinnerService) {}

  show(name = pageSpinner, small = false) {
    if (name !== pageSpinner) {
      if (small) {
        this.spinner.show(name, spinnerSmallContainerConfig as Spinner);
      } else {
        this.spinner.show(name, spinnerContainerConfig as Spinner);
      }
    } else {
      this.spinner.show(name, spinnerConfig as Spinner);
    }
  }

  hide(name = pageSpinner) {
    this.spinner.hide(name);
  }

}

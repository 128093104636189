import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LendiProductRecord } from 'projects/lendi-business/src/public-api';

@Component({
  selector: 'app-product-dropdown-menu',
  templateUrl: './product-dropdown-menu.component.html',
  styleUrls: ['./product-dropdown-menu.component.css']
})
export class ProductDropdownMenuComponent implements OnInit {

  @Input() default: string;
  @Input() productList: LendiProductRecord[];
  @Output() selectedProductChanged = new EventEmitter<LendiProductRecord>();

  selectedProduct: LendiProductRecord;

  constructor() { }

  ngOnInit() {
    if (this.default && this.productList) {
      const defaultProduct = this.productList.find(p => p.id === this.default);
      if (defaultProduct) {
        this.selectedProduct = defaultProduct;
      }
    }
  }

  setSelectedProduct(product) {
    this.selectedProduct = product;
    this.selectedProductChanged.emit(this.selectedProduct);
  }

}

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment, AppDeliveryFee, BannerPosition, PromoBannerRecord } from 'projects/lendi-business/src/public-api';
import { LendiCredits } from 'projects/lendi-business/src/public-api';

export function compare(a, b) {
  // Use toUpperCase() to ignore character casing
  const valA = a.toUpperCase();
  const valB = b.toUpperCase();

  let comparison = 0;
  if (valA > valB) {
    comparison = 1;
  } else if (valA < valB) {
    comparison = -1;
  }
  return comparison;
}

@Injectable({ providedIn: 'root' })
export class AppSettingsService {

  deliveryFee: AppDeliveryFee = null;
  lendiCredits: LendiCredits[] = [];
  adminFeeRate: number;
  mainBannerUrl = '';
  sideBannerUrl = '';

  constructor(private $db: AngularFirestore) {}

  loadDeliveryFee() {
    return this.$db.collection(environment.collections.appDeliveryFee,
      ref => ref.where('isActive', '==', true).orderBy('createdAt', 'desc').limit(1)
    ).get().toPromise().then(res => {
      const deliveryFees = res.docs.map(r => r.data() as AppDeliveryFee);
      if (deliveryFees.length) {
        this.deliveryFee = deliveryFees[0];
        return this.deliveryFee;
      } else {
        return null;
      }
    }).catch(err => {
      console.error(err);
    });
  }

  loadLendiCredits() {
    return this.$db.collection(environment.collections.appCredits,
      ref => ref.where('isActive', '==', true).orderBy('amount')
    ).get().toPromise().then(res => {
      this.lendiCredits = res.docs.map(r => r.data() as LendiCredits);
      return this.lendiCredits;
    }).catch(err => {
      console.error(err);
      return err;
    });
  }

  loadPromoBanners() {
    return Promise.all([
      this.$db.collection(environment.collections.promoBanners, ref => ref
        .where('isActive', '==', true)
        .where('isDeleted', '==', false)
        .where('promoBanner.position', '==', BannerPosition.MainBanner)
        .where('promoBanner.schedule.endDate', '>', new Date())
        .orderBy('promoBanner.schedule.endDate', 'desc')
      ).get().toPromise().then(res => {
        res.docs.map(d => d.data() as PromoBannerRecord).map(pb => {
          this.mainBannerUrl = new Date() >= pb.promoBanner.schedule.startDate.toDate() && new Date() <= pb.promoBanner.schedule.endDate.toDate() ? pb.source : this.mainBannerUrl;
        });
      }),
      this.$db.collection(environment.collections.promoBanners, ref => ref
        .where('isActive', '==', true)
        .where('isDeleted', '==', false)
        .where('promoBanner.position', '==', BannerPosition.SideBanner)
        .where('promoBanner.schedule.endDate', '>', new Date())
        .orderBy('promoBanner.schedule.endDate', 'desc')
      ).get().toPromise().then(res => {
        res.docs.map(d => d.data() as PromoBannerRecord).map(pb => {
          this.sideBannerUrl = new Date() >= pb.promoBanner.schedule.startDate.toDate() && new Date() <= pb.promoBanner.schedule.endDate.toDate() ? pb.source : this.sideBannerUrl;
        });
      }),
    ]).catch(err => {
      console.error(err);
    });
  }


}

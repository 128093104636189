import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SocialMediaLinkService } from 'projects/core/services/social-media-link.service';

@Component({
  selector: 'app-social-links',
  templateUrl: './social-links.component.html',
  styleUrls: ['./social-links.component.css']
})
export class SocialLinksComponent implements OnInit {

  links: Observable<any[]>;

  constructor(private socialMediaLinkService: SocialMediaLinkService) { }

  ngOnInit() {
    this.links = this.socialMediaLinkService.socialMediaLinks;
  }

  openTab(url) {
    window.open(url, '_blank');
    return false;
  }

}

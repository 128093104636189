import { FirebaseMetaData } from './firebase.meta';
import { LendiUserRecord, LendiUserProfile, LendiUserProfileAddress, LendiUserProfilePhoto } from './user-profile.model';
import { BorrowRequestRecord, ItemTrackerStatus } from './borrow-request.model';

export enum CivilStatus {
  Single,
  Married,
  Separated,
  Divorced,
  Windowed
}

export interface LendiRiderProfile extends LendiUserProfile {
  civilStatus: CivilStatus;
}

export interface LendiRiderRecord extends FirebaseMetaData {
  profile: LendiRiderProfile;
  profileAddress: LendiUserProfileAddress;
  profilePhoto: LendiUserProfilePhoto;
  vehicleInfo: LendiVehicleInfo;
}

export interface LendiVehicleInfo {
  brand: string;
  model: string;
  plateNumber: string;
}

export interface BorrowRequestRecordWithUserProfile extends BorrowRequestRecord {
  lenderUserProfile: LendiUserRecord;
  borrowerUserProfile: LendiUserRecord;
}

export enum RiderTransctionType {
  LenderToBorrower,
  BowerToLender
}

export interface StatusSnapshot {
  status: ItemTrackerStatus;
  updatedAt: Date;
}

export interface RiderTransactionRecord extends FirebaseMetaData {
  rider: LendiRiderRecord;
  borrowRequest: BorrowRequestRecord;
  type: RiderTransctionType;
  status: ItemTrackerStatus;
  updatedAt: any;
  snapshot: StatusSnapshot[];
}

